export function getThemeCSSColor(colorName: string, prefix: string = '--ah-color-') {
  return getComputedStyle(document.documentElement).getPropertyValue(prefix + colorName);
}

export function getHSLA(color: string = 'primary', alpha: number = 1, prefix: string = '--ah-color-') {
  const hue = getThemeCSSColor(`${color}-H`, prefix);
  const saturation = getThemeCSSColor(`${color}-S`, prefix);
  const luminance = getThemeCSSColor(`${color}-L`, prefix);
  return `hsla(${hue}, ${saturation}, ${luminance}, ${alpha})`;
}
