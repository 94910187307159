
import { Component } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';

@Component
export default class CheckboxMultipleFormField extends BaseFormField {
  onChange(option: any, $event: Event) {
    const checked: any = !!($event.target as any).checked;
    const value = this.optionValue(option);
    const model = [...this.value];
    if (checked && !this.isChecked(option)) {
      model.push(value);
    } else if (!checked && this.isChecked(option)) {
      model.splice(model.indexOf(value), 1);
    }
    this.$emit('set-value', model);
  }

  get options() {
    return getState(this.model, 'options');
  }

  optionName(option: any) {
    return typeof option === 'string' ? option : option.label;
  }

  optionValue(option: any) {
    return typeof option === 'string' ? option : option.value;
  }

  isChecked(option: any) {
    return this.value.includes(this.optionValue(option));
  }

  get value() {
    return Array.isArray(this.field.$model) ? this.field.$model : [];
  }

  get valuePropName() {
    return getState(this.model, 'valuePropName', 'value');
  }

  get dated() {
    return getState(this.model, 'dated');
  }

  get form() {
    return getState(this.model, 'label');
  }

  get label() {
    return getState(this.model, 'label');
  }
}
