import _Vue, { PluginObject } from 'vue';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import { NotificationsStore, setAuthStoreGetter } from './store/notificationsModule';
import { Toast } from 'ah-common-lib';
import { AuthStore } from 'ah-common-lib/src/models';
import { DocumentsService, Notification } from 'ah-api-gateways';

declare module 'vue/types/vue' {
  interface Vue {
    $ahNotificationsState: AhNotificationsState;
  }
}

export interface AhNotificationsPluginOptions {
  state: AhNotificationsState;
  directives: { onIntersect: any };
}

export interface AhNotificationsState {
  mediaQuery: MediaQueries;
  toast: Toast.Toast;
  services: {
    documents: DocumentsService;
  };
  viewNotificationResource?: (item: Notification) => void;
  store: {
    useNotificationsStore: () => NotificationsStore;
    useAuthStore: () => AuthStore;
  };
}

export const AhNotificationsPlugin = {
  install: function install(Vue: typeof _Vue, options: AhNotificationsPluginOptions) {
    const culledState: AhNotificationsState = {
      mediaQuery: options.state.mediaQuery,
      toast: options.state.toast,
      store: options.state.store,
      services: options.state.services,
      viewNotificationResource: options.state.viewNotificationResource
    };

    const notifState = new _Vue({
      data: culledState
    });

    Vue.use(options.directives.onIntersect);
    Vue.prototype.$ahNotificationsState = notifState;
    setAuthStoreGetter(notifState.store.useAuthStore);
  }
} as PluginObject<AhNotificationsPluginOptions>;

export function useNotificationState() {
  return _Vue.prototype.$ahNotificationsState;
}

export * from './components';
