
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RequestState } from 'ah-requests';

/**
 * Loading Overlay component
 *
 * Emits:
 * update:isError (payload: boolean) - sync-able read-only prop displaying whether or not state is currently in error
 * update:isLoading (payload: boolean) - sync-able read-only prop displaying whether or not state is currently loading
 */
@Component
export default class LoadingOverlay extends Vue {
  /**
   * Text to show while loading
   */
  @Prop({ default: '' }) loadingText!: string;

  /**
   * Whether the loader should not wrap the child content
   */
  @Prop({ default: false }) noWrap!: string | boolean;

  /**
   * Whether to show the loader in a loading state:
   * - If true, `state` is ignored
   * - If false (but not undefined) `state` is ignored, meaning no loading state is shown
   */
  @Prop({ required: false }) loading?: string | boolean;

  /**
   * Whether to show the loader in an error state:
   * - If true, `loading` and `state` are ignored
   * - If false (but not undefined) `state` is ignored, meaning no error state is shown
   */
  @Prop({ required: false }) error?: boolean | string;

  /**
   * Request state (or states) to track. `loading` and `error` will override the states provided.
   * Otherwise, loader is considered:
   * - In error if any of the requests are in error state
   * - Loading if any of the requests are in a loading state
   * - Idle/loaded otherwise
   */
  @Prop({ required: false }) state?: RequestState | RequestState[];

  /**
   * Title for error state
   */
  @Prop({ default: 'An error has occured' }) errorTitle!: string;

  /**
   * Descriptive text for error state
   */
  @Prop({ default: 'Content failed to load due to a technical error.' }) errorText!: string;

  /**
   * Whether to show the retry button when in an error state
   */
  @Prop({ default: false }) showRetry!: boolean | string;

  /**
   * Label for the retry button. Only valid if `showRetry` is truthy, and no loading slot is provided.
   */
  @Prop({ default: 'Retry' }) retryLabel!: string;

  /**
   * Layout overlay variant
   */
  @Prop({ default: 'main' }) variant!: string;

  /**
   * Layout overlay opacity value
   */
  @Prop({ default: '1' }) opacity!: string;

  /**
   * Layout overlay opacity value
   */
  @Prop({ default: '' }) blur!: string;

  /**
   * Whether to hide content on loading
   */
  @Prop({ default: false }) hideOnLoading!: boolean | string;

  /**
   * Whether to remove content on loading
   */
  @Prop({ default: false }) removeOnLoading!: boolean | string;

  get stateArray() {
    if (this.state) {
      return Array.isArray(this.state) ? this.state : [this.state];
    }
    return [];
  }

  get isLoading() {
    if (this.loading !== undefined) {
      return this.loading !== false;
    }
    return !!this.stateArray.find((i) => i === 'pending');
  }

  get isError() {
    if (this.error !== undefined) {
      return this.error !== false;
    }
    return !!this.stateArray.find((i) => i === 'error');
  }

  @Watch('isError', { immediate: true })
  private onIsErrorChange() {
    this.$emit('update:isError', this.isError);
  }

  @Watch('isLoading', { immediate: true })
  private onIsLoadingChange() {
    this.$emit('update:isLoading', this.isLoading);
  }

  get isRetryShown() {
    return this.showRetry !== false;
  }

  get isHiddenOnLoading() {
    return this.hideOnLoading !== false;
  }

  get isRemovedOnLoading() {
    return this.removeOnLoading !== false;
  }

  get isNoWrap() {
    return this.noWrap !== false;
  }

  retry() {
    this.$emit('retry');
  }
}
