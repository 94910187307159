import { HttpBasedService, HttpRequestOptions, HttpService } from 'ah-requests';
import {
  ClientInvite,
  ClientInviteAcceptRequest,
  ClientInviteCreateRequest,
  ClientInviteListFilters,
  ClientInviteRegistrationDetails,
} from '../models/client/clientInvite';
import { PaginatedQuery, PaginatedResponse, SessionData, standardiseQuerySort, VersionedObject } from '../models';

export class ClientInviteService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'clientInviteService' } },
      },
    });
  }

  public getSessionClientInvite(options?: Partial<HttpRequestOptions<ClientInvite>>) {
    return this.get<ClientInvite>(`${this.baseUrl}client-invites/session`, options);
  }

  public getClientInviteByToken(token: string, options?: Partial<HttpRequestOptions<ClientInvite>>) {
    return this.get<ClientInvite>(`${this.baseUrl}client-invites/token/${token}`, {
      ...options,
      options: {
        ...options?.options,
        skipAuth: true,
      },
    });
  }

  public registerClientFromInvite(
    payload: ClientInviteRegistrationDetails,
    options?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}register/client/invite`, payload, options);
  }

  public acceptClientInvite(
    acceptRequest: ClientInviteAcceptRequest,
    options?: Partial<HttpRequestOptions<SessionData>>
  ) {
    return this.post<SessionData>(`${this.baseUrl}registration/accept/client`, acceptRequest, {
      ...options,
      options: {
        ...options?.options,
        skipAuth: true,
      },
    });
  }

  public declineClientInviteByToken(token: string, options?: Partial<HttpRequestOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}client-invites/token/${token}`, {
      ...options,
      options: {
        ...options?.options,
        skipAuth: true,
      },
    });
  }

  public listClientInvites(
    filters: PaginatedQuery<ClientInviteListFilters>,
    options?: Partial<HttpRequestOptions<PaginatedResponse<ClientInvite>>>
  ) {
    filters = standardiseQuerySort(filters);
    return this.get<PaginatedResponse<ClientInvite>>(`${this.baseUrl}client-invites/`, {
      ...options,
      axiosConfig: {
        ...options?.axiosConfig,
        params: filters,
      },
    });
  }

  public createClientInvite(
    createRequest: ClientInviteCreateRequest,
    options?: Partial<HttpRequestOptions<ClientInvite>>
  ) {
    return this.post<ClientInvite>(`${this.baseUrl}client-invites/`, createRequest, options);
  }

  public getClientInvite(id: string, options?: Partial<HttpRequestOptions<ClientInvite>>) {
    return this.get<ClientInvite>(`${this.baseUrl}client-invites/${id}`, options);
  }

  public resendClientInvite(id: string, options?: Partial<HttpRequestOptions<ClientInvite>>) {
    return this.put<ClientInvite>(`${this.baseUrl}client-invites/${id}`, {}, options);
  }

  public deleteClientInvite(id: string, options?: Partial<HttpRequestOptions<ClientInvite>>) {
    return this.delete<ClientInvite>(`${this.baseUrl}client-invites/${id}`, options);
  }
}
