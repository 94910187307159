import { useServices, Services } from '../services';
import {
  StoreSupportData as CommonStoreSupportData,
  makeStoreSupportData as commonMakeSupport,
} from 'ah-common-lib/src/store';
import Vue from 'vue';

export type StoreSupportData<T = any> = CommonStoreSupportData<T, Services>;

export function makeStoreSupportData<T = any>(data: T = {} as any): StoreSupportData<T> {
  return commonMakeSupport({
    data,
    s: useServices(),
    tabSync: Vue.tabSync,
  });
}
