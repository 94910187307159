export enum ClientType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export const clientTypeLabels: { [key in ClientType]: string } = {
  [ClientType.INDIVIDUAL]: 'Individual',
  [ClientType.COMPANY]: 'Company',
};

export interface ClientReference {
  name: string;
  id: string;
  type: ClientType;
}
