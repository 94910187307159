
import { Component, Watch } from 'vue-property-decorator';
// importing directly to avoid non-extracted css issues from built code
import { VueTagsInput, createTags } from '@johmun/vue-tags-input/vue-tags-input/publish';
import uniq from 'lodash/uniq';
import { getState, setState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';

type Tag = {
  text: string;
  tiClasses: string[];
};

@Component({
  components: {
    VueTagsInput,
  },
})
export default class TagsFormField extends BaseFormField<string[]> {
  currTags: Tag[] = [];

  currTagsText: string[] = [];

  inputVal = '';

  beforeMount() {
    this.checkTagsValidity();
  }

  setValue(newTags: Tag[]) {
    const filtered = newTags
      .filter((t) => this.includeInvalidTags || t.tiClasses.includes('ti-valid'))
      .map((t) => t.text);

    this.currTags = newTags;
    this.checkTagsValidity();
    this.currTagsText = newTags.map((t) => t.text);

    this.$emit('set-value', filtered, !this.field.$dirty && !this.dirtyOnInput);
  }

  checkTagsValidity() {
    const allValid = !this.currTags.find((i) => !i.tiClasses.includes('ti-valid'));
    this.$set(this.model.$state, 'validTags', allValid);
  }

  onBlur() {
    this.$set(this.model.$state, 'validTags', null);
    setTimeout(() => {
      this.field.$touch();
      this.emitFormEvent({
        field: this.field,
        model: this.model,
        event: 'form-field-blur',
      });

      setTimeout(() => {
        // Setting a timeout inside another to skip 2 JS event loops, ensuring the setValue function has had an opportunity to be called
        this.checkTagsValidity();
      }, 500);
    });
  }

  onFocus() {
    this.emitFormEvent({
      field: this.field,
      model: this.model,
      event: 'form-field-focus',
    });
  }

  @Watch('field.$model', { immediate: true })
  onTagsChange() {
    this.currTagsText = uniq(this.currTagsText.concat(this.field.$model || []));
  }

  @Watch('inputVal', { immediate: true })
  onInputValChange() {
    setState(this.model, 'tagsInputVal', this.inputVal);
  }

  getTagSlotNames() {
    return Object.keys(this.$scopedSlots).filter((k) => {
      const slots = [
        'tag-left',
        'tag-right',
        'tag-center',
        'tag-actions',
        'autocomplete-item',
        'autocomplete-header',
        'autocomplete-footer',
        'between-elements',
      ];

      const innerSlotName = k.split(':')[1];
      if (!slots.includes(innerSlotName)) {
        return false;
      }
      return !!this.getSlotName(innerSlotName);
    });
  }

  get tags() {
    return createTags(this.currTagsText || []) as Tag[];
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get number() {
    return getState(this.model, 'number');
  }

  get placeholder() {
    return getState(this.model, 'placeholder', '');
  }

  get allowEditTags() {
    return getState(this.model, 'allowEditTags', true);
  }

  get tagValidation() {
    return getState(this.model, 'tagValidation', []);
  }

  get includeInvalidTags() {
    return getState(this.model, 'includeInvalidTags', false);
  }

  get addOnlyFromAutocomplete() {
    return getState(this.model, 'addOnlyFromAutocomplete', false);
  }

  get autocompleteAlwaysOpen() {
    return getState(this.model, 'autocompleteAlwaysOpen', false);
  }

  get autocompleteMinLength() {
    return getState(this.model, 'autocompleteMinLength', undefined);
  }

  get autocompleteItems() {
    return getState(this.model, 'autocompleteItems', undefined);
  }

  get autocomplete(): string {
    return this.model.$state.autocomplete || this.model.$name;
  }

  get icon(): any {
    return null;
  }
}
