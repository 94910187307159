
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TradeState, tradeStateBadges } from 'ah-api-gateways';

@Component
export default class TradeStateBadge extends Vue {
  @Prop({ default: '' }) state!: TradeState;

  get stateBadge() {
    return tradeStateBadges[this.state] || {};
  }
}
