
import { Component, Vue, Prop } from 'vue-property-decorator';
import ArrowTopIcon from '../../icons/components/ArrowTopIcon.vue';

@Component({
  components: {
    ArrowTopIcon,
  },
})
export default class CollapsibleCard extends Vue {
  @Prop({ default: 'Title' }) title!: string;

  isToggled = false;

  get hasFooter() {
    return this.$slots.footer;
  }
}
