import { render, staticRenderFns } from "./NotificationsList.vue?vue&type=template&id=0a388f6f&scoped=true&"
import script from "./NotificationsList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NotificationsList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NotificationsList.vue?vue&type=style&index=0&id=0a388f6f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a388f6f",
  null
  
)

export default component.exports