import { generateTempUUID } from 'ah-common-lib/src/helpers/uuid';
import {
  ClientCollaterals,
  FeesInfo,
  NotificationEvent,
  NotificationEventType,
  NotificationType,
  Wallet,
  WalletsPaginatedQuery,
} from 'ah-api-gateways';
import { map, tap } from 'rxjs/operators';
import Vue from 'vue';

import { makeStoreSupportData, StoreSupportData } from './supportData';
import { CachedItem } from 'ah-common-lib/src/helpers/cachedItem';
import { WalletOwnerData } from 'ah-common-lib/src/models';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { useAuthStore } from './authModule';
import { defineStore } from 'pinia';
import { useNotificationsStore } from 'ah-notifications/src/store';

let sd!: StoreSupportData;

export const useWalletsStore = defineStore('walletsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData();
    }
    return {
      _wallets: {} as { [key: string]: CachedItem<Wallet> },
      _collaterals: {} as { [key: string]: CachedItem<ClientCollaterals> },
      _feeInfos: {} as { [key: string]: CachedItem<FeesInfo> },
    };
  },
  getters: {
    getCollaterals(state) {
      const authStore = useAuthStore();
      return (clientId?: string) => {
        return state._collaterals[clientId ?? authStore.loggedInIdentity!.client!.id]?.item;
      };
    },
    areCollateralsLoading(state) {
      const authStore = useAuthStore();
      return (id?: string) => state._collaterals[id ?? authStore.loggedInIdentity!.client!.id]?.loadDate === 'pending';
    },
    getClientFeesInfo(state) {
      return (id: string) => state._feeInfos[id]?.item || null;
    },
    getPartnerFeesInfo(state) {
      return (id?: string) => state._feeInfos[id ?? 'SESSION']?.item;
    },
    getWalletItemById(state) {
      return (id: String) => {
        return Object.values(state._wallets).find((w) => w.item?.id === id)?.item;
      };
    },
    getWalletById() {
      return (id: String) => {
        return this.getWalletItemById(id);
      };
    },
    sessionWalletOwner(): WalletOwnerData {
      const authStore = useAuthStore();

      return {
        isPartner: !authStore.isClientUser,
        id: authStore.isClientUser ? authStore.loggedInIdentity?.client?.id : undefined,
      };
    },
    getWalletItem(state) {
      return (currency: string, owner?: WalletOwnerData) => {
        owner = owner || this.sessionWalletOwner;

        if (!owner) {
          throw 'must supply a target owner';
        }
        return Object.values(state._wallets).find((w) => {
          if (!w.item || w.item?.currency !== currency) {
            return false;
          }
          if (owner?.isPartner === false) {
            return w.item.clientId === owner.id;
          } else {
            // Absence of clientId signifies that it belongs to the owner if they are a partner
            return !w.item.clientId;
          }
        });
      };
    },
    getWallet() {
      return (currency: string, owner?: WalletOwnerData) => {
        return this.getWalletItem(currency, owner)?.item;
      };
    },
  },
  actions: {
    async waitForCollateralsChange(payload?: {
      retries?: number;
      delay?: number;
      clientId?: string;
      referenceDate?: Date;
    }) {
      const options = {
        retries: 8,
        delay: 500,
        ...payload,
      };

      const authStore = useAuthStore();

      if (authStore.isAgent && !payload?.clientId) {
        throw 'Only clients can access collateral information';
      }

      if (!this.getCollaterals(payload?.clientId)) {
        return this.loadCollaterals({ force: true, clientId: payload?.clientId });
      }

      let dateUpdated: Date | null = null;
      const id = payload?.clientId ? payload.clientId : authStore.loggedInIdentity!.client!.id;

      if (payload?.referenceDate) {
        dateUpdated = payload.referenceDate;
      } else {
        const dateText = this._collaterals[id]?.item?.updatedAt;
        dateUpdated = dateText ? new Date(dateText) : null;
      }

      let collaterals = await sd.s.risk.getClientCollateral(id).toPromise();

      for (let i = 0; i < options.retries; i++) {
        if (!dateUpdated || new Date(collaterals.updatedAt) >= dateUpdated) {
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, options.delay));
        collaterals = await sd.s.risk.getClientCollateral(id).toPromise();
      }

      if (!this._collaterals[id]) {
        Vue.set(this._collaterals, id, new CachedItem());
      }
      CachedItem.setCachedItem(this._collaterals[id], collaterals);

      return collaterals;
    },
    async waitForWalletChange(payload: { id: string; retries?: number; delay?: number }) {
      const options = {
        retries: 10,
        delay: 1500,
        ...payload,
      };

      const wallet = this.getWalletById(payload.id);

      if (!wallet) {
        return this.loadWallet({ id: payload.id! });
      }

      let loadedWallet;
      loadedWallet = await sd.reqManager
        .currentOrNew(`getWallet-${wallet.id}`, sd.s.wallet.getWallet(wallet.id))
        .toPromise();

      for (let i = 0; i < options.retries; i++) {
        if (wallet.updatedAt !== loadedWallet.updatedAt) {
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, options.delay));
        loadedWallet = await sd.reqManager
          .currentOrNew(`getWallet-${wallet.id}`, sd.s.wallet.getWallet(wallet.id))
          .toPromise();
      }
      this.setWallet({ wallet: loadedWallet });

      return loadedWallet;
    },
    loadCollaterals(payload: { force: boolean; clientId?: string } = { force: false }) {
      const authStore = useAuthStore();
      if (authStore.isAgent && !payload.clientId) {
        throw 'Only clients can access collateral information';
      }

      const id = payload.clientId ? payload.clientId : authStore.loggedInIdentity!.client!.id;

      if (!this._collaterals[id]) {
        Vue.set(this._collaterals, id, new CachedItem());
      }

      return CachedItem.loadCachedItem(this._collaterals[id], sd.s.risk.getClientCollateral(id), payload.force);
    },
    async loadClientFeesInfo(payload: { force: boolean; clientId: string }): Promise<any> {
      if (!this._feeInfos[payload.clientId]) {
        Vue.set(this._feeInfos, payload.clientId, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this._feeInfos[payload.clientId],
        sd.s.wallet.getClientFeesInfo(payload.clientId),
        payload.force
      );
    },
    /**
     * Load either session partner or partner by id
     */
    loadPartnerFeesInfo(payload: { force: boolean }) {
      const partnerCacheKey = 'SESSION';
      if (!this._feeInfos[partnerCacheKey]) {
        Vue.set(this._feeInfos, partnerCacheKey, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this._feeInfos[partnerCacheKey],
        sd.s.wallet.getPartnerFeesInfo(),
        payload.force
      );
    },
    expireCollaterals() {
      try {
        Object.values(this._collaterals).forEach(CachedItem.expireCachedItem);
      } catch (e) {
        this._collaterals = {};
      }
    },
    removeWalletFromCache(walletId: string) {
      const walletCache = this._wallets[walletId];

      if (walletCache) {
        CachedItem.expireCachedItem(walletCache);
      }
    },
    listWalletsObs(payload: { query?: WalletsPaginatedQuery; owner?: WalletOwnerData }) {
      const owner = payload.owner || this.sessionWalletOwner;

      const query: WalletsPaginatedQuery = {
        ...payload.query,
        partnerWallet: owner.isPartner,
        [owner.isPartner ? 'partnerId' : 'clientId']: owner.id,
      };

      if (owner.isPartner && !owner.id) {
        query.partnerWallet = owner.isPartner;
      }

      return sd.s.wallet.listWallets(query).pipe(
        tap((r) => {
          r.list.forEach((i) => {
            this.setWallet({ wallet: i });
          });
        })
      );
    },
    listWallets(payload: { query?: WalletsPaginatedQuery; owner?: WalletOwnerData }) {
      return this.listWalletsObs(payload).toPromise();
    },
    loadWallet(payload: { id: string; force?: boolean }) {
      if (!payload.force && this._wallets[payload.id] && !CachedItem.isCachedItemExpired(this._wallets[payload.id])) {
        return Promise.resolve(this._wallets[payload.id].item);
      }

      return sd.reqManager
        .sameOrNew(`loadWallet-${payload.id}`, sd.s.wallet.getWallet(payload.id))
        .pipe(
          tap((i) => {
            this.setWallet({ wallet: i });
          })
        )
        .toPromise();
    },
    loadCurrencyWallet(payload: { currency: string; force?: boolean; owner?: WalletOwnerData }) {
      const owner = payload.owner || this.sessionWalletOwner;

      if (!payload.force) {
        const currencyWallet = this.getWalletItem(payload.currency, payload.owner);

        if (currencyWallet && !CachedItem.isCachedItemExpired(currencyWallet)) {
          return Promise.resolve(currencyWallet.item);
        }
      }

      return sd.reqManager
        .sameOrNew(
          `loadCurrencyWallet-${payload.currency}`,
          this.listWalletsObs({ query: { currency: payload.currency, hideEmpty: false }, owner: payload.owner }).pipe(
            map(
              (r) =>
                r.list.find((w) => w.currency === payload.currency) ??
                <Wallet>{
                  balance: 0,
                  currency: payload.currency,
                  id: generateTempUUID(),
                  clientId: !owner.isPartner ? owner.id : undefined,
                  partnerId: owner.isPartner ? owner.id : undefined,
                }
            ),
            tap((i) => {
              this.setWallet({ wallet: i });
            })
          )
        )
        .toPromise();
    },
    async setWallet(payload: { wallet: Wallet }) {
      // If there is a wallet matching this currency with a different id, remove it
      const currWallet = this.getWallet(payload.wallet.currency, {
        isPartner: !payload.wallet.clientId,
        id: payload.wallet.clientId || payload.wallet.partnerId,
      });
      if (currWallet && currWallet.id !== payload.wallet.id) {
        Vue.delete(this._wallets, currWallet.id);
      }
      if (!this._wallets[payload.wallet.id]) {
        Vue.set(this._wallets, payload.wallet.id, new CachedItem<Wallet>(payload.wallet.id));
      }
      CachedItem.setCachedItem(this._wallets[payload.wallet.id], payload.wallet);
    },
    async [commonStoreActions.onSetup]() {
      sd = makeStoreSupportData();
      this.expireCollaterals();
    },
    async [commonStoreActions.afterSetup]() {
      const collateralEvents = [
        NotificationType.POST_COLLATERAL_FAILURE,
        NotificationType.POST_COLLATERAL_SUCCESS,
        NotificationType.WITHDRAW_COLLATERAL_FAILURE,
        NotificationType.WITHDRAW_COLLATERAL_SUCCESS,
      ];
      const notificationsStore = useNotificationsStore();
      const isLeader = await sd.tabSync.isLeaderPromise;

      notificationsStore.notificationEvents.subscribe((event: NotificationEvent) => {
        if (
          isLeader &&
          event.eventType === NotificationEventType.NOTIFICATION_CREATED &&
          collateralEvents.includes(event.payload?.type!)
        ) {
          this.loadCollaterals({ force: true });
        }
      });
    },
    async [commonStoreActions.onLogout]() {
      sd.reqManager.clear();
      this._wallets = {};
      this._collaterals = {};
      this._feeInfos = {};
    },
  },
});
