
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface ScrollRangeEvent {
  first: number;
  last: number;
}

/**
 * Virtual Scroller
 *
 * Used when rendering a big amount of components. Will create a scrollable
 * component that renders the components dynamically on demand (scroll)
 *
 * The scroll size depends on the children height, thus the scroller assumes all
 * elements inside of the scroller got the same height
 *
 * Emits:
 * - scroll-index-change (payload: ScrollRangeEvent): when items in viewport change
 */
@Component
export default class VirtualScroller extends Vue {
  /**
   * Items displayed in the infinite scroll. Will be listed and displayed in
   * a scrollable container
   */
  @Prop({ default: () => [] }) items!: any[];

  /**
   * Number of bearable items rendered. Defaults to 10, which means only 10
   * items will be displayed simultaneously
   */
  @Prop({ default: 10 }) renderedItems!: number;

  /**
   *  Class applied to the scrollable wrapper container
   */
  @Prop({ default: '' }) wrapperClass!: string;

  /**
   *  Class applied to the outer content container
   */
  @Prop({ default: '' }) containerClass!: string;

  /**
   *  Class applied to the inner content container
   */
  @Prop({ default: '' }) contentClass!: string;

  $refs!: {
    scrollWrapper: HTMLElement;
    scrollContainer: HTMLElement;
    scrollContent: HTMLElement;
  };

  /**
   * First item displayed in the list of rendered items
   */
  protected firstItem: number = 0;

  /**
   * Last item displayed in the list of rendered items
   * Will always be `firstItem` * `renderedItems`
   */
  private lastItem: number = this.renderedItems;

  /**
   * Height of child element
   */
  private elementHeight: number = 30;

  mounted() {
    // Calculate scroll height after all div have rendered properly

    setTimeout(() => this.calculateScrollHeight());
  }

  get loadedItems() {
    return this.items.slice(this.firstItem, this.lastItem);
  }

  get itemsLength() {
    return this.items.length + (this.$slots['append-option']?.length || 0);
  }

  /**
   * Scrolls into `item` if set. Otherwise scrolls to top
   */
  public scrollTo(item?: any) {
    const index = Math.max(
      this.items.findIndex((i) => i === item),
      0
    );

    this.firstItem = index;
    this.setContentPosition();
    this.setScrollPosition();
  }

  /**
   * Calculates virtual scroller height.
   *
   * Will change the outer container div to have the same size as the
   * number of items * each item height.
   */
  private calculateScrollHeight() {
    if (this.$refs.scrollContent?.children.item(0) !== null) {
      this.elementHeight = this.$refs.scrollContent!.children.item(0)!.clientHeight;
      this.$refs.scrollContainer!.style!.height = `${this.elementHeight * this.itemsLength + 10}px`;
    }
  }

  /**
   * Sets content position.
   *
   * Will change the inner container div to display in the present view.
   * Will always be the first item size * each element height
   */
  private setContentPosition() {
    this.$refs.scrollContent.style.transform = `translateY(${this.elementHeight * this.firstItem}px)`;
  }

  /**
   * Sets scroll position.
   *
   * Will change the scroll position to the first item index * the element height
   */
  private setScrollPosition() {
    this.$refs.scrollWrapper.scrollTop = this.firstItem * this.elementHeight;
  }

  private onScroll(event: Event) {
    this.$emit('scroll', event);

    const target = event.target as Element;
    if (!target) return;

    // calculates the first item matching the current scroll position
    const newFirst = Math.floor(target.scrollTop / this.elementHeight);
    const newLast = newFirst + this.renderedItems;

    if (newFirst !== this.firstItem || newLast !== this.lastItem) {
      this.firstItem = newFirst;
      this.lastItem = newLast;
      this.setContentPosition();
      this.$emit('scroll-index-change', { first: newFirst, last: newLast });
    }
  }

  @Watch('items')
  onItemChange() {
    this.calculateScrollHeight();
    this.scrollTo();
  }
}
