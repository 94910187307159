export enum FeatureFlag {
  PRICING_SHOW_CLIENT_COSTS = 'PRICING_SHOW_CLIENT_COSTS',
  ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE = 'ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE',
}

export enum FeatureFlagTreatment {
  ON = 'on',
  OFF = 'off',
}

export interface FeatureFlagRequest {
  featureFlag: FeatureFlag;
}

export interface FeatureFlagResponse extends FeatureFlagRequest {
  treatment: FeatureFlagTreatment;
}
