
import { Component, Vue } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import { getState } from '../../helpers/formHelpers';
import { FieldOption, FormSelectFieldSearchEvent, FieldOptionObj } from '../../interfaces/form';
import BaseFormField from './BaseFormField.vue';
import isEqual from 'lodash/isEqual';
import { createPopper } from '@popperjs/core';
import { cloneDeep } from 'lodash';

// We need to replace the VueSelect.select method with one we can hook into
// The original select method is disregardable, as we set the value ourselves
const VueSelectWithSelectEvent = Vue.extend({
  extends: VueSelect,
  methods: {
    select(option: any) {
      this.$emit('option-selected', option);
    },
  },
});

@Component({
  components: {
    VueSelect: VueSelectWithSelectEvent,
  },
})
export default class SelectFormField extends BaseFormField {
  $refs!: {
    select: any;
  };

  setValue(event: any) {
    if (event && (event.value === 0 || event.value === null || event.value === false)) {
      // ensuring value is set if it is 0 or null since
      // (event && event.value) || event will return event if value === 0 || value === null
      this.$emit('set-value', event.value, !this.field.$dirty && !this.dirtyOnInput);
      return;
    }
    this.$emit('set-value', (event && event.value) || event, !this.field.$dirty && !this.dirtyOnInput);
  }

  onSearch(search: string, loading: (val: boolean) => void) {
    this.emitFormEvent({
      field: this.field,
      model: this.model,
      search,
      loading,
      event: 'form-field-select-search',
    } as FormSelectFieldSearchEvent);
  }

  onOptionSelected(option: any) {
    let selectedValue = option;
    if (this.multiple) {
      const index = this.optionIndex(option);
      const value = [...this.value];
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(cloneDeep(option.value));
      }
      selectedValue = value;
    }

    if (this.closeOnSelect) {
      this.$refs.select.searchEl.blur();
    }
    this.setValue(selectedValue);
  }

  remove(value: any) {
    const option = this.options.find((opt) => opt.value === value);
    if (option) {
      this.onOptionSelected(option);
    }
  }

  optionIndex(option: any) {
    if (!this.value || this.value.length === 0) {
      return -1;
    }
    return this.value.findIndex((i: any) => i === option.value);
  }

  reduceValue(item: FieldOption) {
    return typeof item === 'string' ? item : item.value;
  }

  localSlotName(slotName: string) {
    return slotName.substr(this.model.$path.length + 1);
  }

  getSelectSlotNames() {
    return Object.keys(this.$scopedSlots).filter((k) => {
      const innerSlotName = k.split(':')[1];
      if (!innerSlotName) {
        return false;
      }
      return !!this.getSlotName(innerSlotName);
    });
  }

  getValueText(value: any) {
    if (this.loadingText && this.loading) {
      return this.loadingText;
    }

    const option = this.options.find((o: any) => {
      if (typeof o === 'object') {
        return o === value || o.value === value || o.value === value.label;
      }

      return o === value;
    });

    if (option) {
      return option.label ?? option;
    }

    return value.label ?? value;
  }

  isOptionSelected(option: FieldOptionObj) {
    return this.field.$model.includes(option.value);
  }

  get itemsText() {
    if (!this.value || this.value.length === 0) {
      return this.placeholder;
    }
    if (this.value.length <= this.maxFulltextLabels) {
      return this.value
        .map((i: any) => {
          const option = this.options.find((o: any) => o.value === i);
          return option ? option.label : i;
        })
        .join(', ');
    }
    return `${this.value.length} ${this.itemsCountLabel}`;
  }

  get value() {
    return this.field.$model;
  }

  get showBadges() {
    return getState(this.model, 'showBadges', false);
  }

  get itemsCountLabel() {
    return getState(this.model, 'itemsCountLabel', 'items');
  }

  get maxFulltextLabels() {
    return getState(this.model, 'maxFulltextLabels', 3);
  }

  get useSwitches() {
    return getState(this.model, 'switches', false);
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get multiple() {
    return getState(this.model, 'multiple', false);
  }

  get clearable() {
    return getState(this.model, 'clearable', false);
  }

  get searchable() {
    return getState(this.model, 'searchable', true);
  }

  get filterable() {
    return getState(this.model, 'filterable', true);
  }

  get closeOnSelect() {
    return getState(this.model, 'closeOnSelect', !this.multiple);
  }

  get placeholder() {
    if (this.loading) return 'Loading...';

    return getState(this.model, 'placeholder', '');
  }

  get selectable() {
    return getState(this.model, 'selectable', () => true);
  }

  get selectOnTab() {
    return getState(this.model, 'selectOnTab', true);
  }

  get appendToBody() {
    return getState(this.model, 'appendToBody', false);
  }

  get showSelectedOptions() {
    return getState(this.model, 'showSelectedOptions', true);
  }

  get calculateTextLabel() {
    return getState(this.model, 'calculateLabel', true);
  }

  get loading() {
    const loading = getState(this.model, 'loading', false);

    if (typeof loading === 'function') {
      return loading();
    }

    return loading;
  }

  get loadingText() {
    return getState(this.model, 'loadingText', undefined);
  }

  withPopper(dropdownList: HTMLElement, component: Vue) {
    /**
     * We need to explicitly define the dropdown width since
     * it is usually inherited from the parent with CSS.
     */
    dropdownList.style.minWidth = `${(component.$el as HTMLElement).getBoundingClientRect().width}px`;
    const popper = createPopper(component.$refs.toggle as any, dropdownList, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'positioningClass',
          enabled: true,
          phase: 'write',
          fn({ state }) {
            component.$el.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-down', state.placement !== 'top');
          },
        },
      ],
    });

    return () => popper.destroy();
  }

  get options(): FieldOptionObj[] {
    const options = getState(this.model, 'options', []);
    return typeof options === 'function' ? options() : options;
  }

  get filteredOptions() {
    if (this.showSelectedOptions) {
      return this.options;
    }

    return this.options.filter((o: any) => {
      let value = this.field.$model;
      if (!this.multiple) {
        value = [value];
      }

      for (let i = 0; i < value.length; i++) {
        if (isEqual(o, value[i]) || isEqual(o.value, value[i])) {
          return false;
        }
      }

      return true;
    });
  }
}
