
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RemainingCharCounter extends Vue {
  @Prop({ required: false }) maxLength?: number;

  @Prop({ required: true }) length!: number;

  get counter() {
    let out = (this.length || 0).toString();
    if (typeof this.maxLength === 'number' && this.maxLength > -1) {
      out += ' / ' + this.maxLength;
    }
    return out;
  }
}
