import { map, flatMap } from 'rxjs/operators';
import {
  Quote,
  QuoteLogs,
  PaginatedQuery,
  PaginatedResponse,
  QuoteType,
  HedgingSolutionInput,
  HedgingSolution,
  EmailOptions,
  QuoteState,
  partnerRoles,
  clientRoles,
  ListQuery,
  VersionedObject,
  ExportListType,
  UserRole,
  QuoteSuspendResume,
  ModelStateMachineActions,
  DocumentExport,
  standardiseQuerySort,
} from '../models';
import { HttpBasedService, HttpService, HttpOptions, RequestManager, downloadFile } from 'ah-requests';

export type QuotePostRequest = Partial<Quote> & Pick<Quote, 'agentId' | 'clientId' | 'type'>;

const QUOTE_CACHE = 'quote';

export class QuoteService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'quoteService' } },
      },
    });
  }

  private requestManager = new RequestManager();

  public get requestStates() {
    return this.requestManager.requestStates;
  }

  public listQuotes(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<Quote>>(`${this.baseUrl}quotes`, {
      axiosConfig: { params },
    });
  }

  public getQuote(id: string, options?: Partial<HttpOptions<Quote>>) {
    return this.requestManager.currentOrNew(
      `getQuote-${id}`,
      this.get<Quote>(`${this.baseUrl}quotes/${id}`, {
        options: {
          ...options,
        },
      })
    );
  }

  // TODO: replace this with real API once it's ready
  public getQuoteLogHistory(id: string, options?: Partial<HttpOptions<QuoteLogs>>) {
    return this.requestManager.currentOrNew(
      `getQuoteHistory-${id}`,
      this.get<QuoteLogs>(`${this.baseUrl}quotes/${id}`, {
        options: {
          cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: id },
          ...options,
        },
      })
    );
  }

  public refreshQuote(id: string) {
    return this.requestManager.sameOrCancelAndNew(
      `refreshQuote-${id}`,
      this.put<VersionedObject>(`${this.baseUrl}quotes/${id}/refresh`)
    );
  }

  public expressInterest(quoteId: string, solutionId: string) {
    return this.requestManager.sameOrCancelAndNew(
      `expressInterest-${solutionId}`,
      this.put<Quote>(`${this.baseUrl}quotes/${quoteId}/solutions/${solutionId}/expressinterest`, undefined, {
        options: {
          cache: { type: 'override', cacheKey: QUOTE_CACHE, itemKey: quoteId },
        },
      })
    );
  }

  public deleteQuote(id: string) {
    return this.delete<undefined>(`${this.baseUrl}quotes/${id}`);
  }

  public getSyncQuotePdfBlob(id: string) {
    return this.getQuoteTermsheet(id, 'application/pdf').pipe(map((r) => r.data));
  }

  public downloadSyncQuoteTermsheet(id: string) {
    return this.getQuoteTermsheet(id).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public downloadQuoteList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Quotes') {
    query = standardiseQuerySort(query);

    return this.get<DocumentExport>(`${this.baseUrl}quotes/export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public getQuoteTermsheet(id: string, filetype = 'application/octet-stream') {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        headers: {
          Accept: filetype,
        },
        url: `${this.baseUrl}quotes/${id}/termsheet`,
      },
    });
  }

  public createQuote(agentId: string, clientId: string, callId?: string, type: QuoteType = 'QUICK') {
    const requestBody: QuotePostRequest = {
      clientId: clientId,
      agentId,
      type,
    };

    if (callId) {
      requestBody.activeCallId = callId;
    }

    return this.post<VersionedObject>(`${this.baseUrl}quotes`, requestBody);
  }

  public updateQuote(id: string, quote: Partial<Quote>, options?: HttpOptions<VersionedObject>) {
    return this.put<VersionedObject>(`${this.baseUrl}quotes/${id}`, quote, {
      options: {
        ...options,
      },
    });
  }

  public updateSolutionOrder(quoteId: string, solutionIds: string[]) {
    return this.put<VersionedObject>(`${this.baseUrl}quotes/${quoteId}/solutions/sort`, solutionIds, {
      options: {
        cache: { cacheKey: QUOTE_CACHE, type: 'delete', itemKey: quoteId },
      },
    });
  }

  public getQuoteSolution(quoteId: string, solutionId: string, options?: HttpOptions<HedgingSolution>) {
    return this.get<HedgingSolution>(`${this.baseUrl}quotes/${quoteId}/solutions/${solutionId}`, { options });
  }

  public addQuoteSolution(quoteId: string, solution: Partial<HedgingSolutionInput>) {
    return this.post<VersionedObject>(`${this.baseUrl}quotes/${quoteId}/solutions`, solution);
  }

  public updateQuoteSolution(quoteId: string, solutionId: string, solution: Partial<HedgingSolutionInput>) {
    return this.put<VersionedObject>(`${this.baseUrl}quotes/${quoteId}/solutions/${solutionId}`, solution);
  }

  public removeQuoteSolution(quoteId: string, solutionId: string) {
    return this.delete(`${this.baseUrl}quotes/${quoteId}/solutions/${solutionId}`);
  }

  public sendQuoteProposal(quoteId: string, data: Partial<EmailOptions>) {
    return this.post(`${this.baseUrl}quotes/${quoteId}/email`, data);
  }

  /**
   * State Actions
   **/

  public get stateActions(): ModelStateMachineActions<QuoteState, Quote> {
    return {
      [QuoteState.CLIENT_EXPRESSED_INTEREST]: [
        {
          name: 'proposeCounterOffer',
          label: 'Propose Counter Offer',
          successLabel: (quote) => `Proposed counter offer for quote #${quote.referenceNumber}`,
          test: (quote, session) => session.role === UserRole.AH_ADMIN,
          action: (quote) =>
            this.proposeCounterOffer(quote.id).pipe(
              flatMap(() =>
                this.getQuote(quote.id, { cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: quote.id } })
              )
            ),
        },
        {
          name: 'deny',
          label: 'Deny Trade',
          successLabel: (quote) => `Trade denied for quote #${quote.referenceNumber}`,
          test: (quote, session) => session.role === UserRole.AH_ADMIN,
          action: (quote) =>
            this.deny(quote.id).pipe(
              flatMap(() =>
                this.getQuote(quote.id, { cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: quote.id } })
              )
            ),
        },
        {
          name: 'place',
          label: 'Place Trade',
          successLabel: (quote) => `Trade for quote #${quote.referenceNumber} placed successfully`,
          test: (quote, session) => session.role === UserRole.AH_ADMIN,
          action: (quote) =>
            this.place(quote.id).pipe(
              flatMap(() =>
                this.getQuote(quote.id, { cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: quote.id } })
              )
            ),
        },
      ],
      [QuoteState.DRAFT]: [
        {
          name: 'delete',
          label: 'Delete',
          successLabel: (quote) => `Quote #${quote.referenceNumber} deleted successfully`,
          action: (quote) => this.deleteQuote(quote.id),
        },
        {
          name: 'sendTermsheet',
          label: 'Send Termsheet',
          successLabel: (quote) => `Termsheet sent for quote #${quote.referenceNumber}`,
          test: (quote, session) => partnerRoles.includes(session.role),
          action: (quote, payload) =>
            this.sendQuoteProposal(quote.id, payload).pipe(
              flatMap(() =>
                this.getQuote(quote.id, { cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: quote.id } })
              )
            ),
        },
      ],
      [QuoteState.SENT]: [
        {
          name: 'expressInterest',
          label: 'Express Interest',
          successLabel: (quote) => `Interest expressed for quote #${quote.referenceNumber}`,
          test: (quote, session) => clientRoles.includes(session.role),
          action: (quote, solutionId) =>
            this.expressInterest(quote.id, solutionId).pipe(
              flatMap(() =>
                this.getQuote(quote.id, { cache: { type: 'use', cacheKey: QUOTE_CACHE, itemKey: quote.id } })
              )
            ),
        },
      ],
      [QuoteState.DELETED]: [
        // Final state
      ],
      [QuoteState.STALE]: [
        // Final state
      ],
      [QuoteState.AWAITING_PREMIUM]: [
        // Final state
      ],
      [QuoteState.OPEN]: [
        // Final state
      ],
      [QuoteState.CLOSED]: [
        // Final state
      ],
      [QuoteState.EXERCISE_REQUESTED]: [
        // Final state
      ],
      [QuoteState.EXERCISED]: [
        // Final state
      ],
      [QuoteState.CASHOUT_REQUESTED]: [
        // Final state
      ],
      [QuoteState.CASHED_OUT]: [
        // Final state
      ],
      [QuoteState.EXPIRED_ITM]: [
        // Final state
      ],
      [QuoteState.EXPIRED_OTM]: [
        // Final state
      ],
    };
  }

  public proposeCounterOffer(quoteId: string) {
    return this.put(`${this.baseUrl}quotes/${quoteId}/proposecounteroffer`);
  }

  public place(quoteId: string) {
    return this.put(`${this.baseUrl}quotes/${quoteId}/place`);
  }

  public deny(quoteId: string) {
    return this.put(`${this.baseUrl}quotes/${quoteId}/deny`);
  }

  public getQuoteStatus() {
    return this.get<QuoteSuspendResume>(`${this.baseUrl}quotes/status`);
  }

  public setQuoteStatus(suspendOrResume: string) {
    return this.put<void>(`${this.baseUrl}quotes/${suspendOrResume}`);
  }
}
