
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getChildModel, makeFormModel, setState } from '../../form/helpers';
import { selectField } from '../../form/models';
import { PaginatedParams } from 'ah-api-gateways';
import { formTitle } from '../../form/models';
import { FormModel } from 'ah-common-lib/src/form/interfaces';

type commonTableFields = {
  header: string;
  key: string;
  sortable: boolean;
  sortKey?: string;
};

@Component
export default class SortSelector extends Vue {
  @Prop({ default: () => ({}) }) sortAndPageParams!: Partial<PaginatedParams>;

  @Prop({ required: true }) fields!: commonTableFields[];

  private sortForm: FormModel = makeFormModel({
    name: 'sorterForm',
    fieldType: 'form',
    fields: [
      formTitle('Sort'),
      selectField('sortBy', '', [], {
        required: false,
        fieldWrapperClass: 'col col-8',
      }),
      selectField(
        'sortDirection',
        '',
        [
          {
            label: 'Asc',
            value: 'ASC',
          },
          {
            label: 'Desc',
            value: 'DESC',
          },
        ],
        {
          required: false,
          fieldWrapperClass: 'col col-4',
        }
      ),
    ],
  });

  @Watch('fields', { immediate: true })
  onFieldsChange() {
    // FIXME update sortForm.sortby.options
    const sortby = getChildModel(this.sortForm, 'sortby')!;

    if (sortby) {
      setState(
        sortby,
        'options',
        this.fields.filter((i) => i.sortable).map((i) => ({ label: i.header, value: i.sortKey ? i.sortKey : i.key }))
      );
    }
  }

  @Watch('sortAndPageParams', { immediate: true })
  private onSortByChange() {
    this.sortForm.sortBy = this.sortAndPageParams.sort;
    this.sortForm.sortDirection = this.sortAndPageParams.sortDirection;
  }

  private onFormEvent() {
    this.$emit('update:sortBy', this.sortForm.sortBy);
    this.$emit('update:sortAndPageParams', {
      ...this.sortAndPageParams,
      sort: this.sortForm.sortBy,
      sortDirection: this.sortForm.sortDirection,
    });
  }
}
