
import { Component, Vue, Prop } from 'vue-property-decorator';
import TrashcanIcon from '../../icons/components/TrashcanIcon.vue';
import { VButton } from '../../common/components';
import FormElement from './FormElement.vue';
import { toggleState, getState } from '../helpers/formHelpers';
import { RepeatModel, FormModel, FormEvent, FormActionEvent, FormAction } from '../interfaces/form';
import { FormValidation } from '../interfaces';

@Component({
  components: {
    FormElement,
    TrashcanIcon,
    VButton,
  },
})
export default class RepeatForm extends Vue {
  @Prop({ required: true }) form!: FormValidation<FormModel[]>;

  @Prop({ required: true }) model!: RepeatModel;

  private toggleOpen(model: FormModel | RepeatModel) {
    toggleState(model, 'open');
  }

  private isOpen(model: FormModel | RepeatModel) {
    return getState(model, 'open') || this.form.$model.length <= 1;
  }

  get elementTitlePlaceholder() {
    return this.model.$state.multipleTitlePlaceholder || '';
  }

  get multipleClass() {
    return this.model.$state.multipleClass || '';
  }

  get itemName() {
    return this.model.$state.itemName || '';
  }

  private elementTitle(model: FormModel) {
    const titleField = this.model.$state.multipleTitleField;
    if (typeof titleField === 'string') {
      return null;
    }
    if (typeof titleField === 'function') {
      return this.model.$state.multipleTitleField(model).trim();
    }
    return null;
  }

  onChildEvent(event: FormEvent) {
    if (event.event === 'form-action') {
      if ((event as FormActionEvent).action.name === 'remove-item-internal') {
        this.removeItem(this.form.$model.indexOf(event.model as FormModel));
      }
    }
    this.$emit('form-event', event);
  }

  addItem() {
    if (this.form.$model) {
      this.form.$model.push(this.model.$constructor());
    } else {
      this.form.$model = [this.model.$constructor()];
    }
  }

  removeItem(index: number) {
    this.form.$model.splice(index, 1);
  }

  get canAddItem() {
    return true;
  }

  private onFormAction(action: FormAction) {
    setTimeout(() => {
      this.$emit('form-event', {
        field: this.form,
        model: this.model,
        event: 'form-action',
        action,
      } as FormEvent<FormModel[]>);
    });
  }
}
