import './styles/style.scss';
import breakpoints from './styles/breakpoints.module.scss';

import Vue from 'vue';
import messages from 'ah-common-lib/src/constants/messages';
import { Toast, Icons, Common, Form } from 'ah-common-lib';
import { ThemePlugin, ThemePluginOptions } from 'ah-theme';
import {
  VBTooltipPlugin,
  PopoverPlugin,
  ModalPlugin,
  FormCheckboxPlugin,
  InputGroupPlugin,
  LayoutPlugin,
  OverlayPlugin,
  TabsPlugin,
  DropdownPlugin,
  BadgePlugin,
  TooltipPlugin,
} from 'bootstrap-vue';

import { setupRouter } from 'bd-common/src/router/router';
import { setupServices, useServices } from 'bd-common/src/services';
import { injectAppGlobals } from './injectGlobals';
import mediaQuery from 'ah-common-lib/src/mediaQuery';
import { tabSyncPlugin } from 'ah-common-lib/src/tabSync';

import IconsPlugin from 'bd-common/src/icons/iconsPlugin';
import { EventBus } from 'ah-common-lib/src/eventBus';
import fitText from 'ah-common-lib/src/directives/fitText';
import onIntersect from 'ah-common-lib/src/directives/intersect';
import { AppConfig, setupAppConfig } from './config';
import { OtpConfig, OtpPlugin } from 'ah-common-lib/src/otp';
import { from } from 'rxjs';
import { BrandingImageType, DocumentExport } from 'ah-api-gateways';
import { AhReportsPlugin, AhReportsPluginOptions } from 'ah-reports';
import PrimeVue from 'primevue/config';
import { AhNotificationsPlugin, AhNotificationsPluginOptions } from 'ah-notifications';
import { setAsyncFileDownloadReaction } from 'ah-common-lib/src/listing/listingConfig';
import { setNotificationsModuleSupportDataFactory, useNotificationsStore } from 'ah-notifications/src/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useAuthStore } from 'bd-common/src/store/authModule';
import { setupFeatureFlagStore, useFeatureFlagStore, useTabSyncSafePersistence } from 'ah-common-stores';
import { setOnBehalfOfOptions } from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { AhAppUpdaterPlugin, AhAppUpdaterPluginOptions } from 'ah-app-updater';
import { setupStore } from 'bd-common/src/store';
import { makeStoreSupportData } from './store/supportData';
import { ToastPluginOptions, useToast } from 'ah-common-lib/src/toast';
import { i18nPlugin, useI18n } from 'ah-common-lib/src/i18n';

import ToastComponent from './components/common/ToastComponent.vue';
import { AhTradesPlugin, AhTradesPluginOptions } from 'ah-trades';
import { useSettingsStore } from './store/settingsStore';
import { useWalletsStore } from './store/walletsStore';
import BDDynamicTableVue from './components/list/BDDynamicTable.vue';

export async function setupApp(config: AppConfig) {
  // Pinia must be created before store setup, so the current hooks are called at the right time
  const pinia = createPinia();
  useTabSyncSafePersistence(pinia);

  setupAppConfig(config);

  Vue.use(VBTooltipPlugin);
  Vue.use(TooltipPlugin);
  Vue.use(ModalPlugin);
  Vue.use(FormCheckboxPlugin);
  Vue.use(InputGroupPlugin);
  Vue.use(LayoutPlugin);
  Vue.use(OverlayPlugin);
  Vue.use(TabsPlugin);
  Vue.use(DropdownPlugin);
  Vue.use(BadgePlugin);
  Vue.use(PopoverPlugin);
  Vue.use(PrimeVue);
  Vue.use(PiniaVuePlugin);

  Vue.use(Icons.IconsPlugin);
  Vue.use(IconsPlugin);
  Vue.use(EventBus);
  Vue.use(Common.CommonComponentsPlugin);
  Vue.use(Form.FormsPlugin, {
    fieldWrapperClass: 'col col-12',
    formWrapperClass: 'col col-12',
  });
  Vue.use(mediaQuery, {
    bands: {
      xsDown: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
      xsOnly: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
      smUp: `(min-width: ${breakpoints.sm})`,
      smDown: `(max-width: calc(${breakpoints.md} - 0.02px))`,
      smOnly: `(min-width: ${breakpoints.sm}) and (max-width: calc(${breakpoints.md} - 0.02px))`,
      mdUp: `(min-width: ${breakpoints.md})`,
      mdDown: `(max-width: calc(${breakpoints.lg} - 0.02px))`,
      mdOnly: `(min-width: ${breakpoints.md}) and (max-width: calc(${breakpoints.lg} - 0.02px))`,
      lgUp: `(min-width: ${breakpoints.lg})`,
      lgDown: `(max-width: calc(${breakpoints.xl} -0.02px))`,
      lgOnly: `(min-width: ${breakpoints.lg}) and (max-width: calc(${breakpoints.xl} -0.02px))`,
      xlUp: `(min-width: ${breakpoints.xl})`,
      xlOnly: `(min-width: ${breakpoints.xl})`,
    },
  });
  Vue.use(fitText);
  Vue.use(onIntersect);
  Vue.use(tabSyncPlugin);
  Vue.use(Toast.ToastPlugin, {
    toastComponent: ToastComponent,
  } as ToastPluginOptions);

  Vue.config.errorHandler = function (err: any, vm: any, info: any) {
    if (!config.isProduction || config.showDevTools) {
      // TODO send this log to server if it is a critical error
      /* eslint-disable-next-line no-console */
      console.error('🔥 Error in Vue: \n', err, vm, info);
    }
  };

  injectAppGlobals();

  setNotificationsModuleSupportDataFactory((data) => makeStoreSupportData({ ...data, toast: useToast() }));

  const services = setupServices(config);
  const router = setupRouter(config);

  Vue.use(OtpPlugin, {
    refreshOtpRequest: () => from(services.auth.refreshOtp()),
  } as OtpConfig);

  Vue.use<AhReportsPluginOptions>(AhReportsPlugin, {
    state: {
      mediaQuery: Vue.prototype.$mediaQuery,
      services: {
        trade: services.trade,
        client: services.client,
        wallet: services.wallet,
        rates: services.rates,
        customer: services.customerReference,
      },
    },
  });

  Vue.use(ThemePlugin, {
    prefix: config.themeColorCSSPrefix,
    onLoad: (val) => {
      const icon = val.logos.find((l: { type: BrandingImageType }) => l.type === BrandingImageType.FAVICON);
      const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (icon && link) {
        link.href = icon.link;
        link.type = icon.contentType;
      }
    },
    loader: () => {
      const services = useServices();

      return services.branding.getPartnerBrandingData(undefined, {
        options: { errors: { silent: true } },
      });
    },
  } as Partial<ThemePluginOptions>);

  // i18n
  Vue.use(i18nPlugin, {
    locale: 'en', // set locale
    messages, // set locale messages
  });

  const i18n = useI18n();

  Vue.use<AhNotificationsPluginOptions>(AhNotificationsPlugin, {
    state: {
      mediaQuery: Vue.prototype.$mediaQuery,
      toast: Vue.toast,
      store: { useAuthStore, useNotificationsStore },
      services: { documents: services.documents },
      viewNotificationResource: () => {},
    },
    directives: { onIntersect },
  });

  Vue.use<AhAppUpdaterPluginOptions>(AhAppUpdaterPlugin, {
    router,
    http: services.http,
    beforeUpdateReload() {
      return useAuthStore().waitOnRequests();
    },
    state: {
      installedVersion: window.VUE_APP_VERSION,
    },
  });

  Vue.use<AhTradesPluginOptions>(AhTradesPlugin, {
    state: {
      theme: Vue.theme.val,
      mediaQuery: Vue.prototype.$mediaQuery,
      showDevTools: config.showDevTools,
      store: {
        useWalletsStore,
        useNotificationsStore,
        useSettingsStore,
        useAuthStore,
        useFeatureFlagStore,
      },
      i18n,
      services,
      toast: Vue.toast,
    },
  });

  setOnBehalfOfOptions({
    loadClient(clientId) {
      return services.client.getClient(clientId);
    },
  });

  setAsyncFileDownloadReaction((document: DocumentExport) => {
    useNotificationsStore().triggerFileExportRequestNotification(document);
  });

  // We replace DynamicTable with the BankDirect-specific BDDynamicTableVue
  Vue.component('DynamicTable', BDDynamicTableVue);
  // We replace PaginatedTable with BDDynamicTableVue,
  // efectively removing pagination and exporting from all Listing components,
  // as pagination is handled via BDListBlock
  Vue.component('PaginatedTable', BDDynamicTableVue);

  setupFeatureFlagStore({ supportData: makeStoreSupportData() });

  const injections = config.postSetup ? await config.postSetup(Vue) : {};

  const app = new Vue({
    router,
    pinia,
    ...injections,
    render: (h) => h(config.rootComponent),
  });

  return setupStore()
    .then(() => router.onReady)
    .then(() => app);
}
