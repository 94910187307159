
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LockIcon } from '../../icons/components';
import { generateUUID } from '../../helpers/uuid';

@Component({
  components: { LockIcon },
})
export default class LockableCheckboxInput extends Vue {
  @Prop({ default: false }) locked!: boolean;

  @Prop({ default: false }) isSwitch!: boolean;

  @Prop({ default: '' }) fieldId!: string;

  @Prop({ default: '' }) lockedTooltip!: string;

  private get _fieldId() {
    return this.fieldId || generateUUID();
  }
}
