
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatHedgingInstrument, Quote } from 'ah-api-gateways';

@Component({
  components: {},
})
export default class QuoteSolutionsCell extends Vue {
  @Prop({ required: true }) quote!: Quote;

  formatHedgingInstrument = formatHedgingInstrument;

  get firstSolution() {
    return this.quote.solutions.find((s) => s.expressedInterest) ?? this.quote.solutions[0];
  }

  get solutions() {
    if (this.quote.solutions.length) {
      return this.quote.solutions;
    }
    return [];
  }
}
