
import { Component, Vue, Watch } from 'vue-property-decorator';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { format, startOfDay, parse, addDays, subDays } from 'date-fns';
import { CalendarIcon } from '../../../icons/components';
import { getState } from '../../helpers/formHelpers';
import { isDescendant } from '../../../helpers/dom';
import BaseFormField from './BaseFormField.vue';
import { generateUUID } from '../../../helpers/uuid';
import { stripZoneOffset, addZoneOffset } from '../../../helpers/time';
import { eachDayOfInterval, startOfMonth, endOfMonth, subWeeks, addWeeks } from 'date-fns';
import { getValidatorFn } from 'ah-common-lib/src/form/helpers';

function validDate(date: Date) {
  return !Number.isNaN(date.valueOf());
}

@Component({
  components: {
    DatePicker,
    CalendarIcon,
  },
})
export default class SingleInputDateFormField extends BaseFormField {
  $refs!: {
    dateInput?: Element;
    datePicker: any; // no Calendar typings exist, setting to `any` as a workaround
  };

  previousInputDateString = '';

  inputDateString = '';

  isCalendarShown = false;

  private uuid = generateUUID();

  private clickListener!: (avent: any) => void;

  private currentMonthDisplayed: { month: number; year: number } = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  private selectionAttributes = {
    highlight: {
      class: 'date-highlight',
      contentClass: 'date-highlight-content',
    },
  };

  private attributes = [
    {
      key: 'today',
      highlight: {
        class: 'vc-today',
        contentClass: 'vc-today-content',
      },
      dates: new Date(),
    },
  ];

  onKeyPress(event: KeyboardEvent) {
    if (this.inputDateString) {
      if (event.key === 'ArrowUp') {
        this.inputDateString = format(addDays(parse(this.inputDateString, this.format, new Date()), 1), this.format);
      }
      if (event.key === 'ArrowDown') {
        this.inputDateString = format(subDays(parse(this.inputDateString, this.format, new Date()), 1), this.format);
      }
    }
  }

  @Watch('field.$model', { immediate: true })
  onModelChange() {
    if (!this.field.$model) {
      this.inputDateString = '';
      this.previousInputDateString = '';
    } else {
      const date = this.useLocalTime ? new Date(this.field.$model) : addZoneOffset(new Date(this.field.$model));
      if (this.field.$model && validDate(date)) {
        this.inputDateString = format(date, this.format);
        this.previousInputDateString = this.inputDateString;
      }
    }
  }

  @Watch('isCalendarShown')
  onCalendarChownChange(newVal: boolean, oldVal: boolean) {
    if (!newVal && oldVal) {
      this.field.$touch();
    }
  }

  setDateFromPopup(date: Date) {
    date = startOfDay(date);

    if (this.useDateTime && !this.useLocalTime) {
      date = stripZoneOffset(date);
    }

    this.$emit('set-value', this.formatDate(date));
    this.hideCalendar();
  }

  mounted() {
    this.clickListener = (event: any) => {
      if (
        this.isCalendarShown &&
        this.$refs.datePicker &&
        !isDescendant((this.$refs.datePicker as Vue).$el, event.target)
      ) {
        this.hideCalendar();
      }
    };
  }

  beforeDestroy() {
    window.removeEventListener('click', this.clickListener);
  }

  showCalendar() {
    if (!this.isCalendarShown && !this.readonly) {
      this.isCalendarShown = true;
      setTimeout(() => {
        if (this.field.$model && this.$refs.datePicker) {
          this.$refs.datePicker.move(new Date(this.field.$model), { transition: 'none' });
        }
        if (this.isCalendarShown) {
          window.addEventListener('click', this.clickListener);
        }
      });
    }
  }

  hideCalendar() {
    if (this.isCalendarShown) {
      this.isCalendarShown = false;
      window.removeEventListener('click', this.clickListener);
    }
  }

  formatDate(date: Date) {
    try {
      return this.useDateTime ? date.toISOString() : format(date, 'yyyy-MM-dd');
    } catch (e) {
      return '';
    }
  }

  get shouldDisplayCalendar() {
    return getState(this.model, 'showCalendar', true);
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput', true);
  }

  get leftAlignPicker() {
    return getState(this.model, 'leftAlignPicker', false);
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get useLocalTime() {
    return getState(this.model, 'useLocalTime', false);
  }

  get popupDate() {
    if (this.field.$model) {
      let date = new Date(this.field.$model);
      return this.useDateTime && !this.useLocalTime ? addZoneOffset(date) : date;
    }
    return this.field.$model;
  }

  get useDateTime() {
    return getState(this.model, 'useDateTime', false);
  }

  get format() {
    return 'dd-MM-yyyy';
  }

  get dateDisallowedChecker() {
    // Note: when running validators "this" is broken
    // date validators should take this into consideration
    return (date: Date) => {
      const validators = Object.keys(this.model.$validators);
      const dateVal = (this.useLocalTime ? date : stripZoneOffset(date)).toISOString();
      for (let i = 0; i < validators.length; i += 1) {
        const validator = getValidatorFn(this.model.$validators[validators[i]]);
        if (
          !validator(dateVal, this.model.$parent && this.model.$parent(), this.model.$parent && this.model.$parent())
        ) {
          return true;
        }
      }
      return false;
    };
  }

  get disabledDates() {
    // FIXME: "hacking" dates showned in page
    // v-calendar doesn't support date validations only arrays of
    // allowed/disabled dates, therefore, we are calculating disabled dates
    // in current showned calendar page
    const month = new Date(this.currentMonthDisplayed.year, this.currentMonthDisplayed.month - 1);
    const days = eachDayOfInterval({
      start: subWeeks(startOfMonth(month), 1),
      end: addWeeks(endOfMonth(month), 1),
    });

    return days.filter((day) => this.dateDisallowedChecker(day));
  }

  formatDateString(date: string) {
    if (date.length < 7) {
      date = date.replace(/(\d{2})(?=\d)/g, '$1-');
    }
    return date;
  }

  onFieldInput(event: InputEvent) {
    this.inputDateString = (event.target as HTMLInputElement).value;
    const inputLength = this.inputDateString.length;
    /**
     * Multiple calculations are made on the adding date string
     * to make sure the format will match whats expected (dd-mm-yyyy).
     *
     * Example:
     *  - Added "03" > we will add a '-' automatically > "03-"
     *  - Added "3-" > we will add a 0 before the single number > "03-"
     */
    if (inputLength < this.previousInputDateString.length) {
      if (inputLength === 3 || inputLength === 4) {
        this.inputDateString = this.inputDateString.substring(0, inputLength - 1);
      }
    } else {
      if (this.inputDateString.length === 2 || this.inputDateString.length === 5) {
        if (this.inputDateString[this.inputDateString.length - 1] === '-') {
          this.inputDateString =
            this.inputDateString.slice(0, this.inputDateString.length - 2) +
            '0' +
            this.inputDateString.slice(this.inputDateString.length - 2);
        } else {
          this.inputDateString += '-';
        }
      }
      this.inputDateString = this.formatDateString(this.inputDateString);
    }
    this.previousInputDateString = this.inputDateString;
  }

  setDate(event: InputEvent) {
    this.inputDateString = (event.target as HTMLInputElement).value;
    this.onInput();
  }

  onInput() {
    const dirty = !this.field.$dirty && !this.dirtyOnInput;

    if (this.inputDateString.length) {
      const date = parse(this.inputDateString, 'dd-MM-yyyy', new Date());

      if (validDate(date)) {
        let parsedDate = startOfDay(new Date(date));

        if (this.useDateTime && !this.useLocalTime) {
          parsedDate = stripZoneOffset(parsedDate);
        }
        // FIXME: see above fixme
        this.$emit('set-value', this.formatDate(parsedDate));
      } else {
        this.$emit('set-value', new Date(NaN), dirty);
      }
    } else {
      this.$emit('set-value', '');
    }
  }
}
