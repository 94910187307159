
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VBTooltip } from 'bootstrap-vue';

@Component({
  directives: {
    BTooltip: VBTooltip,
  },
})
export default class UuidTrim extends Vue {
  @Prop({ required: true }) uuid!: string;

  get parsedUuid() {
    return this.uuid.split('-').pop();
  }
}
