import { getHSLA } from 'ah-theme';

// Colors retrieved from CSS theme variables
// These expect ah-theme package to be used, and as such depend on it

export const dataColorPrimary = getHSLA('primary', 0.5);
export const linesColorPrimary = getHSLA('text-secondary', 0.5);
export const dataColorGreen = getHSLA('widgets-green', 0.5);
export const dataColorGrey = getHSLA('widgets-grey', 0.5);
export const dataColorGreenHover = getHSLA('widgets-green', 0.9);
export const dataColorGreyHover = getHSLA('widgets-grey', 0.9);
export const linesColorGreen = getHSLA('widgets-green', 1);
export const linesColorGrey = getHSLA('widgets-grey', 1);
