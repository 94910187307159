
import { Component } from 'vue-property-decorator';
import TextFormField from './TextFormField.vue';
import { getState } from '../../helpers/formHelpers';

@Component
export default class TextAreaFormField extends TextFormField {
  setTextAreaValue(text: string) {
    if (text.replace(/\s/g, '') === '') {
      this.setValue(this.emptyValue);
    } else {
      this.setValue(text);
    }
  }

  get rows() {
    return getState(this.model, 'rows', 3);
  }

  get showMaxLengthCounter() {
    return getState(this.model, 'showMaxLengthCounter', false);
  }
}
