import { Currency, OptionType } from '../currency';
import { HedgingSolution, QuoteDefaults, HedgingSolutionInput } from './hedgingSolution';
import { QuoteState } from './quoteState';
import { AmountType } from '../amountType';
import { TradeState } from '../trade';
import { VersionedObject } from '../rest';

export type QuoteType = 'QUICK' | 'GUIDED';

export enum WorkingCurrency {
  CCY1 = 'CCY1',
  CCY2 = 'CCY2',
}

export function QuoteCurrencySettingsToUpdate(settings: QuoteCurrencySettings) {
  return {
    buyCurrency: settings.buyCurrency,
    sellCurrency: settings.sellCurrency,
    tradeDirection: settings.tradeDirection,
    amount: settings.amount,
    amountType: settings.amountType,
  };
}

export function isValidQuote(quote: Quote) {
  return !!(quote.buyCurrency && quote.sellCurrency && quote.tradeDirection && quote.amount && quote.amountType);
}

export interface QuoteCurrencySettings {
  buyCurrency: Currency;
  sellCurrency: Currency;
  amount: number;
  tradeDirection: string;
  amountType: AmountType;
}

export interface QuoteRequest extends QuoteCurrencySettings {
  activeCallId: string;
  type: QuoteType;
  solutions?: HedgingSolutionInput[];

  partnerId?: string;
  agentId: string;
  clientId: string;
  individualId?: string;
  beneficiaryId?: string;
}

export interface Quote extends QuoteRequest, VersionedObject {
  partnerId?: string;
  partnerName?: string;

  individualId: string;
  beneficiaryId: string;
  brokerageId: string;
  createdAt: string;
  clientName: string;
  agentName: string;
  referenceNumber: number;
  solutions: HedgingSolution[];
  state: QuoteState;
  sellUnprotectedSpotRate: number;
  sellCurrencySymbol: string;
  buyCurrencySymbol: string;
  buyUnprotectedSpotRate: number;
  sellUnprotectedSpotAmount: number;
  buyUnprotectedSpotAmount: number;
  callHistory: string[];
  defaults: QuoteDefaults;
  tradeId: string;
  tradeState: TradeState;

  $deletedClient?: boolean;
  updatedAt?: string;
  deletedAt?: string;
}

export interface QuoteCurrencyData {
  currency: string;
  amount: number;
  premium: number;
  productRate: number;
  spotRate: number;
  forwardRate: number;
  optionType: OptionType;
  totalProfit: number;
  yourProfit: number;
}

/**
 * Partner-exclusive Quote Currency data (seen only by all partners)
 */
export interface PartnerQuoteCurrencyData extends QuoteCurrencyData {
  yourProfit: number;
  totalProfit: number;
}

export enum QuoteLogType {
  READ = 'READ',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export enum QuoteLogCategory {
  QUOTE = 'QUOTE',
  PRICING = 'PRICING',
}

export enum QuoteLogDirection {
  REQUEST = 'REQUEST',
  RESPONSE = 'RESPONSE',
}
export interface QuoteLogs {
  category: string;
  type: string;
  createdAt: string;
  direction: string;
  history: Array<any>;
}
