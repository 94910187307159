
import { intervalToDuration } from 'date-fns';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Duration extends Vue {
  @Prop({ default: 0 }) date!: Date;

  @Prop({ default: 0 }) duration!: number;

  updateTimeout: number | null = null;

  durationString = '';

  mounted() {
    this.updateTimeAgoText();
  }

  beforeDestroy() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }

  calcDuration() {
    if (!this.duration) {
      return intervalToDuration({ end: new Date(), start: new Date(this.date) });
    }
    return intervalToDuration({ end: new Date(this.duration), start: new Date(0) });
  }

  updateTimeAgoText() {
    const calcDuration = this.calcDuration();

    this.durationString = `${(calcDuration.minutes || 0).toString().padStart(2, '0')}:${(calcDuration.seconds || 0)
      .toString()
      .padStart(2, '0')}`;

    if (calcDuration.hours) {
      this.durationString = `${calcDuration.hours.toString().padStart(2, '0')}:${this.duration}`;
    }

    this.updateTimeout = window.setTimeout(() => this.updateTimeAgoText(), 1000);
  }
}
