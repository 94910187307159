
import { Component, Prop, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { BModal } from 'bootstrap-vue';
import RouteProtector from './RouteProtector.vue';

@Component({
  components: {
    BModal,
  },
})
export default class RouteProtectorModal extends RouteProtector {
  $refs!: {
    modal: BModal;
  };

  @Prop({ default: () => () => true }) allowChange!: boolean | (() => boolean);

  @Prop({ default: true }) allowSubpaths!: boolean;

  @Prop({ default: true }) allowQueryChange!: boolean;

  @Watch('allowSubpaths', { immediate: true })
  onAllowSubpathsChange() {
    this.protectorOptions.allowSubpaths = this.allowSubpaths;
  }

  @Watch('allowQueryChange', { immediate: true })
  onallowQueryChangeChange() {
    this.protectorOptions.allowQueryChange = this.allowQueryChange;
  }

  show() {
    this.$refs.modal.show();
  }

  protected canLeave() {
    return typeof this.allowChange === 'function' ? this.allowChange() : this.allowChange;
  }

  protected routeChangeCheck(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.canLeave()) {
      return next();
    }
    if (this.$refs.modal) {
      const confirm = () => {
        this.forcePush(to.fullPath);
      };

      this.$refs.modal.show();
      this.$refs.modal.$once('ok', confirm);
      this.$refs.modal.$once('hidden', () => {
        this.$refs.modal.$off('ok', confirm);
      });
      next(false);
    }
    return undefined;
  }
}
