
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VRow extends Vue {
  @Prop({ required: false }) alignV?: 'start' | 'center' | 'end';

  @Prop({ required: false }) alignH?: 'start' | 'center' | 'end' | 'around' | 'between';

  get bRowAttrs() {
    return [this.alignV ? 'align-items-' + this.alignV : '', this.alignH ? 'justify-content-' + this.alignH : ''];
  }
}
