
import { Component } from 'vue-property-decorator';
import { UploadImageIcon, UploadFileIcon, DownloadIcon, TrashcanIcon } from '../../../icons/components';
import VButton from '../../../common/components/VButton.vue';
import { FormActionEvent } from '../../interfaces/form';
import { getState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';

@Component({
  components: {
    VButton,
    UploadImageIcon,
    UploadFileIcon,
    TrashcanIcon,
    DownloadIcon,
  },
})
export default class FileFormField extends BaseFormField {
  onFileSelection(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files || [];

    if (!Array.isArray(this.field.$model)) {
      this.$emit('set-value', []);
    }

    if (!this.maxFilesReached) {
      const value = [...this.field.$model, ...files];
      this.$emit('set-value', [...value.slice(0, 10)]);
    }

    input.value = '';
  }

  removeFile(index: number) {
    this.$emit('set-value', [...this.field.$model.slice(0, index), ...this.field.$model.slice(index + 1)]);
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  get maxSizeFormatted() {
    return this.formatBytes(getState(this.model, 'maxSize'));
  }

  get maxSize() {
    return getState(this.model, 'maxSize');
  }

  showUpload() {
    if (this.field.$model.length < this.maxFiles) {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      (this.$refs.input as any).dispatchEvent(event);
    }
  }

  downloadFile() {
    this.emitFormEvent({
      event: 'form-action',
      model: this.field.$model,
      field: this.field.$model.validation,
      action: {
        name: 'download-file',
        label: '',
      },
    } as FormActionEvent);
  }

  get maxFilesReached() {
    return this.field.$model.length >= this.maxFiles;
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get fileIcon() {
    return getState(this.model, 'fileIcon');
  }

  get fieldType() {
    return getState(this.model, 'fieldType');
  }

  get maxFiles() {
    return getState(this.model, 'maxFiles', 1);
  }

  get isDownloadFile() {
    return !!getState(this.model, 'download');
  }

  get defaultFileName() {
    return getState(this.model, 'defaultFileName');
  }
}
