import { DocumentExportType } from '../documents';

export interface NotificationFilters {
  type: NotificationType[];
}
export interface NotificationInfo {
  id: string;
  type: NotificationType;
}
export interface Notification extends NotificationInfo {
  title: string;
  resource?: string;
  resourceType?: NotificationResourceType;
  resourceOrigin?: DocumentExportType;
  message: string;
  read: boolean;
  createdAt: string;
  alertType: NotificationAlertType;
}

export type NotificationCreationEvent = {
  eventType: NotificationEventType.NOTIFICATION_CREATED;
  payload: Notification;
};

export type SingleNotificationEvent = {
  eventType: NotificationEventType.NOTIFICATION_READ | NotificationEventType.NOTIFICATION_DELETED;
  payload: NotificationInfo;
};

export type NotificationsEvent = {
  eventType: NotificationEventType.NOTIFICATION_ALL_READ;
  payload: NotificationFilters;
};

export type NotificationEvent = NotificationCreationEvent | SingleNotificationEvent | NotificationsEvent;

export enum NotificationEventType {
  NOTIFICATION_CREATED = 'NOTIFICATION_CREATED',
  NOTIFICATION_READ = 'NOTIFICATION_READ',
  NOTIFICATION_DELETED = 'NOTIFICATION_DELETED',
  NOTIFICATION_ALL_READ = 'NOTIFICATION_ALL_READ',
}

export enum NotificationAlertType {
  REGULAR = 'REGULAR',
  URGENT = 'URGENT',
  BACKGROUND = 'BACKGROUND',
}

export enum NotificationResourceType {
  TRANSACTION_UUID = 'TRANSACTION_UUID',
  TRADE_UUID = 'TRADE_UUID',
  CLIENT_UUID = 'CLIENT_UUID',
  PARTNER_UUID = 'PARTNER_UUID',
  INDIVIDUAL_UUID = 'INDIVIDUAL_UUID',
  WALLET_UUID = 'WALLET_UUID',
  SCHEDULE_TRANSACTION_UUID = 'SCHEDULE_TRANSACTION_UUID',
  SOFTWARE_UPDATE_UUID = 'SOFTWARE_UPDATE_UUID',
  DOCUMENT_UUID = 'DOCUMENT_UUID',
}

export enum NotificationRequestType {
  FEE_DUE_OVERDUE = 'FEE_DUE_OVERDUE',
}

export enum NotificationType {
  // Defined in API
  KYC_STATUS_UPDATED = 'KYC_STATUS_UPDATED',
  CLIENT_STATUS_UPDATE = 'CLIENT_STATUS_UPDATE',
  RATE_ALERT = 'RATE_ALERT',
  MARGIN_CALL = 'MARGIN_CALL',
  POST_COLLATERAL_SUCCESS = 'POST_COLLATERAL_SUCCESS',
  POST_COLLATERAL_FAILURE = 'POST_COLLATERAL_FAILURE',
  WITHDRAW_COLLATERAL_SUCCESS = 'WITHDRAW_COLLATERAL_SUCCESS',
  WITHDRAW_COLLATERAL_FAILURE = 'WITHDRAW_COLLATERAL_FAILURE',
  POSITION_STATUS_OPEN = 'POSITION_STATUS_OPEN',
  POSITION_STATUS_CLOSE = 'POSITION_STATUS_CLOSE',
  SETTLEMENT_DATE_APPROACHING = 'SETTLEMENT_DATE_APPROACHING',
  TRADE_CONFIRMED = 'TRADE_CONFIRMED',
  TRADE_REJECTED = 'TRADE_REJECTED',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  DEPOSIT_CREATED = 'DEPOSIT_CREATED',
  PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  CREDIT_PROFILE_CHANGED = 'CREDIT_PROFILE_CHANGED',
  LIMITS_PROFILE_CHANGED = 'LIMITS_PROFILE_CHANGED',

  SOFTWARE_NEW_FEATURES = 'SOFTWARE_NEW_FEATURES',
  SOFTWARE_IMPROVEMENTS = 'SOFTWARE_IMPROVEMENTS',
  SOFTWARE_BUG_FIXES = 'SOFTWARE_BUG_FIXES',
  SOFTWARE_HINTS = 'SOFTWARE_HINTS',

  DOCUMENT_STATUS_EXPORTED = 'DOCUMENT_STATUS_EXPORTED',
  DOCUMENT_STATUS_EXPORTING = 'DOCUMENT_STATUS_EXPORTING',
  DOCUMENT_STATUS_FAILED = 'DOCUMENT_STATUS_FAILED',

  // Admin only
  CLIENT_ONBOARDED = 'CLIENT_ONBOARDED',
  PARTNER_ONBOARDED = 'PARTNER_ONBOARDED',
  CLIENT_DELETED = 'CLIENT_DELETED',
  CLIENT_INDIVIDUAL_DELETED = 'CLIENT_INDIVIDUAL_DELETED',
  PARTNER_DELETED = 'PARTNER_DELETED',
  VOICE_TRADE_STATUS_OPEN = 'VOICE_TRADE_STATUS_OPEN',
}

export const publicNotifications = [
  NotificationType.KYC_STATUS_UPDATED,
  NotificationType.CLIENT_STATUS_UPDATE,
  NotificationType.RATE_ALERT,
  NotificationType.MARGIN_CALL,
  NotificationType.POST_COLLATERAL_SUCCESS,
  NotificationType.POST_COLLATERAL_FAILURE,
  NotificationType.WITHDRAW_COLLATERAL_SUCCESS,
  NotificationType.WITHDRAW_COLLATERAL_FAILURE,
  NotificationType.POSITION_STATUS_OPEN,
  NotificationType.POSITION_STATUS_CLOSE,
  NotificationType.SETTLEMENT_DATE_APPROACHING,
  NotificationType.TRADE_CONFIRMED,
  NotificationType.TRADE_REJECTED,
  NotificationType.PAYMENT_SUCCESS,
  NotificationType.PAYMENT_FAILURE,
  NotificationType.DEPOSIT_CREATED,
  NotificationType.PAYMENT_SCHEDULED,
  NotificationType.PAYMENT_CONFIRMATION,
  NotificationType.CREDIT_PROFILE_CHANGED,
  NotificationType.LIMITS_PROFILE_CHANGED,
];

export const adminNotifications = [
  // Admin only
  NotificationType.CLIENT_ONBOARDED,
  NotificationType.PARTNER_ONBOARDED,
  NotificationType.CLIENT_DELETED,
  NotificationType.CLIENT_INDIVIDUAL_DELETED,
  NotificationType.PARTNER_DELETED,
];

export const updateNotifications = [
  NotificationType.SOFTWARE_NEW_FEATURES,
  NotificationType.SOFTWARE_IMPROVEMENTS,
  NotificationType.SOFTWARE_BUG_FIXES,
  NotificationType.SOFTWARE_HINTS,
];

export const fileExportNotifications = [
  NotificationType.DOCUMENT_STATUS_EXPORTED,
  NotificationType.DOCUMENT_STATUS_EXPORTING,
  NotificationType.DOCUMENT_STATUS_FAILED,
];

export const NotificationTranslations: {
  [key in NotificationType]: string;
} = {
  [NotificationType.KYC_STATUS_UPDATED]: 'KYC Status Updated',
  [NotificationType.CLIENT_STATUS_UPDATE]: 'Client status updated',
  [NotificationType.RATE_ALERT]: 'Rate Alert Triggered',
  [NotificationType.POSITION_STATUS_OPEN]: 'Position Status Opened',
  [NotificationType.POSITION_STATUS_CLOSE]: '',
  [NotificationType.SETTLEMENT_DATE_APPROACHING]: 'Settlement Date Approaching',

  [NotificationType.PARTNER_ONBOARDED]: 'Partner Onboarded',
  [NotificationType.PARTNER_DELETED]: 'Partner Deleted',
  [NotificationType.CLIENT_ONBOARDED]: 'Client Onboarded',
  [NotificationType.CLIENT_DELETED]: 'Client Deleted',
  [NotificationType.CLIENT_INDIVIDUAL_DELETED]: 'Client Individual Deleted',

  [NotificationType.DEPOSIT_CREATED]: 'Wallet Funds Received',

  [NotificationType.PAYMENT_CONFIRMATION]: 'Payment Confirmed',
  [NotificationType.SOFTWARE_NEW_FEATURES]: 'New Feature',
  [NotificationType.SOFTWARE_IMPROVEMENTS]: 'Software Improvement',
  [NotificationType.SOFTWARE_BUG_FIXES]: 'Bugfix',
  [NotificationType.SOFTWARE_HINTS]: 'Hints & Tips',

  // FIXME these events are defined here for completion sake, but there are already titles for them in the API.
  // Once all events have titles, this object (and its usages) can be removed

  [NotificationType.LIMITS_PROFILE_CHANGED]: '',
  [NotificationType.CREDIT_PROFILE_CHANGED]: '',
  [NotificationType.DOCUMENT_STATUS_EXPORTED]: '',
  [NotificationType.DOCUMENT_STATUS_EXPORTING]: '',
  [NotificationType.DOCUMENT_STATUS_FAILED]: '',

  [NotificationType.TRADE_CONFIRMED]: '',
  [NotificationType.TRADE_REJECTED]: '',
  [NotificationType.PAYMENT_SUCCESS]: '',
  [NotificationType.PAYMENT_FAILURE]: '',
  [NotificationType.PAYMENT_SCHEDULED]: '',
  [NotificationType.MARGIN_CALL]: '',
  [NotificationType.POST_COLLATERAL_SUCCESS]: '',
  [NotificationType.POST_COLLATERAL_FAILURE]: '',
  [NotificationType.WITHDRAW_COLLATERAL_SUCCESS]: '',
  [NotificationType.WITHDRAW_COLLATERAL_FAILURE]: '',

  [NotificationType.VOICE_TRADE_STATUS_OPEN]: '',
};
