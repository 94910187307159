
import { Component, Vue, Watch } from 'vue-property-decorator';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { format, isValid, startOfDay } from 'date-fns';
import { CalendarIcon } from '../../../icons/components';
import { getState } from '../../helpers/formHelpers';
import { isDescendant } from '../../../helpers/dom';
import BaseFormField from './BaseFormField.vue';
import { generateUUID } from '../../../helpers/uuid';
import { stripZoneOffset, addZoneOffset } from '../../../helpers/time';
import { eachDayOfInterval, startOfMonth, endOfMonth, subWeeks, addWeeks } from 'date-fns';
import { getValidatorFn } from '../../helpers';

function validDate(date: Date) {
  return !Number.isNaN(date.valueOf());
}

const NUM_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

@Component({
  components: {
    DatePicker,
    CalendarIcon,
  },
})
export default class DateFormField extends BaseFormField {
  $refs!: {
    dayInput?: Element;
    monthInput?: Element;
    yearInput?: Element;
    datePicker: any; // no Calendar typings exist, setting to `any` as a workaround
  };

  year = '';

  month = '';

  day = '';

  // Events are stored so the last interaction can be queried on @input events for these inputs
  // Values are udnefined (rather than null) to avoid unnecessary reactivity

  private lastYearKeyEvent?: KeyboardEvent;

  private lastMonthKeyEvent?: KeyboardEvent;

  private lastDayKeyEvent?: KeyboardEvent;

  isCalendarShown = false;

  private uuid = generateUUID();

  private clickListener!: (avent: any) => void;

  private currentMonthDisplayed: { month: number; year: number } = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  private selectionAttributes = {
    highlight: {
      class: 'date-highlight',
      contentClass: 'date-highlight-content',
    },
  };

  private attributes = [
    {
      key: 'today',
      highlight: {
        class: 'vc-today',
        contentClass: 'vc-today-content',
      },
      dates: new Date(),
    },
  ];

  @Watch('field.$model', { immediate: true })
  onModelChange() {
    if (!this.field.$model) {
      this.year = '';
      this.month = '';
      this.day = '';
    } else {
      const date = this.useLocalTime ? new Date(this.field.$model) : addZoneOffset(new Date(this.field.$model));
      if (this.field.$model && validDate(date)) {
        this.year = date.getFullYear().toString();
        this.month = (date.getMonth() + 1).toString();
        this.day = date.getDate().toString();
      }
    }
  }

  @Watch('isCalendarShown')
  onCalendarChownChange(newVal: boolean, oldVal: boolean) {
    if (!newVal && oldVal) {
      this.field.$touch();
    }
  }

  setDateFromPopup(date: Date) {
    date = startOfDay(date);
    if (!this.useLocalTime) {
      if (this.useDateTime && !this.useLocalTime) {
        date = stripZoneOffset(date);
      }
    }
    this.$emit('set-value', this.formatDate(date), true);
    this.hideCalendar();
  }

  mounted() {
    this.clickListener = (event: any) => {
      if (
        this.isCalendarShown &&
        this.$refs.datePicker &&
        !isDescendant((this.$refs.datePicker as Vue).$el, event.target)
      ) {
        this.hideCalendar();
      }
    };
  }

  beforeDestroy() {
    window.removeEventListener('click', this.clickListener);
  }

  showCalendar() {
    if (!this.isCalendarShown && !this.readonly) {
      this.isCalendarShown = true;
      setTimeout(() => {
        if (this.popupDate && this.$refs.datePicker) {
          this.$refs.datePicker.move(this.popupDate, { transition: 'none' });
        }
        if (this.isCalendarShown) {
          window.addEventListener('click', this.clickListener);
        }
      });
    }
  }

  hideCalendar() {
    if (this.isCalendarShown) {
      this.isCalendarShown = false;
      window.removeEventListener('click', this.clickListener);
    }
  }

  formatDate(date: Date) {
    try {
      return this.useDateTime ? date.toISOString() : format(date, 'yyyy-MM-dd');
    } catch (e) {
      return '';
    }
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get leftAlignPicker() {
    return getState(this.model, 'leftAlignPicker', false);
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get useLocalTime() {
    return getState(this.model, 'useLocalTime', false);
  }

  get popupDate() {
    if (this.field.$model) {
      let date = new Date(this.field.$model);
      if (isValid(date)) {
        return this.useDateTime && !this.useLocalTime ? addZoneOffset(date) : date;
      }
    }
    return null;
  }

  get useDateTime() {
    return getState(this.model, 'useDateTime', false);
  }

  get format() {
    if (getState(this.model, 'useDateTime')) {
      return "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
    }
    return 'yyyy-MM-dd';
  }

  get dateDisallowedChecker() {
    // Note: when running validators "this" is broken
    // date validators should take this into consideration
    return (date: Date) => {
      const validators = Object.keys(this.model.$validators);
      const dateVal = (this.useLocalTime ? date : stripZoneOffset(date)).toISOString();
      for (let i = 0; i < validators.length; i += 1) {
        const validator = getValidatorFn(this.model.$validators[validators[i]]);
        if (
          !validator(dateVal, this.model.$parent && this.model.$parent(), this.model.$parent && this.model.$parent())
        ) {
          return true;
        }
      }
      return false;
    };
  }

  get disabledDates() {
    // FIXME: "hacking" dates showned in page
    // v-calendar doesn't support date validations only arrays of
    // allowed/disabled dates, therefore, we are calculating disabled dates
    // in current showned calendar page
    const month = new Date(this.currentMonthDisplayed.year, this.currentMonthDisplayed.month - 1);
    const days = eachDayOfInterval({
      start: subWeeks(startOfMonth(month), 1),
      end: addWeeks(endOfMonth(month), 1),
    });

    return days.filter((day) => this.dateDisallowedChecker(day));
  }

  setYear(event: InputEvent) {
    this.year = (event.target as HTMLInputElement).value;
    this.onInput();
  }

  setMonth(event: InputEvent) {
    this.month = (event.target as HTMLInputElement).value;
    if (
      NUM_KEYS.includes(this.lastMonthKeyEvent?.key || '') &&
      parseInt((event.target as HTMLInputElement).value) > 1
    ) {
      (this.$refs.yearInput as any).focus();
    }
    this.onInput();
  }

  setDay(event: InputEvent) {
    this.day = (event.target as HTMLInputElement).value;
    if (NUM_KEYS.includes(this.lastDayKeyEvent?.key || '') && parseInt((event.target as HTMLInputElement).value) > 3) {
      (this.$refs.monthInput as any).focus();
    }
    this.onInput();
  }

  isValidInput(year: number, month: number, day: number) {
    if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

    if (!(year > 1900 && year < 9999)) {
      return false;
    }

    if (!(month > 0 && month < 13)) {
      return false;
    }

    if (day <= 0 || day > monthLength[month - 1]) {
      return false;
    }
    return true;
  }

  onInput() {
    const dirty = !this.field.$dirty && !this.dirtyOnInput;

    if (!this.year && !this.month && !this.day) {
      this.$emit('set-value', '', dirty);
      return;
    }

    const year = parseInt(this.year, 10);
    const month = parseInt(this.month, 10);
    const day = parseInt(this.day, 10);

    if (this.isValidInput(year, month, day)) {
      let date = startOfDay(new Date(year, month - 1, day));

      if (this.useDateTime && !this.useLocalTime) {
        date = stripZoneOffset(date);
      }

      // FIXME: see above fixme
      this.$emit('set-value', this.formatDate(date), dirty);
    } else {
      this.$emit('set-value', new Date(NaN), dirty);
    }
  }

  private onKeyDown(event: KeyboardEvent) {
    if (event.target === this.$refs.yearInput) {
      this.lastYearKeyEvent = event;
    } else if (event.target === this.$refs.monthInput) {
      this.lastMonthKeyEvent = event;
    } else if (event.target === this.$refs.dayInput) {
      this.lastDayKeyEvent = event;
    }

    if (event.key === 'Backspace' && !(event.target as any).value) {
      if (event.target === this.$refs.yearInput) {
        (this.$refs.monthInput as any).focus();
      } else if (event.target === this.$refs.monthInput) {
        (this.$refs.dayInput as any).focus();
      }
    }
  }

  onBlur() {
    setTimeout(() => {
      let internal = false;
      Object.keys(this.$refs).forEach((k) => {
        const refEl = ((this.$refs as any)[k] as Vue)?.$el || ((this.$refs as any)[k] as Element);
        if (
          document.activeElement &&
          refEl &&
          (document.activeElement === refEl || isDescendant(refEl, document.activeElement))
        ) {
          internal = true;
        }
      });
      if (!internal) {
        this.field.$touch();
      }
    });
  }
}
