
import { Component, Watch } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import TextFormField from './TextFormField.vue';

@Component
export default class NumberFormField extends TextFormField {
  $refs!: {
    numberInput: HTMLInputElement;
  };

  currInputVal = '';

  private lastNumber: number | null = null;

  private isNumber(value: string | number | null): value is number {
    return value !== null && typeof value !== 'string';
  }

  @Watch('field.$model', { immediate: true })
  onModelChange() {
    if (typeof this.field.$model === 'number') {
      if (this.field.$model !== this.parseInput(this.currInputVal)) {
        this.currInputVal = this.stringVal;
      }
    } else {
      this.currInputVal = this.stringVal;
    }
  }

  onNumberFieldBlur() {
    this.currInputVal = this.stringVal;
    this.onBlur();
  }

  private countDecimals(value: string) {
    const splitedValue = value.split('.');
    if (splitedValue.length > 1) {
      return splitedValue[1].length || 0;
    }
    return 0;
  }

  private parseInput(value: string) {
    return this.integer ? parseInt(value, 10) : parseFloat(value);
  }

  setValue(value: string) {
    this.currInputVal = value;
    let val: number | string | null = this.parseInput(value);

    if (typeof val === 'number' && !isNaN(val)) {
      if (typeof this.min === 'number' && !isNaN(this.min)) {
        val = Math.max(val, this.min);
      }
      if (typeof this.max === 'number' && !isNaN(this.max)) {
        val = Math.min(val, this.max);
      }
    } else {
      val = null;
    }

    let numberVal: number | null = typeof val === 'string' ? this.parseInput(value) : val;

    this.lastNumber = numberVal;
    this.$emit('set-value', this.inputToValueFn(numberVal), !this.field.$dirty && !this.dirtyOnInput);
    this.$forceUpdate();
  }

  get fieldType() {
    return 'number';
  }

  /**
   * Input type to use
   * Defaults to 'text' (for backwards compatibility), allows 'text' or 'number'
   */
  get inputType() {
    const inputTypeString = getState(this.model, 'inputType', 'text');
    return inputTypeString === 'number' ? 'number' : 'string';
  }

  get number() {
    return true;
  }

  get integer() {
    return getState(this.model, 'integer', false);
  }

  get min() {
    return getState(this.model, 'min', 0);
  }

  get max() {
    return getState(this.model, 'max', null);
  }

  get step() {
    return getState(this.model, 'step', 1);
  }
}
