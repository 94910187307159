export enum UserRole {
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  AH_ADMIN = 'AH_ADMIN',
  AH_AGENT = 'AH_AGENT',
  AH_COMPLIANCE = 'AH_COMPLIANCE',
  AH_FINANCE = 'AH_FINANCE',
  AH_CREDIT = 'AH_CREDIT',
  AH_TRADING = 'AH_TRADING',
  AH_OPERATIONS = 'AH_OPERATIONS',
  AH_SALES = 'AH_SALES',
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_AGENT = 'PARTNER_AGENT',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_INDIVIDUAL = 'CLIENT_INDIVIDUAL',

  CLIENT_REGISTRATION = 'CLIENT_REGISTRATION',
}

// User Entity Type, used by the API to distinguish
// between different types of users with the same role
export enum UserEntityType {
  USER = 'USER',
  GROUP = 'GROUP',
  COMPANY_CLIENT = 'COMPANY_CLIENT',
  INDIVIDUAL_CLIENT = 'INDIVIDUAL_CLIENT',
}

export const userRoles: { [key in UserRole]: string } = {
  [UserRole.SYSTEM_ADMIN]: '',
  [UserRole.AH_ADMIN]: 'Assure Hedge Admin',
  [UserRole.AH_AGENT]: 'Assure Hedge Base User',
  [UserRole.AH_COMPLIANCE]: 'Assure Hedge Compliance Officer',
  [UserRole.AH_FINANCE]: 'Assure Hedge Finance Officer',
  [UserRole.AH_CREDIT]: 'Assure Hedge Credit Officer',
  [UserRole.AH_TRADING]: 'Assure Hedge Trading Officer',
  [UserRole.AH_OPERATIONS]: 'Assure Hedge Operations Officer',
  [UserRole.AH_SALES]: 'Assure Hedge Sales Agent',
  [UserRole.PARTNER_ADMIN]: 'Partner Admin',
  [UserRole.PARTNER_AGENT]: 'Broker',
  [UserRole.CLIENT_ADMIN]: 'Client Admin',
  [UserRole.CLIENT_INDIVIDUAL]: 'Client',

  [UserRole.CLIENT_REGISTRATION]: 'To be registered',
};

export const clientRoles: UserRole[] = [
  UserRole.CLIENT_ADMIN,
  UserRole.CLIENT_INDIVIDUAL,
  UserRole.CLIENT_REGISTRATION,
];

export const ahRoles: UserRole[] = [
  UserRole.AH_ADMIN,
  UserRole.AH_AGENT,
  UserRole.AH_COMPLIANCE,
  UserRole.AH_FINANCE,
  UserRole.AH_CREDIT,
  UserRole.AH_TRADING,
  UserRole.AH_OPERATIONS,
  UserRole.AH_SALES,
];

export const partnerRoles: UserRole[] = [UserRole.PARTNER_ADMIN, UserRole.PARTNER_AGENT];

export const adminRoles: UserRole[] = [UserRole.PARTNER_ADMIN, UserRole.CLIENT_ADMIN];

export const allAhRoles: UserRole[] = [
  UserRole.CLIENT_ADMIN,
  UserRole.CLIENT_INDIVIDUAL,
  UserRole.AH_ADMIN,
  UserRole.AH_AGENT,
  UserRole.AH_COMPLIANCE,
  UserRole.AH_FINANCE,
  UserRole.AH_CREDIT,
  UserRole.AH_TRADING,
  UserRole.AH_OPERATIONS,
  UserRole.AH_SALES,
  UserRole.PARTNER_ADMIN,
  UserRole.PARTNER_AGENT,
];

export function userHasRole(user: { role: UserRole }, role: UserRole | UserRole[]) {
  const userRoles = Array.isArray(role) ? role : [role];
  return userRoles.includes(user.role);
}
