
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormValidation } from '../interfaces';

/**
 * Secure password checker
 *
 * Checks if password is secure against a Validation
 *
 */
@Component
export default class SecurePasswordChecker extends Vue {
  /**
   * Number of validation rules expected form the password
   */
  @Prop({ default: 4 }) levels!: number;

  /**
   * Password validator used to check wheter password meets all validations
   */
  @Prop({ required: true }) validator!: FormValidation;

  get password() {
    return this.validator.$model;
  }

  get validations() {
    const validations = {} as { [key: string]: boolean };
    const passwordValidationsKeys = Object.keys(this.validator).filter((key) => key.includes('password'));
    passwordValidationsKeys.forEach((key) => (validations[key] = this.validator[key].$invalid === false));
    return validations;
  }

  get validationNumber() {
    return Object.keys(this.validations).length;
  }

  get secureLevelCounter() {
    if (!this.password) return 0;
    return Object.values(this.validations).filter((val) => val).length;
  }

  get hasValidLength() {
    return this.validations.passwordLength && this.password;
  }

  get hasNumber() {
    return this.validations.passwordNumber && this.password;
  }

  get hasSpecialChar() {
    return this.validations.passwordSpecialChar && this.password;
  }

  get hasUpper() {
    return this.validations.passwordUpper && this.password;
  }

  get hasLower() {
    return this.validations.passwordLower && this.password;
  }

  get has() {
    return (validation: string) => {
      return Object.keys(this.validations).find((key) => key.includes(validation));
    };
  }
}
