
import { Component } from 'vue-property-decorator';
import BaseFormField from '../fields/BaseFormField.vue';
import { getState } from '../../helpers';
import RemainingCharCounter from 'ah-common-lib/src/common/components/RemainingCharCounter.vue';

@Component({
  components: {
    RemainingCharCounter,
  },
})
export default class InputGroupWrapper extends BaseFormField {
  get disabled() {
    return getState(this.model, 'disabled', false, true);
  }

  get inputGroupClass() {
    return getState(this.model, 'inputGroupClass', '');
  }

  get showLengthCounter() {
    return getState(this.model, 'showLengthCounter', false);
  }

  get hideAppendBg() {
    return getState(this.model, 'removeAppendBg', false);
  }
}
