export enum QuoteState {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CLIENT_EXPRESSED_INTEREST = 'CLIENT_EXPRESSED_INTEREST',
  DELETED = 'DELETED',
  STALE = 'STALE',
  AWAITING_PREMIUM = 'AWAITING_PREMIUM',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  EXERCISE_REQUESTED = 'EXERCISE_REQUESTED',
  EXERCISED = 'EXERCISED',
  CASHOUT_REQUESTED = 'CASHOUT_REQUESTED',
  CASHED_OUT = 'CASHED_OUT',
  EXPIRED_ITM = 'EXPIRED_ITM',
  EXPIRED_OTM = 'EXPIRED_OTM',
}

export const quoteStateText: Record<QuoteState, string> = {
  [QuoteState.DRAFT]: 'This quote is drafted.',
  [QuoteState.SENT]: 'This quote was sent.',
  [QuoteState.CLIENT_EXPRESSED_INTEREST]: 'Client has interest expressed on this quote.',
  [QuoteState.DELETED]: 'This quote has been deleted.',
  [QuoteState.STALE]: 'This is a stale quote.',
  [QuoteState.AWAITING_PREMIUM]: 'This quote is awaiting premium payment.',
  [QuoteState.OPEN]: 'Open quote.',
  [QuoteState.CLOSED]: 'Closed quote.',
  [QuoteState.EXERCISE_REQUESTED]: 'Exercising has been requested for this quote.',
  [QuoteState.EXERCISED]: 'Quote exercised.',
  [QuoteState.CASHOUT_REQUESTED]: 'Cashout has been requested for this quote.',
  [QuoteState.CASHED_OUT]: 'Quote cashed out.',
  [QuoteState.EXPIRED_ITM]: 'Quote expired in the money.',
  [QuoteState.EXPIRED_OTM]: 'Quote expired out of the money.',
};

export const closedQuoteStates = [
  QuoteState.EXPIRED_ITM,
  QuoteState.EXPIRED_OTM,
  QuoteState.CLOSED,
  QuoteState.EXERCISED,
  QuoteState.CASHED_OUT,
  QuoteState.DELETED,
];

// FIXME using this value to filter out deleted quotes - can be removed once Client view filtering occurs
export const nonDeletedQuoteStates = [
  QuoteState.DRAFT,
  QuoteState.SENT,
  QuoteState.CLIENT_EXPRESSED_INTEREST,
  QuoteState.STALE,
  QuoteState.AWAITING_PREMIUM,
  QuoteState.OPEN,
  QuoteState.CLOSED,
  QuoteState.EXERCISE_REQUESTED,
  QuoteState.EXERCISED,
  QuoteState.CASHOUT_REQUESTED,
  QuoteState.CASHED_OUT,
  QuoteState.EXPIRED_ITM,
  QuoteState.EXPIRED_OTM,
];

export const quoteStateBadges: Record<QuoteState, { label: string; class: string }> = {
  [QuoteState.DRAFT]: {
    label: 'Draft',
    class: 'orange',
  },
  [QuoteState.SENT]: {
    label: 'Sent',
    class: 'light-blue',
  },
  [QuoteState.CLIENT_EXPRESSED_INTEREST]: {
    label: 'Interest Expressed',
    class: 'blue',
  },
  [QuoteState.DELETED]: {
    label: 'Deleted',
    class: 'red',
  },
  [QuoteState.STALE]: {
    label: 'Stale',
    class: 'light-grey',
  },
  [QuoteState.AWAITING_PREMIUM]: {
    label: 'Awaiting premium payment',
    class: 'blue',
  },
  [QuoteState.OPEN]: {
    label: 'Open',
    class: 'green',
  },
  [QuoteState.CLOSED]: {
    label: 'Closed',
    class: 'red',
  },
  [QuoteState.EXERCISE_REQUESTED]: {
    label: 'Exercise requested',
    class: 'blue',
  },
  [QuoteState.EXERCISED]: {
    label: 'Exercised',
    class: 'green',
  },
  [QuoteState.CASHOUT_REQUESTED]: {
    label: 'Cash out requested',
    class: 'blue',
  },
  [QuoteState.CASHED_OUT]: {
    label: 'Cashed out',
    class: 'green',
  },
  [QuoteState.EXPIRED_ITM]: {
    label: 'Expired (In the money)',
    class: 'light-grey',
  },
  [QuoteState.EXPIRED_OTM]: {
    label: 'Expired (Out of the money)',
    class: 'light-grey',
  },
};
