import {
  BankingReferenceService,
  BeneficiaryService,
  ClientService,
  PaymentsService,
  TradeService,
  WalletService,
} from 'ah-api-gateways';
import { Toast } from 'ah-common-lib';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import _Vue, { PluginObject } from 'vue';
import { AuthStore, SettingsStore, WalletsStore } from 'ah-common-lib/src/models';
import { NotificationsStore } from 'ah-notifications/src/store';
import VueI18n from 'vue-i18n';

declare module 'vue/types/vue' {
  interface Vue {
    $ahWalletState: AhWalletState;
  }
}

export interface AhWalletsPluginOptions {
  state: AhWalletState;
}

export interface AhWalletState {
  toast: Toast.Toast;
  mediaQuery: MediaQueries;
  i18n: VueI18n;
  store: {
    useWalletsStore: () => WalletsStore;
    useSettingsStore: () => SettingsStore;
    useNotificationsStore: () => NotificationsStore;
    useAuthStore: () => AuthStore;
  };
  services: {
    trade: TradeService;
    wallet: WalletService;
    client: ClientService;
    beneficiary: BeneficiaryService;
    bankingReference: BankingReferenceService;
    payments: PaymentsService;
  };
}

export const AhWalletsPlugin = {
  install: function install(Vue: typeof _Vue, options: AhWalletsPluginOptions) {
    const culledState: AhWalletState = {
      mediaQuery: options.state.mediaQuery,
      toast: options.state.toast,
      store: options.state.store,
      services: options.state.services,
      i18n: options.state.i18n,
    };
    Vue.prototype.$ahWalletState = new _Vue({
      data: culledState,
    });
  },
} as PluginObject<AhWalletsPluginOptions>;

export function useWalletState() {
  return _Vue.prototype.$ahWalletState as AhWalletState;
}
