
import { Component, Vue, Prop } from 'vue-property-decorator';
import randomColor from 'randomcolor';
import { Individual } from 'ah-api-gateways';

@Component
export default class UserBadge extends Vue {
  @Prop({ required: true }) user!: Individual;

  get initials() {
    return ((this.user.firstName || ' ')[0] + (this.user.lastName || ' ')[0]).toUpperCase();
  }

  get color() {
    return randomColor({
      seed: this.user.id,
      luminosity: 'dark',
      hue: 'blue',
    });
  }
}
