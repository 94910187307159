
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { makeValidation } from '../../../form/helpers';
import { BaseFormModel, FormValidation } from '../../../form/interfaces';
import useVuelidate, { Validation } from '@vuelidate/core';
import DynamicForm from '../Form.vue';

@Component({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    const { fm } = this as ValidatedForm;

    return {
      fm: makeValidation(fm),
    };
  },
})
export default class ValidatedForm extends Vue {
  @Prop({ required: true }) fm!: BaseFormModel;

  $refs!: {
    form: DynamicForm;
  };

  private v$!: Validation;

  get validation(): FormValidation {
    return this.v$.fm;
  }

  get form() {
    return this.$refs.form;
  }

  validate() {
    return this.v$.$validate();
  }

  @Watch('validation', { immediate: true })
  onValidationChange() {
    this.$emit('update:validation', this.validation);
  }
}
