
import { Component } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import { getState, setState } from '../../helpers/formHelpers';
import { TreeOptionsBranchOption } from '../../interfaces/form';
import BaseFormField from './BaseFormField.vue';

import ChoiceBranch from './ChoiceBranch.vue';

@Component({
  components: {
    VueSelect,
    ChoiceBranch,
  },
})
export default class ChoiceTreeFormField extends BaseFormField {
  get tree() {
    return getState(this.model, 'options');
  }

  onChoice(choice: TreeOptionsBranchOption[]) {
    if (choice.length) {
      const final = !choice[choice.length - 1].next;
      setState(this.model, 'final', final);
      setState(this.model, 'meta', choice[choice.length - 1].meta);
    }
    this.setValue(choice.map((c) => c.value));
  }

  setValue(value: any) {
    this.$emit('set-value', value, true);
  }

  get showCallText(): string {
    return getState(this.model, 'showCallText', true, true);
  }
}
