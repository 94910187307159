
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VCard extends Vue {
  @Prop({ required: false }) title!: string;

  @Prop({ default: false }) noHeader!: string | boolean;

  @Prop({ default: false }) noBody!: string | boolean;

  private get shouldHideHeader() {
    return this.noHeader !== false;
  }

  private get shouldHideBody() {
    return this.noBody !== false;
  }
}
