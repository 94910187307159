
import { Component, Prop } from 'vue-property-decorator';
import { FormModel } from '../interfaces/form';
import { getState, toggleState } from '../helpers/formHelpers';
import BaseFormElement from './BaseFormElement.vue';

@Component({
  components: {
    RepeatForm: () => import('./RepeatForm.vue'),
    DynamicForm: () => import('./Form.vue'),
  },
})
export default class FormSection extends BaseFormElement {
  @Prop({ required: true }) declare model: FormModel;

  onSectionClick() {
    if (this.isSection && !this.open) {
      this.emitFormEvent({ field: this.field, model: this.model, event: 'form-section-select' });
    }
  }

  toggle($event: Event) {
    toggleState(this.model, 'open');
    $event.stopPropagation();
  }

  get isRepeatForm(): boolean {
    return getState(this.model, 'multiple', false);
  }

  get showForm() {
    return !this.isSection || this.open;
  }

  get open() {
    return getState(this.model, 'open', false);
  }

  get isTogglable() {
    return getState(this.model, 'togglable');
  }

  get isSection() {
    return getState(this.model, 'display') === 'section';
  }

  get title() {
    return getState(this.model, 'title');
  }
}
