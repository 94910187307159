
import { Component, Watch } from 'vue-property-decorator';
import isEqual from 'lodash/isEqual';
import { getState, setState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';
import {
  formatCurrencyValue,
  cleanCurrencyString,
  formatCurrencyString,
  getLocaleSeparators,
} from '../../../helpers/currency';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

@Component({
  components: {
    InputGroupWrapper,
  },
})
export default class FinancialAmountFormField extends BaseFormField {
  inputText: string = '';

  inputDirty = false;

  $refs!: {
    currencyInput: HTMLInputElement;
  };

  get allowNegative() {
    return getState(this.model, 'allowNegative', false);
  }

  get placeholder() {
    return getState(this.model, 'placeholder', '');
  }

  get fieldErrorsShown() {
    return this.field && (this.field.$error || this.customErrors.length > 0);
  }

  get showAsDirty() {
    return this.inputDirty || this.customErrors.length > 0;
  }

  onBlur() {
    setTimeout(() => {
      this.field.$touch();
      this.inputDirty = true;
      this.updateInputText(true);
      this.$forceUpdate();
    });

    this.emitFormEvent({
      field: this.field,
      model: this.model,
      event: 'form-field-blur',
    });
  }

  onFocus() {
    // when the field is focused with a value of 0.00, the input will automatically
    // clear out so the user doesn't have to delete all the digits
    if (this.field.$model === 0) {
      this.$refs.currencyInput.value = '';
    }
    setState(this.model, 'unexpectedError', false);
  }

  @Watch('field.$model', { immediate: true })
  onFieldModelChange() {
    setTimeout(() => {
      this.updateInputText();
    });
  }

  updateInputText(force = false) {
    const existing = this.cleanNumber(this.inputText);

    if (force || existing !== this.field.$model) {
      this.inputText = this.formatNumber(this.field.$model) || '';
    }
  }

  setAmount(value: string, keepPristine = false) {
    if (value === '') {
      this.$emit('set-value', '', keepPristine);
    }
    const separators = getLocaleSeparators();
    let cursorStart = this.$refs.currencyInput.selectionStart || 0;
    let cursorEnd = this.$refs.currencyInput.selectionEnd || 0;
    value = value.replace(/[a-zA-Z ]/g, '');
    let cleanVal = value ? cleanCurrencyString(value, undefined, true) : value;
    if (value.substr(0, cursorStart) === separators.decimalSeparator || cleanVal === '.') {
      // Handle '.' as '0.' to move the cursor to the right place (the start of the decimal values)
      cursorStart += 1;
      cursorEnd += 1;
      if (cleanVal === '.') {
        cleanVal = '0.';
      }
    }
    const cleanNumber = this.cleanNumber(cleanVal);
    if (cleanVal && !Number.isNaN(cleanNumber)) {
      this.inputText = formatCurrencyString(cleanVal);
    } else {
      this.inputText = cleanVal;
    }
    this.$forceUpdate();

    if (!Number.isNaN(cleanNumber)) {
      if (
        (this.allowNegative || (!this.allowNegative && cleanNumber > -1)) &&
        !isEqual(cleanNumber, this.field.$model)
      ) {
        this.$emit('set-value', cleanNumber, keepPristine);
      }
      this.$nextTick(() => {
        if (typeof cursorStart === 'number' && typeof cursorEnd === 'number') {
          if (value === '') {
            this.$refs.currencyInput.setSelectionRange(0, 0);
          } else {
            const delta = this.calcLengthDifference(
              value.substr(0, cursorStart),
              this.$refs.currencyInput.value.substr(0, cursorStart)
            );
            this.$refs.currencyInput.setSelectionRange(cursorStart + delta, cursorEnd + delta);
          }
        }
      });
    }
  }

  formatNumber(num: number | null) {
    if (typeof num === 'number' && !Number.isNaN(num)) {
      return formatCurrencyValue(num);
    }
    return '';
  }

  private cleanNumber(amount: string): number {
    if (!amount) {
      return NaN;
    }

    const cleanVal = cleanCurrencyString(amount);
    const number = Number(cleanVal);

    if (Number.isNaN(number)) {
      return NaN;
    }
    return Number(number.toFixed(2));
  }

  private calcLengthDifference(prev: string, curr: string): number {
    const deltaSeparatorsPrev = prev.length - cleanCurrencyString(prev).length;
    const deltaSeparatorsNow = curr.length - cleanCurrencyString(curr).length;

    return deltaSeparatorsNow - deltaSeparatorsPrev;
  }
}
