
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ExpandTransition extends Vue {
  afterEnter(element: HTMLElement) {
    // eslint-disable-next-line no-param-reassign
    element.style.height = `auto`;
  }
  enter(element: HTMLElement) {
    const { width } = getComputedStyle(element);
    /* eslint-disable no-param-reassign */
    element.style.width = width;
    element.style.position = `absolute`;
    element.style.visibility = `hidden`;
    element.style.height = `auto`;
    /* eslint-enable */
    const { height } = getComputedStyle(element);
    /* eslint-disable no-param-reassign */
    element.style.width = '';
    element.style.position = '';
    element.style.visibility = '';
    element.style.height = '0';
    /* eslint-enable */
    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line no-unused-expressions
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      // eslint-disable-next-line no-param-reassign
      element.style.height = height;
    });
  }
  leave(element: HTMLElement) {
    const { height } = getComputedStyle(element);
    // eslint-disable-next-line no-param-reassign
    element.style.height = height;
    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line no-unused-expressions
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      // eslint-disable-next-line no-param-reassign
      element.style.height = '0';
    });
  }
}
