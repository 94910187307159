import { FeatureFlag, FeatureFlagResponse, FeatureFlagService } from 'ah-api-gateways';
import { CachedItem } from 'ah-common-lib/src/helpers';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { defineStore } from 'pinia';
import { StoreSupportData } from 'ah-common-lib/src/store';
import Vue from 'vue';

// Importing persisted state plugin for type completion
import 'pinia-plugin-persistedstate';

export type FeatureFlagStoreSupportData = StoreSupportData<
  {},
  {
    featureFlag: FeatureFlagService;
  }
>;

export interface FeatureFlagStoreSetupOptions {
  supportData: FeatureFlagStoreSupportData;
}

let sd: FeatureFlagStoreSupportData;

const useStore = defineStore('featureFlagStore', {
  persist: true,
  state: () => {
    return {
      featureFlags: {} as { [key: string]: CachedItem<FeatureFlagResponse> },
    };
  },
  actions: {
    loadFeatureFlag(payload: {
      featureFlag: FeatureFlag;
      oboClientId?: string;
      force?: boolean;
    }): Promise<FeatureFlagResponse | null> {
      let key: string = payload.featureFlag;

      if (payload.oboClientId) {
        key += `-${payload.oboClientId}`;
      }

      if (!this.featureFlags[key]) {
        Vue.set(this.featureFlags, key, new CachedItem<FeatureFlag>());
      }
      return CachedItem.loadCachedItem(
        this.featureFlags[key],
        sd.s.featureFlag.getfeatureFlag({ featureFlag: payload.featureFlag }, payload.oboClientId, {
          errors: { silent: true },
        }),
        payload?.force
      );
    },
    clearStore() {
      this.featureFlags = {};
    },
    async [commonStoreActions.onOutMaintenance]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogout]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogoutOtherTab]() {
      this.clearStore();
    },
  },
});

export type FeatureFlagStore = ReturnType<typeof useStore>;

export function setupFeatureFlagStore(options: FeatureFlagStoreSetupOptions) {
  sd = options.supportData;
}

export function useFeatureFlagStore(): FeatureFlagStore {
  if (!sd) {
    throw 'Feature flag store not setup! Please ensure setup method is called on app boot.';
  }
  return useStore();
}
