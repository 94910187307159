
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { makeFormValidation, makeFormModel, submitForm, toDataModel } from '../form/helpers';
import { otpField } from '../form/models/commonFields';
import { FormValidation, FormEvent } from '../form/interfaces';
import useVuelidate, { Validation } from '@vuelidate/core';
import { Observable } from 'rxjs';
import WithRequestManager from '../requestManager/WithRequestManager.vue';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';

const otpFM = () =>
  makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  });

@Component({
  components: {
    BModal,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    otpForm: makeFormValidation(otpFM()),
  },
})
export default class OtpModalComponent extends Mixins(WithRequestManager) {
  $refs!: {
    modal: BModal;
  };
  @Prop({ required: false }) otpPhone?: string;

  @Prop({ default: false }) staticOTP!: boolean;

  @Prop({ required: true }) request!: (otp: string) => Observable<any>;

  @Prop({ required: true }) refreshOtpRequest!: () => Observable<any>;

  @Prop({ required: true }) otpErrorCheck!: (error: any) => boolean;

  private v$!: Validation;

  requestManagerConfig = {
    exposeToParent: ['refreshOtp'],
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'refreshOtp') {
      this.refreshOtp();
    }
  }

  private otpError = false;

  private otpForm = otpFM();

  otpMessage = otpMessage;

  show() {
    this.refreshOtp();
    this.$refs.modal.show();
  }

  cancel() {
    this.$emit('cancel');
  }

  refreshOtp() {
    this.requestManager.currentOrNew('refreshOtp', this.refreshOtpRequest()).subscribe(() => {
      this.otpError = false;
      this.otpForm.otp = [];
    });
  }

  triggerRequest() {
    submitForm(this.otpFV);
    if (this.otpFV.$invalid) {
      return;
    }
    const otp: string = toDataModel(this.otpForm).otp;

    this.requestManager.cancelAndNew('otpSubmit', this.request(otp)).subscribe(
      (result) => {
        this.$emit('completed', result);
      },
      (error) => {
        if (this.otpErrorCheck(error)) {
          this.otpError = true;
        } else {
          this.$emit('error', error);
        }
      }
    );
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-submit') {
      this.triggerRequest();
    }
  }

  get otpFV(): FormValidation {
    return this.v$.otpForm as FormValidation;
  }
}
