
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { makeFormValidation, makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import BeneficiaryAccountSelector from 'ah-beneficiaries/src/components/BeneficiaryAccountSelector.vue';
import BeneficiaryEditModal from 'ah-beneficiaries/src/components/BeneficiaryEditModal.vue';
import { TradeFundsDestination, TradeDetails } from 'ah-trades/src/models/trade';
import { Beneficiary, AuthorityType, Permission, getBuyCcy, AmountType } from 'ah-api-gateways';
import WalletTransferText from 'ah-wallets/src/components/WalletTransferText.vue';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import InjectObo from 'ah-common-lib/src/onBehalfOf/InjectObo.vue';
import { TradeDestination } from './tradeDestination';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import IconAlertCircle from 'ah-common-lib/src/icons/components/IconAlertCircle.vue';
import { PaymentLimitData, BankingScheme, BeneficiaryCategory, DrawdownDetails } from 'ah-api-gateways/models';
import useVuelidate, { Validation } from '@vuelidate/core';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { DrawdownQuotePriceResponse } from 'ah-api-gateways/models';
import { PaymentAggregateLimit } from 'ah-api-gateways/models/payments/paymentAggregatedLimit';
import AggregatedLimitErrorMessage from '../../../../ah-payments/src/components/AggregatedLimitErrorMessage.vue';
import { aggregatedPaymentReviewRoute } from '../../composables/aggregatedPaymentLimitChecker';

const destinationOptions: FieldOptionObj[] = [
  { label: 'Keep funds', value: TradeFundsDestination.KEEP },
  { label: 'Make payment', value: TradeFundsDestination.SEND },
];

const fundsFormFM = () =>
  makeFormModel({
    name: 'fundsForm',
    state: {
      class: 'form-narrow margin-auto',
    },
    fieldType: 'form',
    fields: [
      radioField('destination', '', destinationOptions, {
        defaultValue: TradeFundsDestination.SEND,
        inline: true,
      }),
    ],
  });

/**
 * Trade Destination form
 *
 * Uses Authorities to determine if choosing "SEND" is allowed
 */
@Component({
  components: {
    AggregatedLimitErrorMessage,
    BeneficiaryAccountSelector,
    BeneficiaryEditModal,
    IconAlertCircle,
    WalletTransferText,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    fundsForm: makeFormValidation(fundsFormFM()),
  },
})
export default class TradeDestinationForm extends Mixins(InjectObo, WithRequestManager) {
  $refs!: {
    beneficiarySelector: any; // InstanceType<typeof BeneficiaryAccountSelector>;
  };

  /**
   * Trade details object (managed by TradeDetailsForm)
   */
  @Prop({ default: null }) tradeDetails!: TradeDetails | DrawdownDetails | null;

  /**
   * Trade price request and response (as received by getting/refreshing  the trade)
   */
  @Prop({ default: null }) tradePrice!: DrawdownQuotePriceResponse | null;

  /**
   * Possible beneficiary target of the trade
   *
   * synchronizable via `update:beneficiary` or .sync modifier
   */
  @Prop({ required: false }) beneficiary?: Beneficiary;

  /**
   * Trade destination object (managed by TradeDestinationForm)
   *
   * synchronizable via `update:tradeDestination` or .sync modifier
   */
  @Prop({ default: null }) tradeDestination!: TradeDestination | null;

  /**
   * Whether to allow keeping funds
   */
  @Prop({ default: true }) allowKeepFunds!: boolean | string;

  /**
   * Whether to allow sending money
   */
  @Prop({ default: true }) allowSendMoney!: boolean | string;

  private v$!: Validation;

  TradeFundsDestination = TradeFundsDestination;

  aggregatedPaymentReviewRoute = aggregatedPaymentReviewRoute;

  BankingScheme = BankingScheme;

  fundsForm = fundsFormFM();

  formatCurrencyValue = formatCurrencyValue;

  requestManagerConfig = {
    exposeToParent: ['getClientPermissions'],
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getClientPermissions') {
      this.checkUserPermissions();
    }
  }

  private beneficiaryQuery = '';

  private clientPermissions: Permission[] = [];

  private currentPaymentLimit: PaymentLimitData | null = null;

  private hasSwiftBeneficiary = false;

  private beneficiarySelected: Beneficiary | null = null;

  private isAggregatedLimitReached: boolean = false;

  private aggregatedLimitForCurrency: PaymentAggregateLimit | null = null;

  beforeMount() {
    if (!this.tradeDestination) {
      this.emitDestinationChange(false);
    }
  }

  get canManageBeneficiaries() {
    return (
      this.onBehalfOfClient ||
      this.$ahTradesState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES)
    );
  }

  get displayWarning() {
    return this.tradeDetails && this.currentPaymentLimit && this.beneficiarySelected && !this.beneficiaryIsSwift;
  }

  get beneficiaryIsSwift() {
    if (this.beneficiarySelected) {
      return this.beneficiarySelected.bankingScheme === BankingScheme.SWIFT;
    }

    return false;
  }

  /**
   * Send Money is allowed for Trader OBO (Admin users), otherwise depends on OBO permissions
   *
   * Can be forced false via prop `allowSendMoney`
   */
  get sendMoneyAllowed() {
    if (this.allowSendMoney === false) {
      return false;
    }
    if (this.$ahTradesState.store.useAuthStore().isAHUser) {
      return true;
    }
    if (this.onBehalfOfClient && this.clientPermissions.length > 0) {
      const permission = this.clientPermissions.find((p) => p.authority === AuthorityType.PAY_ON_BEHALF_OF);
      return permission?.allow ?? false;
    }
    return this.$ahTradesState.store.useAuthStore().hasAuthorities(AuthorityType.MAKE_PAYMENTS);
  }

  get buyCcy() {
    if (this.tradePrice) {
      return getBuyCcy(this.tradePrice);
    }
  }

  get isPaymentOverLimit() {
    if (!this.currentPaymentLimit?.hasLimit || !this.tradeDetails?.amount) return false;
    if (this.tradeDetails.amountType === AmountType.BUY) {
      return this.tradeDetails.amount > this.currentPaymentLimit.amountLimit;
    }
    const buyPrice =
      this.tradePrice?.ccy1.amountType === AmountType.BUY ? this.tradePrice?.ccy1 : this.tradePrice?.ccy2;

    return buyPrice && buyPrice.clientAmount > this.currentPaymentLimit.amountLimit;
  }

  get isPaymentInvalid() {
    if (this.tradeDestination?.destination === TradeFundsDestination.KEEP) return false;
    else if (!this.tradeDestination?.beneficiary) return true;
    return this.isPaymentOverLimit && this.tradeDestination.beneficiary.bankingScheme !== BankingScheme.SWIFT;
  }

  get shouldAllowKeepFunds() {
    return this.allowKeepFunds !== false;
  }

  @Watch('tradePrice')
  @Watch('aggregatedLimitForCurrency')
  @Watch('tradeDestination.destination')
  onBuyCurrencyAmountChange() {
    this.isAggregatedLimitReached = false;
    if (this.tradePrice?.ccy1.clientAmount && this.tradeDestination?.destination === TradeFundsDestination.SEND) {
      this.aggregatedLimitValidation(this.tradePrice.ccy1.clientAmount);
    }
  }

  @Watch('onBehalfOfClient', { immediate: true })
  checkUserPermissions() {
    if (this.onBehalfOfClient) {
      this.requestManager
        .currentOrNew(
          'getClientPermissions',
          this.$ahTradesState.services.authz.getClientsPermissions(this.onBehalfOfClient.id)
        )
        .subscribe((response) => (this.clientPermissions = response));
    }
  }

  @Watch('shouldAllowKeepFunds')
  @Watch('sendMoneyAllowed', { immediate: true })
  onSendMoneyAllowedChange() {
    const destinationField = getChildModel(this.fundsForm, 'destination');
    if (this.allowKeepFunds !== false && !this.tradeDestination) {
      this.fundsForm.destination = TradeFundsDestination.KEEP;
    }

    let options = destinationOptions;
    options = options.map((i) => ({
      ...i,
      disabled: i.value === TradeFundsDestination.SEND ? !this.sendMoneyAllowed : !this.shouldAllowKeepFunds,
    }));

    if (destinationField) {
      setState(destinationField, 'options', options);
      this.fundsForm.destination = options.find((opt) => !opt.disabled)?.value ?? null;
    }
    this.emitDestinationChange();
  }

  @Watch('buyCcy')
  @Watch('tradeDetails.buyCurrency')
  onTradeDetailsChange() {
    this.emitDestinationChange(false);
  }

  @Watch('tradeDetails.buyCurrency', { immediate: true })
  onBuyCurrencyChange() {
    const query: any = {};
    query.category = BeneficiaryCategory.CLIENT_3RD_PARTY;
    query.clientId = this.onBehalfOfClient?.id || this.$ahTradesState.store.useAuthStore().loggedInIdentity!.client!.id;
    query.pageSize = 1;
    query.bankingScheme = BankingScheme.SWIFT;
    query.currency = this.tradeDetails?.buyCurrency;

    this.requestManager
      .sameOrCancelAndNew('getSwiftBeneficiaries', this.$ahTradesState.services.beneficiary.listBeneficiaries(query))
      .subscribe((response) => {
        this.hasSwiftBeneficiary = response.total >= 1;
      });
  }

  @Watch('tradeDestination', { immediate: true })
  onTradeDestinationChange() {
    if (this.tradeDestination) {
      this.fundsForm.destination = this.tradeDestination.destination;
      this.beneficiaryQuery = this.tradeDestination.beneficiaryQuery ?? '';
      if (this.tradeDestination.destination === TradeFundsDestination.SEND) {
        this.loadAggregatedLimit();
      }
    }
  }

  @Watch('tradeDetails', { immediate: true })
  @Watch('tradeDestination')
  onTradeChange(newDetails: TradeDetails, oldDetails: TradeDetails) {
    if (this.tradeDetails && newDetails.sellCurrency !== oldDetails?.sellCurrency) {
      this.$ahTradesState.store
        .useSettingsStore()
        .loadPaymentLimit({
          force: false,
          currency: this.tradeDetails!.buyCurrency,
          bankingScheme: BankingScheme.LOCAL,
        })
        .then((response) => (this.currentPaymentLimit = response));
    }
  }

  get validDestination() {
    if (this.fundsForm.destination === TradeFundsDestination.SEND) {
      return (
        (!!this.beneficiarySelected ?? !!this.beneficiary ?? !!this.tradeDestination?.beneficiary) &&
        !this.isAggregatedLimitReached
      );
    }
    return true;
  }

  emitDestinationChange(dirty = true, beneficiary?: Beneficiary | null) {
    const out: TradeDestination = {
      ...this.tradeDestination,
      beneficiary: this.beneficiarySelected ?? beneficiary ?? this.tradeDestination?.beneficiary ?? null,
      beneficiaryQuery: this.beneficiaryQuery,
      reason: this.tradeDestination?.reason,
      reference: this.tradeDestination?.reference,
      destination: this.beneficiary ? TradeFundsDestination.SEND : this.fundsForm.destination,
      dirty,
      valid: this.validDestination,
    };

    // if beneficiary is set to null we want to unselect the beneficiary forcefully
    if (beneficiary === null) {
      delete (out as any).beneficiary;
    }

    if (
      this.fundsForm.destination === TradeFundsDestination.KEEP ||
      this.tradeDetails?.buyCurrency !== out.beneficiary?.currency
    ) {
      out.beneficiary = null;
    }

    this.$emit('update:tradeDestination', out);
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      this.emitDestinationChange();
    }
  }

  onAccountSelected(beneficiary: Beneficiary | null) {
    this.beneficiarySelected = beneficiary;
    this.emitDestinationChange(this.tradeDestination?.dirty, beneficiary);
  }

  onBeneficiaryAdded() {
    this.$refs.beneficiarySelector.onBeneficiaryAdded();
  }

  // TODO EC: Use useAggregatedPaymentLimitChecker when converting this component to composition API
  loadAggregatedLimit() {
    if (
      !this.tradeDetails &&
      (!this.onBehalfOfClient?.id || !this.$ahTradesState.store.useAuthStore().loggedInIdentity?.client?.id)
    ) {
      return;
    }

    const params = {
      clientId: (this.onBehalfOfClient?.id ??
        this.$ahTradesState.store.useAuthStore().loggedInIdentity?.client?.id) as string,
      oboClientId: this.onBehalfOfClient?.id,
      paymentCurrency: this.tradeDetails!.buyCurrency,
    };

    this.requestManager
      .sameOrCancelAndNew('getAggregateLimit', this.$ahTradesState.services.payments.getAggregateLimit(params))
      .subscribe((aggregatedLimitResponse) => {
        this.aggregatedLimitForCurrency = aggregatedLimitResponse;
      });
  }

  // TODO EC: Use useAggregatedPaymentLimitChecker when converting this component to composition API
  aggregatedLimitValidation(amount: number) {
    if (!this.aggregatedLimitForCurrency) return;
    const currentAmountInGBP = this.aggregatedLimitForCurrency.currentRate * amount;
    let currentAggregatedAmountInGBP = this.aggregatedLimitForCurrency.aggregatedAmount + currentAmountInGBP;

    this.isAggregatedLimitReached = currentAggregatedAmountInGBP > this.aggregatedLimitForCurrency.limitAmount;
  }
}
