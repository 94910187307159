
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BTable, BOverlay } from 'bootstrap-vue';
import { ListResponse } from 'ah-api-gateways';
import VButton from '../VButton.vue';
import NotFoundIcon from '../../../icons/components/NotFoundIcon.vue';
import LoadingIcon from '../../../icons/components/LoadingIcon.vue';
import { RequestState } from 'ah-requests';
import { TableFieldDefinition } from '../../../models/table';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

/**
 * Dymanic table implementation
 *
 * Wraps PrimeVue DataTable component - refer to https://www.primefaces.org/primevue-v2/#/datatable
 *
 * Emits extra events:
 * - row-toggle-all (no payload) triggered to signal the selectbox in the header has been clicked
 * - row-clicked (payload: row) Maps from Primevue event row-click
 */
@Component({
  components: {
    BTable,
    BOverlay,
    VButton,
    NotFoundIcon,
    LoadingIcon,
    DataTable,
    Column,
  },
})
export default class DynamicTable<T = any> extends Vue {
  @Prop({ default: null }) data!: ListResponse<T> | null;

  @Prop({ default: () => [] }) expandedRows!: any[];

  @Prop({ default: 'idle' }) dataLoadState!: RequestState;

  @Prop({ default: 'items' }) itemsLabel!: string;

  @Prop({ default: 'id' }) primaryKey!: string;

  @Prop({ default: false }) nowrap!: boolean | string;

  @Prop({ default: false }) customSearch!: boolean | string;

  @Prop({ default: () => [] }) selectedItems!: any[];

  @Prop({ default: false }) showEmptyHeaders!: boolean | string;

  @Prop({ default: false }) bordered!: boolean | string;

  @Prop({ required: true }) fields!: TableFieldDefinition[];

  @Prop({ default: false }) singleRowDetails!: boolean | string;

  @Prop({ default: false }) withRowDetails!: boolean | string | Function;

  /**
   * Whether clicking the whole row should toggle details
   * Only relevant if withRowDetails is truthy
   */
  @Prop({ default: true }) showDetailsOnRowClick!: boolean | string | Function;

  /**
   * Whether to use flex scroll
   *
   * Flex scroll requires setting 'flex-basis' values for every column
   * Please refer to PrimeVue documentation
   */
  @Prop({ default: false }) withFlexScroll!: string | boolean;

  /**
   * Whether to use 'simple' scroll - overriden by `withFlexScroll`
   *
   * In this mode, fixed columns are not possible (will simply add a overflow: auto to the wrapping element)
   */
  @Prop({ default: false }) withSimpleScroll!: string | boolean;

  /**
   * Whether to show a scrollbar at the top, sinchronized with the bottom bar
   *
   * Only available if `withFlexScroll` is truthy
   */
  @Prop({ default: false }) withTopScrollbar!: string | boolean;

  /**
   * Whether to animate column transitions
   */
  @Prop({ default: false }) animateCols!: boolean | string;

  /**
   * Time taken in ms for the animation to trigger
   */
  @Prop({ default: 650 }) animationTimeout!: number;

  /**
   * What to show on empty cells. Defaults to empty string
   */
  @Prop({ default: '' }) emptyCellValue!: string;

  /**
   * Whether to force table size re-rendering on window size change
   */
  @Prop({ default: false }) forceTableSizeRerendering!: boolean | string;

  /**
   * Whether to display diferent color on odd rows
   */
  @Prop({ default: true }) striped!: boolean | string;

  private sortDesc: boolean = false;

  private sortBy: string = '';

  private scrollTimeout: number | null = null;

  private tableResizeObserver: ResizeObserver | null = null;

  private dataTableRenderingIndex = 1;

  private rendering = false;

  private debounceTableSizeRender!: DebouncedFunc<() => void>;

  mounted() {
    this.setTableSizeObserver();
    this.currentFields = this.fields;
    this.debounceTableSizeRender = debounce(() => {
      if (this.forceTableSizeRerendering !== false) {
        this.dataTableRenderingIndex++;
      }
      this.onTableSizeChange();
      this.rendering = false;
    }, 50);
  }

  isSelected(item: any) {
    return this.selectedItems.includes(item[this.primaryKey]);
  }

  allSelected() {
    return !this.data?.list.find((i) => !this.isSelected(i));
  }

  anySelected() {
    return this.data?.list.find((i) => this.isSelected(i));
  }

  makeCellData(slotProps: any, field: TableFieldDefinition) {
    return {
      ...slotProps,
      fieldData: field,
      field: field.key,
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
    };
  }

  makeThCellData(slotProps: any, field: TableFieldDefinition) {
    return {
      ...this.makeCellData(slotProps, field),
      sort: () => this.toggleSort(field),
    };
  }

  makeTdCellData(slotProps: any, field: TableFieldDefinition) {
    return {
      ...this.makeCellData(slotProps, field),
      item: slotProps.data,
      isSelected: this.isSelected(slotProps.data),
      value: slotProps.data[field.key],
      hasDetails: this.expandedRows.indexOf(slotProps.data[this.primaryKey]) >= 0,
      toggleDetails: (value?: boolean) => this.toggleRowDetails(slotProps.data, value),
    };
  }

  makeExpansionRowData(slotProps: any) {
    return {
      ...slotProps,
      item: slotProps.data,
      hasDetails: this.expandedRows.indexOf(slotProps.data[this.primaryKey]) >= 0,
      toggleDetails: (value?: boolean) => this.toggleRowDetails(slotProps.data, value),
    };
  }

  setTableSizeObserver() {
    const tableElem = document.getElementById('ah-dynamic-table')!;
    this.tableResizeObserver = new ResizeObserver(() => {
      if (!this.rendering) {
        this.rendering = true;
        this.debounceTableSizeRender();
      }
    });
    this.tableResizeObserver.observe(tableElem);
  }

  get showData() {
    return this.showEmptyHeaders !== false || this.hasData;
  }

  get isFlexScroll() {
    return this.withFlexScroll !== false;
  }

  get isSimpleScroll() {
    return this.withFlexScroll === false && this.withSimpleScroll !== false;
  }

  get isWithTopScrollbar() {
    return this.isFlexScroll && this.withTopScrollbar !== false;
  }

  private onTableSizeChange() {
    if (this.isWithTopScrollbar) {
      const table = this.getTableElement();
      const scroll: HTMLElement | undefined = this.$refs.horizontalScrollerInner as HTMLElement;
      if (table && scroll) {
        // setting scroll slightly lower that scrollWidth to not trigger overflow
        scroll.style.width = `${table.scrollWidth - 2}px`;
        this.calculateDetailsSize();
      } else {
        window.setTimeout(this.onTableSizeChange, 50);
      }
    }
  }

  private calculateDetailsSize() {
    const detailsElement = document.getElementsByClassName('p-datatable-row-expansion');
    const thElement = document.getElementsByTagName('tr')[0];
    for (const item of detailsElement) {
      const tdList = item.getElementsByTagName('td');
      if (tdList.length === 1) {
        const node = document.createElement('td');
        node.style.minWidth = `${thElement.scrollWidth - thElement.clientWidth}px`;
        item.prepend(node);
      } else {
        tdList[0].style.minWidth = `${thElement.scrollWidth - thElement.clientWidth}px`;
      }
    }
  }

  get hasData() {
    return !!this.data?.list?.length;
  }

  toggleSort(field: TableFieldDefinition) {
    const sortBy = field.sortKey || field.key;
    this.sortDesc = sortBy === this.sortBy ? !this.sortDesc : false;
    this.sortBy = sortBy;
    this.emitSort();
  }

  /**
   * Animation methods and data
   */

  private currentFields: any[] = [];

  private transitionFields: any[] | null = null;

  private fieldChangeTimeout: number | null = null;

  get renderedFields() {
    return (this.transitionFields || this.currentFields).filter((f) => !f.hidden);
  }

  @Watch('fields')
  onFieldsChange(nextFields: any[], prevFields: any[]) {
    if (this.fieldChangeTimeout) {
      clearTimeout(this.fieldChangeTimeout);
    }

    if (this.animateCols !== false) {
      this.currentFields = [...prevFields];
      const fields = this.calculateTransition();
      if (!fields.fieldActions.filter((i) => i !== 'keep').length) {
        this.currentFields = nextFields;
        this.transitionFields = null;
        return true;
      }
      this.transitionFields = fields.transitionFields;
      this.$nextTick(() => {
        this.animateTransition(fields.fieldActions);

        this.fieldChangeTimeout = window.setTimeout(() => {
          this.currentFields = this.fields;
          this.transitionFields = null;
        }, this.animationTimeout);
      });
      return true;
    } else {
      this.currentFields = nextFields;
      this.transitionFields = null;
    }
  }

  calculateTransition() {
    const transitionFields = cloneDeep(this.currentFields);
    const fieldActions: ('keep' | 'remove' | 'add')[] = [];

    let fieldIndex = -1;
    this.currentFields.forEach((field) => {
      const found = this.fields.findIndex((f) => f.key === field.key);
      if (found < fieldIndex) {
        fieldActions.push('remove');
      } else {
        fieldActions.push('keep');
        fieldIndex = found;
      }
    });

    fieldIndex = 0;
    this.fields.forEach((field) => {
      const found = transitionFields.findIndex((f, index) => f.key === field.key && fieldActions[index] === 'keep');
      if (found >= fieldIndex) {
        transitionFields[found] = field;
        fieldIndex = found + 1;
      } else {
        transitionFields.splice(fieldIndex, 0, field);
        fieldActions.splice(fieldIndex, 0, 'add');
        fieldIndex++;
      }
    });
    return {
      transitionFields,
      fieldActions,
    };
  }

  /**
   * Animates extension/collapse of columns, by overwriting CSS values
   *
   * NOTE: This only works with knowledge of the current CSS styles (padding-right is reduced to 0 as tables have it)
   */
  animateTransition(fieldActions: ('keep' | 'remove' | 'add')[]) {
    if (!this.transitionFields) {
      return;
    }

    const fields = this.transitionFields!;
    const defaultCellWidth = `${100 / this.fields.length}%`;

    fieldActions.forEach((action, index) => {
      const field = fields[index];
      const colWidth = field.colStyle?.width || defaultCellWidth;
      if (action === 'add') {
        this.$set(field, 'colStyle', { ...field.colStyle, width: '0', transition: '', 'padding-right': '0' });
        this.$nextTick(() => {
          const styleObj = { ...field.colStyle, width: colWidth, transition: 'width 0.6s, padding 0.6s' };
          delete styleObj['padding-right'];
          this.$set(field, 'colStyle', styleObj);
        });
      } else if (action === 'remove') {
        this.$set(field, 'colStyle', { ...field.colStyle, width: colWidth, transition: '' });
        this.$nextTick(() => {
          this.$set(field, 'colStyle', {
            ...field.colStyle,
            width: '0',
            'padding-right': '0',
            transition: 'width 0.6s, padding 0.6s',
          });
        });
      }
    });
  }

  /**
   * Gets inner table element, if it exists
   */
  getTableElement(): HTMLElement | undefined {
    const table: Vue | undefined = this.$refs.dataTable as Vue;
    return (table?.$el as HTMLElement | undefined)?.firstElementChild as HTMLElement;
  }

  scrollTo(left: number) {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = window.setTimeout(() => {
      const table: HTMLElement | undefined = this.getTableElement();
      const scroll: HTMLElement | undefined = this.$refs.horizontalScroller as HTMLElement;
      if (table) {
        table.scrollLeft = left;
        if (scroll) {
          scroll.scrollLeft = left;
        }

        if (table.scrollLeft !== left) {
          const clearTimeoutRef = () => {
            window.setTimeout(() => {
              this.scrollTimeout = null;
            });
            table.removeEventListener('scroll', clearTimeoutRef);
          };
          table.addEventListener('scroll', clearTimeoutRef);
        } else {
          window.setTimeout(() => {
            this.scrollTimeout = null;
          });
        }
      } else {
        this.scrollTimeout = null;
      }
    });
  }

  private onScroll(event: Event) {
    if ((event as any).srcElement?.scrollLeft) {
      this.scrollTo((event as any).srcElement?.scrollLeft);
    } else {
      event.stopImmediatePropagation();
    }
  }

  @Watch('data')
  onDataChange() {
    if (this.data) {
      const data = (this.data ?? {}) as any;
      this.sortBy = data.sort;
      this.sortDesc = data.sortDirection === 'DESC';
    }
  }

  emitSort() {
    this.$emit('sort', {
      sort: this.sortBy,
      sortDirection: this.sortDesc ? 'DESC' : 'ASC',
    });
  }

  propagateClick(event: MouseEvent) {
    ((event.target as HTMLInputElement).parentElement as HTMLDivElement).click();
  }

  beforeDestroy() {
    this.tableResizeObserver?.disconnect();
  }

  hasRowDetails(item: any) {
    return typeof this.withRowDetails === 'function' ? this.withRowDetails(item) : this.withRowDetails !== false;
  }

  onRowClicked(item: any) {
    this.$emit('row-clicked', item);
    if (this.hasRowDetails(item) && this.showDetailsOnRowClick !== false) {
      this.toggleRowDetails(item);
    }
  }

  get expandedRowItems() {
    return this.data?.list?.filter((i: any) => i[this.primaryKey] && this.expandedRows.includes(i[this.primaryKey]));
  }

  hasDetails(item: any) {
    const key = item[this.primaryKey];
    return this.expandedRows.indexOf(key) >= 0;
  }

  toggleRowDetails(item: any, value?: boolean) {
    let rows = [...this.expandedRows];
    const key = item[this.primaryKey];
    const index = rows.indexOf(key);
    const itemExists = index >= 0;
    const detailsShown = value ?? !itemExists;
    if (this.singleRowDetails !== false) {
      rows = [];
    }
    if (detailsShown && !itemExists) {
      rows.push(key);
    }
    if (!detailsShown && itemExists) {
      rows.splice(index, 1);
    }
    this.$emit('update:expandedRows', rows);
    this.$emit('row-details-toggled', { item, shown: detailsShown });
  }
}
