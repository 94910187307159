
import { Component, Vue, Prop } from 'vue-property-decorator';
import { InfoTooltipIcon } from '../../icons/components';

@Component({
  components: {
    InfoTooltipIcon,
  },
})
export default class TooltipComponent extends Vue {
  @Prop({ default: '' }) text!: string;
}
