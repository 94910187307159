
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppLogoIcon, CloseIcon, ArrowRightIcon } from '../../icons/components';

@Component({
  components: {
    AppLogoIcon,
    CloseIcon,
    ArrowRightIcon,
  },
})
export default class ManagedFlow extends Vue {
  @Prop({ default: '' }) title!: string;

  @Prop({ default: true }) showBottom!: boolean;

  @Prop({ default: true }) showContinue!: boolean;

  @Prop({ default: true }) continueAllowed!: boolean;

  @Prop({ default: 'Next Step' }) continueText!: string;

  @Prop({ default: true }) showExit!: boolean;

  @Prop({ default: true }) exitAllowed!: boolean;

  @Prop({ default: 'Cancel' }) exitText!: string;

  @Prop({ default: false }) showHelp!: boolean;
}
