
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VCol extends Vue {
  @Prop({ required: false }) cols?: string | number;

  @Prop({ required: false }) xl?: string | number;

  @Prop({ required: false }) lg?: string | number;

  @Prop({ required: false }) md?: string | number;

  @Prop({ required: false }) sm?: string | number;

  @Prop({ required: false }) offset?: string | number;

  @Prop({ required: false }) offsetXl?: string | number;

  @Prop({ required: false }) offsetLg?: string | number;

  @Prop({ required: false }) offsetMd?: string | number;

  @Prop({ required: false }) offsetSm?: string | number;

  @Prop({ required: false }) alignSelf?: 'start' | 'center' | 'end';

  get colAttrs() {
    return [
      this.cols ? `col-${this.cols}` : '',
      this.xl ? `col-xl-${this.xl}` : '',
      this.lg ? `col-lg-${this.lg}` : '',
      this.md ? `col-md-${this.md}` : '',
      this.sm ? `col-sm-${this.sm}` : '',
    ];
  }

  get alignAttrs() {
    return this.alignSelf ? `align-self-${this.alignSelf}` : '';
  }

  get offsetAttrs() {
    return [
      this.offset ? `offset-${this.offset}` : '',
      this.offsetXl ? `offset-xl-${this.offsetXl}` : '',
      this.offsetLg ? `offset-lg-${this.offsetLg}` : '',
      this.offsetMd ? `offset-md-${this.offsetMd}` : '',
      this.offsetSm ? `offset-sm-${this.offsetSm}` : '',
    ];
  }
}
