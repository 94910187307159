
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FileBadge extends Vue {
  @Prop({ required: false }) type?: string;

  get fileType() {
    if (!this.type) return '';
    if (this.type.length > 3) return this.type.slice(0, 3).toUpperCase();
    return this.type.toUpperCase();
  }
}
