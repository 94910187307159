
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { noop } from 'vue-class-component/lib/util';
import { BTabs, BTab } from 'bootstrap-vue';
import { editRoute } from 'ah-common-lib/src/helpers/route';

/**
 * Tabs component syncronised with URL query string
 * Replaces Bootstrap-vue's BTabs component: https://bootstrap-vue.org/docs/components/tabs#tabs
 *
 * Exposes all props meant for BTabs (via v-bind="$attrs")
 * Exposes all events meant for BTabs (via v-on="$listeners")
 */
@Component
export default class QueryStringTabs extends Vue {
  /**
   * Query string key to set/listen to in URL. Defaults to 'tab'
   */
  @Prop({ default: 'tab' }) protected queryStringProp!: string;

  /**
   * Whether to replace, rather than push to, the browser history on tab change
   */
  @Prop({ default: false }) protected useReplace!: boolean;

  /**
   * Syncable tab to be active
   */
  @Prop({ required: false }) protected tab?: string;

  /**
   * contains all the names of tab's which are walked through, it also contain current tab, as the last element
   */
  protected tabNames: string[] = [];

  /**
   * current tab; which is on display on screen
   */
  protected currentTab: string = '';

  $refs!: {
    tabs: BTabs;
  };

  mounted() {
    // set timeout to make sure correct tab is set after "active" sets current active tab
    setTimeout(() => this.onTabPropChange());
  }

  onTabsChange(tabs: BTab[]) {
    if (tabs) {
      this.tabNames = tabs.map((tab, index) => tab.$attrs.name || tab.$props.title || index.toString());

      if (!this.$route.query[this.queryStringProp]) {
        const activeIndex = tabs.findIndex((t) => (t as any).active);
        this.tabIndex = Math.max(activeIndex, 0);
      }
    } else {
      this.tabNames = [];
    }
  }

  /**
   * return the index of the last tab, so that it can be opened on the screen
   */
  get tabIndex() {
    return Math.max(this.tabNames.indexOf(this.currentTab), 0);
  }

  /**
   * sets the current tab from the tabNames with the index numeber
   */
  public tabValue(value: string) {
    this.currentTab = this.tabNames.find((tab) => tab === value) || this.tabNames[0];
  }

  /**
   * sets the current tab from the tabNames with the index number
   */
  set tabIndex(value: number) {
    this.currentTab = this.tabNames[value];
  }

  /**
   *  On tab activation, update URL (if from event)
   *
   *  Tabs are not activated directly, so as to allow disabling redirection via route guards
   */
  onActivateTab(newTabIndex: number, prevTabIndex?: number, bvEvt?: any) {
    if (this.currentTab !== this.tabNames[newTabIndex]) {
      if (bvEvt) {
        this.setTabRoute(this.tabNames[newTabIndex]);
        bvEvt.preventDefault();
      } else {
        this.tabIndex = newTabIndex;
      }
    }
  }

  /**
   * Watches for the tabs property change and as it is changed,
   * if it is the string change it stores in the current tabs query
   */
  @Watch('$route')
  onTabPropChange() {
    if (typeof this.$route.query[this.queryStringProp] === 'string') {
      this.currentTab = this.$route.query[this.queryStringProp] as string;
    }
  }

  setTabRoute(tabName: string = this.currentTab) {
    if (tabName && tabName !== this.$route.query[this.queryStringProp]) {
      const route = editRoute(this.$route, { query: { add: { [this.queryStringProp]: tabName } } });
      if (this.useReplace || !this.$route.query[this.queryStringProp]) {
        this.$router.replace(route).catch(noop);
      } else {
        this.$router.push(route).catch(noop);
      }
    }
    if (tabName && tabName !== this.tab) {
      this.$emit('update:tab', tabName);
    }
  }

  /**
   * route is updated, as the tab changes, it pushes it into the router for the history.
   */
  @Watch('currentTab', { immediate: true })
  updateRoute() {
    setTimeout(() => this.setTabRoute());
  }

  @Watch('tab', { immediate: true })
  onTabChange() {
    if (this.tab) {
      this.currentTab = this.tab;
    }
  }
}
