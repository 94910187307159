
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoadingIcon from '../../icons/components/LoadingIcon.vue';

@Component({
  components: { LoadingIcon },
})
export default class VButton extends Vue {
  @Prop({ default: '' }) label!: string;

  @Prop({ default: '' }) loadingLabel!: string;

  @Prop({ default: true }) showLoadingSpinner!: boolean;

  @Prop({ default: false }) loading!: boolean;

  @Prop({ default: false }) blurOnClick!: boolean | string;

  @Prop({ default: false }) roundedBorder!: boolean;

  onClick($event: MouseEvent) {
    if (this.blurOnClick !== false && $event && $event.currentTarget && ($event.currentTarget as HTMLElement).blur) {
      ($event.currentTarget as HTMLElement).blur();
    }
  }
}
