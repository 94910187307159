
import { Component, Vue, Provide, Inject, Watch } from 'vue-property-decorator';
import { RequestManager, RequestState } from 'ah-requests';
import {
  RequestManagerConfig,
  WithManagerData,
  REQUEST_MANAGER_REGISTER_KEY,
  REQUEST_MANAGER_DEREGISTER_KEY,
  RequestFilter,
  RequestData,
  requestManagerConfigDefaults,
} from './models';

@Component
export default class WithRequestManager extends Vue {
  protected requestManager = new RequestManager();

  protected childManagers: WithManagerData[] = [];

  private managerData!: WithManagerData;

  protected requestManagerConfig: Partial<RequestManagerConfig> = {};

  @Provide(REQUEST_MANAGER_REGISTER_KEY)
  protected provideRegister = (data: WithManagerData) => {
    const index = this.childManagers.findIndex((m) => m.manager === data.manager);
    if (index === -1) {
      this.childManagers.push(data);
    } else {
      this.childManagers.splice(index, 1, data);
    }
  };

  @Inject({
    from: REQUEST_MANAGER_REGISTER_KEY,
    default: () => () => {},
  })
  protected register!: (data: WithManagerData) => void;

  @Provide(REQUEST_MANAGER_DEREGISTER_KEY)
  protected provideDeregister = (manager: RequestManager) => {
    const index = this.childManagers.findIndex((m) => m.manager === manager);
    if (index > -1) {
      this.childManagers.splice(index, 1);
    }
  };

  @Inject({
    from: REQUEST_MANAGER_DEREGISTER_KEY,
    default: () => () => {},
  })
  protected deregister!: (manager: RequestManager) => void;

  private onRetryRequest(key: string) {
    if (this.requestManagerConfigCalc.onRetryFromParentManager) {
      this.requestManagerConfigCalc.onRetryFromParentManager(key);
    }
  }

  @Watch('requestManagerConfigCalc.exposeToParent', { immediate: true })
  private onExposeToParentChange() {
    const getAllRequests = (filter: RequestFilter, checkChildren = true) => {
      const exposeToParent = this.requestManagerConfigCalc.exposeToParent;
      if (exposeToParent) {
        return this.getAllRequests(filter, checkChildren && exposeToParent === true).filter((r) => {
          if (Array.isArray(exposeToParent)) {
            return exposeToParent.includes(r.request);
          } else if (typeof exposeToParent === 'function') {
            return exposeToParent(r.request);
          }
          return true;
        });
      }
      return [];
    };

    this.managerData = {
      manager: this.requestManager,
      children: this.requestManagerConfigCalc.exposeToParent === true ? this.childManagers : [],
      anyRequestInState: (state: RequestState, checkChildren = true) => {
        const exposeToParent = this.requestManagerConfigCalc.exposeToParent;
        if (exposeToParent === true) {
          return this.anyRequestInState(state, checkChildren);
        } else if (Array.isArray(exposeToParent)) {
          return !!exposeToParent.find((k) => this.requestManager.requestStates[k] === state);
        } else if (typeof exposeToParent === 'function') {
          return !!Object.keys(this.requestManager.requestStates).find(
            (k) => this.requestManager.requestStates[k] === state && exposeToParent(k)
          );
        }
        return false;
      },
      getAllRequests,
      getAllRequestsInState: (states: RequestState | RequestState[], checkChildren = true) => {
        return getAllRequests((key, state) => states.includes(state), checkChildren);
      },
      retryRequest: this.onRetryRequest,
    };
    if (this.requestManagerConfigCalc.exposeToParent) {
      this.register(this.managerData);
    } else {
      this.deregister(this.requestManager);
    }
  }

  private get requestManagerConfigCalc() {
    return { ...requestManagerConfigDefaults, ...this.requestManagerConfig };
  }

  protected beforeDestroy() {
    this.deregister(this.requestManager);
    if (this.requestManagerConfigCalc.clearOnDestroy) {
      this.requestManager.clear();
    }
  }

  private getManagerRequests(filter: (key: string, state: RequestState) => boolean = () => true): RequestData[] {
    const out: RequestData[] = [];
    for (const request in this.requestManager.requestStates) {
      if (filter(request, this.requestManager.requestStates[request])) {
        out.push({ request, state: this.requestManager.requestStates[request], managerData: this.managerData });
      }
    }
    return out;
  }

  // Helper methods

  /**
   * Check for a state in self and any children. If `checkChildren` is true, will drill down the hierarchy
   */
  protected anyRequestInState(state: RequestState, checkChildren = true): boolean {
    return (
      this.requestManager.isAnyInState(state) ||
      (checkChildren && !!this.childManagers.find((m) => m.anyRequestInState(state, checkChildren)))
    );
  }

  /**
   * Get a list of Managers and requests in a particular state
   */
  protected getAllRequestsInState(states: RequestState | RequestState[], checkChildren = true): RequestData[] {
    return this.getAllRequests((key, state) => states.includes(state), checkChildren);
  }

  /**
   * Get a list of Managers and requests, optionally filtered
   */
  protected getAllRequests(filter: RequestFilter, checkChildren = true): RequestData[] {
    const out = this.getManagerRequests(filter);

    if (checkChildren) {
      out.push(...this.childManagers.flatMap((m) => m.getAllRequests(filter, checkChildren)));
    }

    return out;
  }
}
