import { FeeChargeType } from '../feesAndCharges/fundingCharge';
import { BankingScheme } from '../beneficiary';
import { WalletType } from '.';

export enum FeeState {
  PENDING_MAIN_TX_CONFIRMATION = 'PENDING_MAIN_TX_CONFIRMATION',
  PENDING_FEE_TX_CONFIRMATION = 'PENDING_FEE_TX_CONFIRMATION',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
}

export enum FeeDirection {
  AH_TO_CLIENT = 'AH_TO_CLIENT',
  CLIENT_TO_AH = 'CLIENT_TO_AH',
  AH_TO_PARTNER = 'AH_TO_PARTNER',
  PARTNER_TO_AH = 'PARTNER_TO_AH',
}

export const feeDirectionLabels: { [key in FeeDirection]: string } = {
  [FeeDirection.AH_TO_CLIENT]: 'Refund',
  [FeeDirection.CLIENT_TO_AH]: 'Pay',
  [FeeDirection.AH_TO_PARTNER]: 'Refund',
  [FeeDirection.PARTNER_TO_AH]: 'Pay',
};

export enum FeeReason {
  CUSTOM_FEE = 'CUSTOM_FEE',
  DEPOSIT_FEE = 'DEPOSIT_FEE',
  WITHDRAW_FEE = 'WITHDRAW_FEE',
  ROLL_FORWARD_LOSS_FEE = 'ROLL_FORWARD_LOSS_FEE',
  TRADE_CANCEL_LOSS_FEE = 'TRADE_CANCEL_LOSS_FEE',
}

export const feeReasonLabels: { [key in FeeReason]: string } = {
  [FeeReason.CUSTOM_FEE]: 'Custom fee',
  [FeeReason.DEPOSIT_FEE]: 'Funding fee',
  [FeeReason.WITHDRAW_FEE]: 'Payment fee',
  [FeeReason.ROLL_FORWARD_LOSS_FEE]: 'Roll forward fee',
  [FeeReason.TRADE_CANCEL_LOSS_FEE]: 'Cancellation fee',
};

export const feeStateLabels: { [key in FeeState]: string } = {
  [FeeState.PENDING_MAIN_TX_CONFIRMATION]: 'Provisory',
  [FeeState.PENDING_FEE_TX_CONFIRMATION]: 'Pending',
  [FeeState.OUTSTANDING]: 'Outstanding',
  [FeeState.PAID]: 'Paid',
};

export interface FeesInfo {
  debtCurrency: 'GBP';
  debtCumulative: number;
  debtThreshold: number;
  accountState: 'SUSPENDED' | 'ACTIVE';
}

export interface FeeEntry {
  id: string;

  fromPartnerId: string;
  fromPartnerName: string;
  fromClientId: string;
  fromClientName: string;
  fromWalletId: string;

  toPartnerId: string;
  toPartnerName: string;
  toClientId: string;
  toClientName: string;
  toWalletId: string;

  bankingScheme: BankingScheme;
  chargeType: FeeChargeType;
  walletType: WalletType;
  currency: string;
  amount: number;
  reason: FeeReason;
  customFeeReason: string;
  direction: FeeDirection;
  description: string;
  state: FeeState;

  mainTransactionDocId: string;
  feeTransactionDocId: string;
  mainTransactionTrackingId: string;
  feeTransactionTrackingId: string;
  feeTransactionId: string;
  mainTransactionId: string;

  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  settleFirstAttempt: string;
}
