
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { Observable } from 'rxjs';
import { makeFormModel } from '../form/helpers/formMaker';
import { otpField } from '../form/models/commonFields';
import { FormEvent } from '../form/interfaces/form';
import LoadingIconVue from '../icons/components/LoadingIcon.vue';
import { VButton } from '../common/components';
import WithRequestManager from '../requestManager/WithRequestManager.vue';
import { otpMessage } from 'ah-common-lib/src/helpers/otp';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';

const otpFM = () =>
  makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  });

@Component({
  components: {
    BModal,
    VButton,
    LoadingIconVue,
  },
})
export default class OtpRequestForm extends Mixins(WithRequestManager) {
  $refs!: {
    modal: BModal;
  };

  private state: 'unsent' | 'autosending' | 'sent' | 'invalid' | 'expired' = 'unsent';

  @Prop({ default: 'The security of your account is important to us.' }) title!: string;

  @Prop({ default: false }) staticOTP!: boolean;

  @Prop({ default: false }) forbidOtpRequest!: boolean;

  @Prop({ default: '' }) otpPhone!: string;

  @Prop({ default: false }) autoRequest!: boolean | string;

  @Prop({ default: false }) preSent!: boolean | string;

  @Prop({ required: true }) refreshOtpRequest!: () => Observable<any>;

  @Prop({ required: false }) expiry?: ExpiryTime;

  private otpForm = otpFM();

  private minutesToExpire: number | null = null;

  otpMessage = otpMessage;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'refreshOtp') {
      this.refreshOtp();
    }
  }

  created() {
    if (this.preSent !== false) {
      this.state = 'sent';
      this.otpForm.otp = [];
    } else if (this.autoRequest !== false) {
      this.state = 'autosending';
      this.refreshOtp();
    }
  }

  setOtpError(error: 'invalid' | 'expired') {
    this.state = error;
  }

  private cancel() {
    this.$emit('cancel');
  }

  refreshOtp() {
    this.requestManager.currentOrNew('refreshOtp', this.refreshOtpRequest()).subscribe((res) => {
      this.state = 'sent';
      this.otpForm.otp = [];
      this.$emit('success-requested');
      if (res?.expiresIn) {
        this.minutesToExpire = res.expiresIn / 60;
      }
    });
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      this.$emit('update:otp', (this.otpForm.otp ?? []).join(''));
    }
  }

  @Watch('expiry', { immediate: true })
  onExpiryChange() {
    if (this.expiry?.expiresIn) {
      this.minutesToExpire = this.expiry.expiresIn / 60;
    }
  }
}
