import { Component } from 'vue';
import { RouterMode } from 'vue-router';
import { CustomRouteConfig } from './router/routeModels';
import { UserRole } from 'ah-api-gateways';
import Vue from 'vue';

let config: AppConfig | null = null;

export function setupAppConfig(_config: AppConfig) {
  config = _config;
}

export function useConfig(): AppConfig {
  if (!config) {
    throw 'App config used before setup!';
  }
  return config;
}

export interface AppConfig {
  postSetup?: (vue: typeof Vue) => Promise<any>;
  allowedRoles: UserRole[];
  isProduction: boolean;
  showDevTools: boolean;
  routerMode: RouterMode;
  staticOTP: boolean;
  routes: CustomRouteConfig[];
  rootComponent: Component;
  [key: string]: any;
}
