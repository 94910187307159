
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InfoTooltip extends Vue {
  @Prop({ required: false, default: '' }) text!: String;

  get tooltipObject() {
    return {
      html: true,
      customClass: 'info-tooltip',
      placement: 'right',
    };
  }
}
