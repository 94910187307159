
import { Component, Watch } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';

@Component
export default class OtpFormField extends BaseFormField {
  chars: string[] = [];

  @Watch('chars')
  private onCharsChange() {
    this.$emit('set-value', this.chars);
  }

  @Watch('field.$model', { immediate: true })
  private onValueChange() {
    this.chars = Array.isArray(this.field.$model) ? this.field.$model : [];
  }

  private onOtpKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace' && !(event.target as any).value) {
      const next = (this.$refs as any).items[this.constrainIndex(index - 1)].firstElementChild;
      next.focus();
    } else if (event.key === 'Enter') {
      this.emitFormEvent({
        field: this.field,
        model: this.model,
        event: 'form-field-submit',
      });
    }
  }

  private onOtpInput(event: InputEvent, index: number) {
    const strings = (event.target as any).value.split('');
    const nextIndex = this.constrainIndex(strings.length + index);
    const next = (this.$refs as any).items[nextIndex].firstElementChild;
    if (strings.length) {
      strings.forEach((char: string, charIndex: number) => {
        if (charIndex + index < this.OtpParts) {
          const number = parseInt(char, 10);
          this.$set(this.chars, charIndex + index, Number.isNaN(number) ? '' : number.toString());
          if (!Number.isNaN(number)) {
            next.focus();
          }
        }
      });
    } else {
      this.$set(this.chars, index, '');
    }
  }

  private constrainIndex(index: number) {
    return Math.max(Math.min(index, this.OtpParts - 1), 0);
  }

  private get OtpParts() {
    return this.model.$state.length || 6;
  }

  public triggerFieldAction(path: string, action: any) {
    if (this.model.$path === path && action === 'focus') {
      const input = (this.$refs as any).items[0].firstElementChild;
      input.focus();
    }
  }

  private onFocus(event: FocusEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target) {
      target.setSelectionRange(0, target.value.length);
    }
  }

  private onBlur() {
    setTimeout(() => {
      let internal = false;
      (this.$refs as any).items.forEach((item: any) => {
        if (document.activeElement && document.activeElement === item.firstElementChild) {
          internal = true;
        }
      });
      if (!internal) {
        this.field.$touch();
      }
    });
  }
}
