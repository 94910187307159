import {
  BankingScheme,
  BeneficiaryType,
  CountryInfo,
  EntitySector,
  EntityType,
  ISOCodeInfo,
  PaginatedQuery,
  PaginatedResponse,
  LocalizedFieldData,
  KycCompanyDetails,
  CountryJurisdiction,
  standardiseQuerySort,
  LegalFileType,
  ExportFileType,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService, deterministicJSON, downloadFile } from 'ah-requests';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CustomerReferenceService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'customerReferenceService' } },
      },
    });
  }

  public listCountries(
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<CountryInfo>>>
  ): Observable<PaginatedResponse<CountryInfo>> {
    if (query) {
      query = standardiseQuerySort(query);
    }
    return this.get<PaginatedResponse<CountryInfo>>(`${this.baseUrl}countries`, {
      options,
      axiosConfig: {
        params: { ...query },
      },
    });
  }

  public listEntityTypes(
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<EntityType>>>
  ): Observable<PaginatedResponse<EntityType>> {
    return this.get<PaginatedResponse<EntityType>>(`${this.baseUrl}entity-types`, {
      options,
      axiosConfig: {
        params: { ...query },
      },
    });
  }

  public listEntitySectors(
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<EntitySector>>>
  ): Observable<PaginatedResponse<EntitySector>> {
    return this.get<PaginatedResponse<EntitySector>>(`${this.baseUrl}sectors`, {
      options,
      axiosConfig: {
        params: { ...query },
      },
    });
  }

  public listAddressFields(params: {
    currency: string;
    bankingScheme?: BankingScheme;
    beneficiaryType: BeneficiaryType;
  }): Observable<LocalizedFieldData[]> {
    return this.get<LocalizedFieldData[]>(`${this.baseUrl}address-fields`, {
      axiosConfig: {
        params,
      },
      options: {
        cache: {
          type: 'use',
          cacheKey: 'addressesfields',
          itemKey: deterministicJSON(params),
        },
      },
    });
  }

  public enableCountry(country: CountryInfo) {
    return this.put<any>(`${this.baseUrl}countries/${country.cc}`, { ...country, enabled: true });
  }

  public disableCountry(country: CountryInfo) {
    return this.put<any>(`${this.baseUrl}countries/${country.cc}`, { ...country, enabled: false });
  }

  public listISOCodes(
    params?: {
      countryCode?: string;
      subdivisionName?: string;
      code?: string;
      sort?: string;
    },
    options?: Partial<HttpOptions<ISOCodeInfo[]>>
  ): Observable<ISOCodeInfo[]> {
    return this.get<ISOCodeInfo[]>(`${this.baseUrl}iso-codes/3166/2`, {
      options,
      axiosConfig: {
        params,
      },
    });
  }

  public listCountriesJurisdiction(
    query?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<CountryJurisdiction>>>
  ): Observable<PaginatedResponse<CountryJurisdiction>> {
    return this.get<PaginatedResponse<CountryJurisdiction>>(`${this.baseUrl}jurisdiction`, {
      options,
      axiosConfig: {
        params: { ...query },
      },
    });
  }

  public kycCountryInformation(
    params: {
      countryCode: string;
      query: string;
    },
    options?: Partial<HttpOptions<KycCompanyDetails[]>>
  ): Observable<KycCompanyDetails[]> {
    return this.get<KycCompanyDetails[]>(`${this.baseUrl}companies/search`, {
      options: {
        cache: {
          type: 'use',
          cacheKey: 'kycsearch',
          itemKey: `kyc-${params.query}`,
        },
        ...options,
      },
      axiosConfig: {
        params,
      },
    });
  }

  public downloadLegalDocument(documentType: LegalFileType, exportType: ExportFileType) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: `${this.baseUrl}documents/${documentType}/content/${exportType}/download`,
      },
    }).pipe(map(downloadFile));
  }

  public getLegalDocument(documentType: LegalFileType, exportType: ExportFileType) {
    return this.get<{ html: string }>(`${this.baseUrl}documents/${documentType}/content/${exportType}`);
  }
}
