
import { Component, Vue, Prop } from 'vue-property-decorator';
import capitalize from 'lodash/capitalize';

@Component
export default class StatusBadge extends Vue {
  @Prop({ default: '' }) status!: string;

  @Prop({ default: '' }) prefix!: string;

  get statusLowercase() {
    return this.status.toLowerCase();
  }

  get statusCapitalised() {
    return capitalize(this.status.replace(/_/g, ' '));
  }

  get label() {
    return `${this.prefix}${this.prefix ? ' ' : ''}${this.statusCapitalised}`;
  }
}
