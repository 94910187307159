
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Observable } from 'rxjs';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component
export default class EntityNameCell extends Mixins(WithRequestManager) {
  @Prop({ required: true }) id!: string;

  @Prop({ default: 'name' }) entityKey!: string;

  @Prop({ required: false }) entityKeySecond!: string;

  @Prop({ default: '' }) name!: string;

  @Prop({ default: null }) email!: string;

  @Prop({ default: '-' }) notFoundValue!: string;

  @Prop({ default: () => () => null }) getRequest!: (id: string) => Observable<any>;

  private entity: any | null = null;

  get title() {
    if (this.entityKeySecond) {
      return this.name || (this.entity && this.entity[this.entityKey])
        ? this.entity[this.entityKey] + ' ' + this.entity[this.entityKeySecond]
        : '';
    }
    return this.name || (this.entity && this.entity[this.entityKey]) || '';
  }

  @Watch('id', { immediate: true })
  onIdChange() {
    if (this.id && !this.name) {
      this.entity = null;
      this.requestManager
        .sameOrCancelAndNew(`fetch-${this.entityKey}`, this.getRequest(this.id), this.id)
        .subscribe((response) => (this.entity = response));
    } else {
      this.entity = null;
      this.entity = { id: this.id, firstName: this.name, email: this.email };
    }
  }
}
