
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Trade, Quote, AmountType, getCcyByAmountType } from 'ah-api-gateways';
import { formatCurrencyValue } from '../../../helpers/currency';

@Component
export default class TradeConversionCell extends Vue {
  @Prop({ required: true }) quoteOrTrade!: Quote | Trade;

  @Prop({ default: 'trade' }) type!: 'trade' | 'quote';

  formatCurrencyValue = formatCurrencyValue;

  get sellCurrency() {
    if (this.type === 'quote') {
      const quote = this.quoteOrTrade as Quote;
      return { currency: quote.sellCurrency, clientAmount: quote.amount };
    }

    const trade = this.quoteOrTrade as Trade;
    if (!trade.ccy1) return null;

    return getCcyByAmountType(trade, AmountType.SELL);
  }

  get buyCurrency() {
    if (this.type === 'quote') {
      const quote = this.quoteOrTrade as Quote;
      return { currency: quote.buyCurrency, clientAmount: '' };
    }

    const trade = this.quoteOrTrade as Trade;
    if (!trade.ccy1) return null;

    return getCcyByAmountType(trade, AmountType.BUY);
  }
}
