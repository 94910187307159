import { render, staticRenderFns } from "./BeneficiaryListing.vue?vue&type=template&id=80a42f24&scoped=true&"
import script from "./BeneficiaryListing.vue?vue&type=script&lang=ts&setup=true&"
export * from "./BeneficiaryListing.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./BeneficiaryListing.vue?vue&type=style&index=0&id=80a42f24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80a42f24",
  null
  
)

export default component.exports