
import { Component } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import checkboxFormField from './CheckboxFormField.vue';
import choiceTreeFormField from './ChoiceTreeFormField.vue';
import currencyExchangeFormField from './CurrencyExchangeFormField.vue';
import dateFormField from './DateFormField.vue';
import filesFormField from './FileFormField.vue';
import otpFormField from './OtpFormField.vue';
import phoneFormField from './PhoneFormField.vue';
import radioFormField from './RadioFormField.vue';
import selectFormField from './SelectFormField.vue';
import sliderFormField from './SliderFormField.vue';
import tagsFormField from './TagsFormField.vue';
import textAreaFormField from './TextAreaFormField.vue';
import textFormField from './TextFormField.vue';
import basisPointsFormField from './BasisPointsFormField.vue';
import financialAmountFormField from './FinancialAmountFormField.vue';
import passwordFormField from './PasswordFormField.vue';
import checkboxMultipleFormField from './CheckboxMultipleFormField.vue';
import ibanFormField from './IBANFormField.vue';
import numberFormField from './NumberFormField.vue';
import BaseFormField from './BaseFormField.vue';
import FormFieldErrors from './FormFieldErrors.vue';
import { isEqual } from 'lodash';

@Component({
  components: {
    checkboxFormField,
    choiceTreeFormField,
    currencyExchangeFormField,
    financialAmountFormField,
    dateFormField,
    filesFormField,
    otpFormField,
    passwordFormField,
    phoneFormField,
    radioFormField,
    selectFormField,
    sliderFormField,
    tagsFormField,
    textAreaFormField,
    textFormField,
    basisPointsFormField,
    checkboxMultipleFormField,
    ibanFormField,
    numberFormField,
    FormFieldErrors,
  },
})
export default class FormField extends BaseFormField {
  setValue(value: any, keepPristine = false) {
    this.field.$model = value;
    if (keepPristine) {
      this.field.$reset();
    }
    this.emitFormEvent({
      event: 'form-field-set-value',
      model: this.model,
      field: this.field,
    });
  }

  public triggerFieldAction(path: string, action: any) {
    const item = (this.$refs.item || {}) as any;
    if (item.triggerFieldAction) {
      item.triggerFieldAction(path, action);
    }
  }

  get hideErrorMessages() {
    return getState(this.model, 'hideErrorMessages', false, true);
  }

  get clearable() {
    return getState(this.model, 'clearable', false);
  }

  get clearValue() {
    return getState(this.model, 'clearValue', null);
  }

  get titleClass() {
    return getState(this.model, 'titleClass', null);
  }

  get showClear() {
    return (
      this.clearable &&
      this.field.$model !== undefined &&
      this.field.$model !== null &&
      !isEqual(this.field.$model, this.clearValue)
    );
  }

  clearField() {
    this.setValue(this.clearValue, true);
  }
}
