import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import type { UpdatableObject } from '../../models';

export enum ClientCategorization {
  RETAIL_CLIENT = 'RETAIL_CLIENT',
  PROFESSIONAL_CLIENT_PER_SE = 'PROFESSIONAL_CLIENT_PER_SE',
  PROFESSIONAL_CLIENT_ELECTIVE = 'PROFESSIONAL_CLIENT_ELECTIVE',
  ELIGIBLE_COUNTERPARTY = 'ELIGIBLE_COUNTERPARTY',
}

export enum AMLRiskType {
  PAYMENT_STRUCTURE = 'PAYMENT_STRUCTURE',
  SIZE_OF_CORPORATE = 'SIZE_OF_CORPORATE',
  LENGTH_OF_ESTABLISHMENT = 'LENGTH_OF_ESTABLISHMENT',
  LEGAL_FORM = 'LEGAL_FORM',
  SECTOR = 'SECTOR',
  SANCTION = 'SANCTION',
  PEP = 'PEP',
  COUNTRY = 'COUNTRY',
}

export enum AMLRiskContributionType {
  GLOBAL_SUM = 'GLOBAL_SUM',
  LOCATION_AVERAGE = 'LOCATION_AVERAGE',
}

export interface AMLScorecardPreviewRequestPayload {
  id: string;
  scorecardValues: Omit<AMLScorecardFieldValue, 'score'>[];
}

export interface AMLScorecardUpdateRequestPayload {
  id: string;
  scorecardValues: Omit<AMLScorecardFieldValue, 'score'>[];
}

export interface AMLScorecardFieldValue {
  type: AMLRiskType;
  score: number;
  value: string | null;
}

export interface AMLScorecardModelField extends AMLScorecardFieldValue {
  field: string;
  label: string;
  riskContributionType: AMLRiskContributionType;
}

export interface AMLScoreCardValues {
  calculatedOverallRiskScore: number;
  calculatedAverageRiskWeight: number;
  scorecardValues: AMLScorecardModelField[];
}

export interface AMLScoreCardData {
  appropriatenessSuitabilityCompleted: boolean;
  onboardingDocumentsGathered: boolean;
  clientCategorization: ClientCategorization;
}

export type AMLScoreCardRequestPayload = AMLScoreCardData & AMLScoreCardValues & { id: string };

export type AMLScoreCard = AMLScoreCardData & AMLScoreCardValues & UpdatableObject;

export const clientCategorizationOptions: FieldOptionObj[] = [
  { label: 'Retail Client', value: ClientCategorization.RETAIL_CLIENT },
  { label: 'Professional Client - Per Se', value: ClientCategorization.PROFESSIONAL_CLIENT_PER_SE },
  { label: 'Professional Client - Elective', value: ClientCategorization.PROFESSIONAL_CLIENT_ELECTIVE },
  { label: 'Eligible Counterparty (ECP)', value: ClientCategorization.ELIGIBLE_COUNTERPARTY },
];
