
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QuoteState, quoteStateBadges } from 'ah-api-gateways';

@Component
export default class QuoteStateBadge extends Vue {
  @Prop({ default: '' }) state!: QuoteState;

  get stateBadge() {
    return quoteStateBadges[this.state] || {};
  }
}
