
import { Component, Watch } from 'vue-property-decorator';
// importing directly to avoid non-extracted css issues from built code
import VueSlider from 'vue-slider-component';
import { getState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';

@Component({
  components: {
    VueSlider,
  },
})
export default class SliderFormField extends BaseFormField {
  inputValue = '';

  beforeMount() {
    this.inputValue = this.valueToInputFn(this.field.$model);
  }

  setValue(value: any) {
    this.$emit('set-value', +value);
  }

  setInputValue(event: any) {
    if (!event.target) return;

    let value!: number;

    try {
      value = this.inputToValueFn(event.target.value);
    } catch (e) {
      return;
    }

    if (this.dataPoints) {
      if (this.dataPoints.includes(+value)) {
        this.setValue(+value);
      } else {
        const dataSorted = (this.dataPoints as number[]).sort();
        for (let i = 0; i < dataSorted.length; i += 1) {
          if (dataSorted[i] > +value || i === dataSorted.length - 1) {
            this.setValue(dataSorted[i]);
            return;
          }
        }
      }
    } else {
      const constrainedValue = Math.max(this.min, Math.min(this.max, value));

      if (this.withMathFloor) {
        this.setValue(Math.floor((constrainedValue - this.min) / this.interval) * this.interval + this.min);
      } else {
        this.setValue(((constrainedValue - this.min) / this.interval) * this.interval + this.min);
      }
    }
  }

  @Watch('field.$model', { immediate: true })
  onModelChange() {
    if ((this.$refs.input as any) !== document.activeElement) {
      this.inputValue = this.valueToInputFn(this.field.$model);
    }
  }

  get cappedValue() {
    if (this.dataPoints) {
      return this.field.$model;
    }
    return Math.max(this.min, Math.min(this.max, this.field.$model));
  }

  get valueToInputFn() {
    return getState(this.model, 'valueToInputFn', (val: number) => val.toString());
  }

  get inputToValueFn() {
    return getState(this.model, 'inputToValueFn', (val: string) => parseFloat(val));
  }

  get max() {
    const max = getState(this.model, 'max', 100);
    return getState(this.model, 'maxValue', max);
  }

  get min() {
    const min = getState(this.model, 'min', 0);
    return getState(this.model, 'minValue', min);
  }

  get withMathFloor() {
    return getState(this.model, 'withMathFloor', true);
  }

  get interval() {
    return getState(this.model, 'interval', 1);
  }

  get dataPoints() {
    return getState(this.model, 'data', undefined);
  }

  onBlur() {
    this.inputValue = this.valueToInputFn(this.field.$model);
  }
}
