
import { Component, Vue, Prop } from 'vue-property-decorator';
import DynamicForm from './Form.vue';
import VButton from '../../common/components/VButton.vue';
import ManagedFlow from '../../common/components/ManagedFlow.vue';
import { submitForm, toDataModel } from '../helpers';
import { BaseFormModel, FormValidation } from '../interfaces';

@Component({
  components: {
    VButton,
    DynamicForm,
    ManagedFlow,
  },
})
export default class EditableInfoBox extends Vue {
  @Prop({ default: '' }) title!: string;

  @Prop({ default: '' }) editingTitle!: string;

  @Prop({ default: false }) editable!: boolean | string;

  @Prop({ default: null }) form!:
    | (FormValidation<BaseFormModel> & { [key: string]: FormValidation<BaseFormModel> })
    | null;

  private savingForm = false;

  private isFormOpen = false;

  private closeFormMethod = () => this.closeForm();

  get isEditable() {
    return (this.editable === '' || this.editable) && this.form;
  }

  get editViewTitle() {
    return this.editingTitle || `Edit ${this.title}`;
  }

  openForm() {
    this.isFormOpen = true;
    // do nothing
  }

  saveForm() {
    submitForm(this.form!);
    if (this.form!.$invalid) {
      return;
    }

    this.$emit('submit', toDataModel(this.form!.$model), (result: any, error: any) => {
      // TODO handle error in a generic way, via a known interface?
      if (!error) {
        this.isFormOpen = false;
        this.$toast.success(`Saved ${this.title} successfully`);
      }
      this.savingForm = false;
    });
  }

  closeForm() {
    this.isFormOpen = false;
  }
}
