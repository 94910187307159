
import { Component, Prop } from 'vue-property-decorator';
import FormField from './fields/FormField.vue';
import FormSection from './FormSection.vue';
import CallTextBalloon from '../../common/components/CallTextBalloon.vue';
import { getState } from '../helpers/formHelpers';
import { FormModel, isFormType } from '../interfaces/form';
import BaseFormElement from './BaseFormElement.vue';

@Component({
  components: {
    FormField,
    FormSection,
    CallTextBalloon,
  },
})
export default class FormElement extends BaseFormElement {
  @Prop({ required: true }) declare model: FormModel;

  public triggerFieldAction(path: string, action: any) {
    const item = (this.$refs.item || {}) as any;
    if (item.triggerFieldAction) {
      item.triggerFieldAction(path, action);
    }
  }

  get isForm(): boolean {
    return isFormType(getState(this.model, 'fieldType'));
  }

  get display(): string {
    return getState(this.model, 'display');
  }

  get formWrapperClass() {
    return getState(this.model, 'formWrapperClass', this.$formOptions.formWrapperClass || '', true);
  }

  get fieldWrapperClass() {
    return getState(this.model, 'fieldWrapperClass', this.$formOptions.fieldWrapperClass || '', true);
  }

  get callText(): string {
    return getState(this.model, 'callText');
  }

  get showCallText(): string {
    return getState(this.model, 'showCallText', true, true);
  }

  get hidden(): string {
    const hidden = getState(this.model, 'hidden', false);
    return typeof hidden === 'function' ? hidden(this.model) : hidden;
  }
}
