
import { Component, Vue, Prop } from 'vue-property-decorator';
import PhoneIcon from '../../icons/components/PhoneIcon.vue';

@Component({
  components: {
    PhoneIcon,
  },
})
export default class CallTextBalloon extends Vue {
  @Prop({ required: true }) callText!: string;

  @Prop({ default: 'Read to client' }) title!: string;
}
