
import { formatDistanceToNowStrict, formatDistanceToNow } from 'date-fns';
import { formatDate, FULL_DATE_AND_TIME_FORMAT } from '../../../helpers/time';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TimeAgo extends Vue {
  @Prop({ required: true }) date!: Date;

  @Prop({ default: 'long' }) dateStyle!: 'long' | 'strict' | 'short';

  updateTimeout: number | null = null;

  timeAgo = '';

  formatDate = formatDate;

  FULL_DATE_AND_TIME_FORMAT = FULL_DATE_AND_TIME_FORMAT;

  mounted() {
    this.updateTimeAgoText();
  }

  beforeDestroy() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }

  updateTimeAgoText() {
    this.timeAgo =
      this.dateStyle === 'long'
        ? formatDistanceToNow(this.date)
        : formatDistanceToNowStrict(this.date, {
            roundingMethod: 'floor',
          });
    if (this.dateStyle === 'short') {
      this.timeAgo = this.timeAgo.replace(/ second[s]?/, 's');
      this.timeAgo = this.timeAgo.replace(/ minute[s]?/, 'm');
      this.timeAgo = this.timeAgo.replace(/ hour[s]?/, 'h');
      this.timeAgo = this.timeAgo.replace(/ day[s]?/, 'd');
    }
    this.updateTimeout = window.setTimeout(() => this.updateTimeAgoText(), 1000);
  }
}
