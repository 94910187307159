
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component
export default class AlertModal extends Vue {
  $refs!: {
    alertModal: BModal;
  };

  @Prop({ default: '' }) modalTitle!: string;

  @Prop({ default: 'Confirm?' }) modalText!: string;

  showModal() {
    this.$refs.alertModal.show();
  }
}
