
import { Component } from 'vue-property-decorator';
import { getState } from '../../helpers/formHelpers';
import { generateUID } from '../../../helpers/uuid';
import BaseFormField from './BaseFormField.vue';

@Component
export default class CheckboxFormField extends BaseFormField {
  private uid = generateUID(6);

  onChange($event: Event) {
    let value: any = !!($event.target as any).checked;
    if (this.dated) {
      value = {
        ...(this.field.$model || {}),
        [this.valuePropName]: value,
        date: new Date().toISOString(),
      };
    }
    this.$emit('set-value', value);
  }

  get value() {
    if (this.field.$model && {}.hasOwnProperty.call(this.field.$model, this.valuePropName)) {
      return this.field.$model[this.valuePropName];
    }
    return this.field.$model;
  }

  get isSwitch() {
    return this.checkBoxStyle === 'switch';
  }

  get checkBoxStyle() {
    return getState(this.model, 'checkBoxStyle', 'checkbox');
  }

  get valuePropName() {
    return getState(this.model, 'valuePropName', 'value');
  }

  get dated() {
    return getState(this.model, 'dated');
  }

  get form() {
    return getState(this.model, 'label');
  }

  get label() {
    return getState(this.model, 'label');
  }
}
