const baseUrl = 'https://demo.api.assurehedge.com/v3.2/';

export default {
  staticOTP: true,
  useAzureAD: true,
  allowMockedFunding: true,
  altReferer: 'https://demo.x-hedge.alt21.com/',

  customerAPIGatewayUrl: `${baseUrl}customer-api/`,
  bankingAPIGatewayUrl: `${baseUrl}banking-api/`,
  fxAPIGatewayUrl: `${baseUrl}fx-api/`,
  analyticsUrl: `${baseUrl}analytics-api/`,
  customerAPIGatewayAdminUrl: `${baseUrl}customer-admin-api/`,
  bankingAPIGatewayAdminUrl: `${baseUrl}banking-admin-api/`,
  fxAPIGatewayAdminUrl: `${baseUrl}fx-admin-api/`,
  analyticsAdminUrl: `${baseUrl}analytics-admin-api/`,
  settlementsAdminUrl: `${baseUrl}settlements-admin-api/`,

  hedgucationSsoClientId: 'fde68071-9d16-42ce-82b3-8dac1b671afc',
  hedgucationRedirectUri: 'https://hedgucation.assurehedge.com/authorizessologin-6',

  // To be deprecated
  baseUrl,
};
