
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import {
  makeFormModel,
  makeFormValidation,
  getChildModel,
  batchSetState,
  submitForm,
} from 'ah-common-lib/src/form/helpers';
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { Subject, empty, Subscription, of } from 'rxjs';
import { debounceTime, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { financialAmountField } from 'ah-common-lib/src/form/models/commonFields';
import { requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import {
  AuthorityType,
  HedgingInstruments,
  PricingEngineErrorCodes,
  LimitsErrorCodes,
  formatHedgingInstrument,
  FeatureFlag,
  FeatureFlagTreatment,
} from 'ah-api-gateways';
import isEqual from 'lodash/isEqual';
import { TimeFrameDate, DrawdownDate } from 'ah-trades/src/models/date';
import { TradeDetails } from 'ah-trades/src/models/trade';
import { Beneficiary, Wallet, AmountType } from 'ah-api-gateways';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import FieldWalletInfoOrErrors from 'ah-wallets/src/components/FieldWalletInfoOrErrors.vue';
import { QuotePriceRequest, pricingRequest } from 'ah-trades/src/requests/pricingRequest';
import TradePriceExchangeRate from '../info/TradePriceExchangeRate.vue';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import ClientRateAndMarginForm from './ClientRateAndMarginForm.vue';
import { ApiError } from 'ah-requests';
import InjectObo from 'ah-common-lib/src/onBehalfOf/InjectObo.vue';
import { getState } from 'ah-common-lib/src/form/helpers';
import WithRequestManager from '../../../../ah-common-lib/src/requestManager/WithRequestManager.vue';
import useVuelidate, { Validation } from '@vuelidate/core';
import CurrencySelectInput from 'ah-trades/src/components/currency/CurrencySelectInput.vue';
import { pricingEngineError, PricingEngineErrorOptions } from './pricingEngineChecks';

// List of Pricing Engine Errors that should show the Partner's contacts
const pricingEngineErrorsNeedingSupport = [
  PricingEngineErrorCodes.AMOUNT_OUT_OF_BOUNDS,
  PricingEngineErrorCodes.OPTION_PREMIUM_LESS_THAN_THRESHOLD,
  PricingEngineErrorCodes.OPTION_PREMIUM_GREATER_THAN_THRESHOLD,
  PricingEngineErrorCodes.UNAVAILABLE_CURRENCY_PAIR,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
  PricingEngineErrorCodes.BASIS_POINTS_BELOW_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.BASIS_POINTS_ABOVE_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
];

const amountFM = () =>
  makeFormModel({
    name: 'amountForm',
    title: '',
    fieldType: 'form',
    fields: [
      financialAmountField('buyAmount', '', {
        hideErrorMessages: true,
        errorMessages: { required: '' },
      }),
      financialAmountField(
        'sellAmount',
        '',
        { hideErrorMessages: true, errorMessages: { required: '' } },
        {
          // walletMaximum: optional(smallerThanOrEqualsParam('sellAmount', 'walletBalance')),
          required: requiredIfStateValue('sellAmount'),
        }
      ),
    ],
  });
@Component({
  components: {
    CurrencySelectInput,
    FieldWalletInfoOrErrors,
    TradePriceExchangeRate,
    ClientRateAndMarginForm,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    amountForm: makeFormValidation(amountFM()),
  },
})
export default class TradeDetailsForm extends Mixins(InjectObo, WithRequestManager) {
  $refs!: {
    clientRateAndMarginForm: any; // InstanceType<typeof ClientRateAndMarginForm>;
  };

  @Prop({ required: true }) timeFrame!: TimeFrameDate;

  /**
   * Trade details object
   *
   * synchronizable via `update:tradeDetails` or .sync modifier
   */
  @Prop({ default: () => null }) tradeDetails!: TradeDetails | null;

  /**
   * Trade price request and response (as received by getting/refreshing  the trade)
   *
   * synchronizable via `update:tradePrice` or .sync modifier
   */
  @Prop({ default: () => null }) tradePrice!: QuotePriceRequest | null;

  /**
   * DrawdownDate object
   */
  @Prop({ default: () => null }) drawdownDate!: DrawdownDate | null;

  /**
   * Possible beneficiary target of the trade
   * Will affect UX and options available in child components
   */
  @Prop({ required: false }) beneficiary?: Beneficiary;

  /**
   * Possible array of allowed buy currencies
   *
   * If set, user will only be able to choose from the currencies listed as a buy currency
   */
  @Prop({ required: false }) allowedBuyCurrencies?: string[];

  /**
   * Possible array of allowed sell currencies
   *
   * If set, user will only be able to choose from the currencies listed as a sell currency
   */
  @Prop({ required: false }) allowedSellCurrencies?: string[];
  /**
   * Whether to verify limits
   *
   * If true:
   * - client will verify limits
   */
  @Prop({ default: false }) verifyLimits?: string | boolean;

  private v$!: Validation;

  requestManagerConfig = {
    exposeToParent: true,
  };

  private amountForm = amountFM();

  private buyCurrency: string = 'EUR';

  private sellCurrency: string = 'GBP';

  private buyWallet: Wallet | null = null;

  private sellWallet: Wallet | null = null;

  private readonly AmountType = AmountType;

  private rateDirection: AmountType = AmountType.SELL;

  private tradeDetailsSubject = new Subject<TradeDetails>();

  private tradeDetailsSubscription?: Subscription;

  private peError: ApiError | null = null;

  private errorMessage: string | null = null;

  private canVerifyLimits: boolean | null = null;

  /**
   * Public methods
   */

  public refreshData() {
    this.loadTradePrice(this.editedTradeDetails).subscribe();
  }

  public touchForms() {
    if (this.v$.amountForm) {
      submitForm(this.v$.amountForm);
    }
    if (this.onBehalfOfClient) {
      this.$refs.clientRateAndMarginForm.touch();
    }
  }

  private get isVerifyingLimits() {
    return this.verifyLimits !== false;
  }

  private get canSeeClientRate() {
    return this.$ahTradesState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_SPREADS);
  }

  created() {
    this.$ahTradesState.store.useSettingsStore().loadTradeableCurrencies();
    this.tradeDetailsSubscription = this.tradeDetailsSubject
      .pipe(
        tap(() => this.requestManager.cancel('getPrices')),
        debounceTime(700),
        filter(() => this.walletsLoaded && this.amount && this.isTrade && !!this.timeFrame),
        switchMap((tradeDetails) => this.loadTradePrice(tradeDetails))
      )
      .subscribe();

    if (this.isVerifyingLimits) {
      const payload = {
        featureFlag: FeatureFlag.ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE,
        oboClientId: this.onBehalfOfClient?.id,
        force: true,
      };

      this.$ahTradesState.store
        .useFeatureFlagStore()
        .loadFeatureFlag(payload)
        .then(
          (featureFlagResponse) => (this.canVerifyLimits = featureFlagResponse?.treatment === FeatureFlagTreatment.ON)
        );
    }
  }

  @Watch('buyCurrencies', { immediate: true })
  private onBuyCurrenciesChange() {
    if (this.buyCurrencies?.length) {
      const buyCurrency = this.buyCurrency;
      if (!this.buyCurrencies.includes(buyCurrency)) {
        this.buyCurrency = this.buyCurrencies[0];
        if (this.sellCurrency === this.buyCurrency) {
          this.sellCurrency = buyCurrency;
        }
      }
    }
  }

  @Watch('sellCurrencies', { immediate: true })
  private onSellCurrenciesChange() {
    if (this.sellCurrencies?.length) {
      const sellCurrency = this.sellCurrency;
      if (!this.sellCurrencies.includes(sellCurrency)) {
        this.sellCurrency = this.sellCurrencies[0];
        if (this.sellCurrency === this.buyCurrency) {
          this.buyCurrency = sellCurrency;
        }
      }
    }
  }

  cleanFields() {
    this.errorMessage = null;
    this.peError = null;
    batchSetState(this.amountForm, 'errors', [this.calculatedAmountField, this.inputAmountField], []);
    batchSetState(this.amountForm, 'unexpectedError', {
      [this.calculatedAmountField]: false,
      [this.inputAmountField]: false,
    });
    batchSetState(this.amountForm, 'placeholder', {
      [this.calculatedAmountField]: '',
      [this.inputAmountField]: '',
    });
  }

  beforeDestroy() {
    this.tradeDetailsSubscription!.unsubscribe();
  }

  @Watch('requestManager.requestStates.getPrices')
  private onGetPricesChange() {
    this.$emit('update:loadingPrices', this.requestManager.requestStates.getPrices === 'pending');
  }

  /**
   * loadTradePrice loads prices for tradeDetails AND handles responses in a tap() pipe
   *
   * This is so it can be called both immediately (on refresh) and in a debounced fashion (on input)
   */
  private loadTradePrice(tradeDetails: TradeDetails) {
    if (!tradeDetails.timeFrame || (tradeDetails.timeFrame.isForward && !tradeDetails.drawdownDate?.valid)) {
      return of({});
    }
    this.cleanFields();

    return this.requestManager.cancelAndNew(
      'getPrices',
      pricingRequest(
        tradeDetails,
        this.onBehalfOfClient?.id ?? this.$ahTradesState.store.useAuthStore().loggedInIdentity!.client!.id,
        this.$ahTradesState.services.pricingEngine,
        {
          options: {
            errors: {
              silent: true,
            },
          },
        }
      ).pipe(
        tap((price) => {
          // we update the amount for the currency we did not set in our price request
          const amount =
            price.type !== price.response?.ccy1.amountType
              ? price.response?.ccy1.clientAmount
              : price.response?.ccy2.clientAmount;
          this.amountForm[this.rateDirection === AmountType.SELL ? 'buyAmount' : 'sellAmount'] = amount;
          this.$emit('update:tradePrice', price);

          if (this.canVerifyLimits) {
            this.loadLimits(price, tradeDetails);
          }
        }),
        catchError((e) => {
          if (e.response.status === 500) {
            batchSetState(this.amountForm, 'unexpectedError', {
              [this.calculatedAmountField]: true,
              [this.inputAmountField]: false,
            });
            this.$ahTradesState.toast.error('An error occured while calculating trade price. Please try again later.');
          }
          this.checkPricingEngineValidation(e.response.data);
          this.$emit('update:tradePrice', null);
          return empty();
        })
      )
    );
  }

  private loadLimits(price: QuotePriceRequest, tradeDetails: TradeDetails) {
    const buyCcy = price.response?.ccy1.amountType === AmountType.BUY ? price.response?.ccy1 : price.response?.ccy2;

    const sellCcy = price.response?.ccy1.amountType === AmountType.SELL ? price.response?.ccy1 : price.response?.ccy2;

    const limitsPriceResponse = {
      amountType: tradeDetails.amountType,
      buyCurrency: buyCcy?.currency,
      sellCurrency: sellCcy?.currency,
      hedgingProduct: price.instrument,
      sellAmount: sellCcy?.clientAmount,
      buyAmount: buyCcy?.clientAmount,
    };

    this.requestManager
      .cancelAndNew(
        'getLimits',

        this.$ahTradesState.services.limits.verifyLimits(
          this.onBehalfOfClient?.id ?? this.$ahTradesState.store.useAuthStore().loggedInIdentity!.client!.id,
          limitsPriceResponse,
          { errors: { silent: true } }
        )
      )
      .subscribe(
        (limits) => {
          this.$emit('update:walletBalanceUsageRequired', limits.walletBalanceUsageRequired);
        },
        (e) => {
          if (e.response?.status === 422 && e.response?.data.code === LimitsErrorCodes.INVALID_AMOUNT) {
            this.setErrorInField('limitsError');
            if (price.instrument == HedgingInstruments.FX_SPOT) {
              this.errorMessage = `We regret to inform you that the trade cannot be executed at this time due to the following potential reasons:<ul><br/><li>Your wallet balance is insufficient to proceed with the trade.</li><br /><li>The notional amount surpasses the client limit for ${formatHedgingInstrument(
                price.instrument
              )} trades.</li></ul>For further assistance, please contact us at <a target="_blank" href="mailto:sales@alt21.com">sales@alt21.com</a>.`;
            } else {
              this.errorMessage = `The notional amount exceeds the client limit for ${formatHedgingInstrument(
                price.instrument
              )} set in the limits profile. Please contact Admin or increase the limit for  ${formatHedgingInstrument(
                price.instrument
              )} in the limits profile. ${this.tradingDeskContactHTMLMessage}`;
            }

            return;
          }

          if (e.response?.status === 400 || e.response?.status === 422) {
            this.$ahTradesState.toast.info(e.response.data.message);
          } else {
            this.$ahTradesState.toast.error('An unexpected problem has occurred. Please try again later.');
          }
          batchSetState(this.amountForm, 'unexpectedError', {
            [this.calculatedAmountField]: true,
            [this.inputAmountField]: false,
          });
        }
      );
  }

  @Watch('tradeDetails', { immediate: true })
  private onTradeDetailsChange() {
    if (this.tradeDetails) {
      this.sellCurrency = this.tradeDetails.sellCurrency;
      this.buyCurrency = this.tradeDetails.buyCurrency;
      this.rateDirection = this.tradeDetails.amountType || AmountType.SELL;
      this.amountForm[this.inputAmountField] = this.tradeDetails.amount;
      this.updateFieldPlaceholders(this.tradeDetails.valid);
    }
  }

  @Watch('workingTradeDetails', { deep: true, immediate: true })
  private onWorkingTradeDetailsChange(newVal: TradeDetails, oldVal: TradeDetails) {
    if (!isEqual(newVal, oldVal)) {
      this.onInputChange();

      if (newVal?.sellCurrency != oldVal?.sellCurrency || newVal?.buyCurrency != oldVal?.buyCurrency) {
        this.$emit('update:tradePrice', null);
      }
    }
  }

  @Watch('buyCurrency', { immediate: true })
  @Watch('sellCurrency', { immediate: true })
  @Watch('onBehalfOfClient', { immediate: true })
  private loadWallets() {
    if (!this.buyWallet || this.buyCurrency !== this.buyWallet.currency) {
      this.loadWallet('buy');
    }
    if (!this.sellWallet || this.sellCurrency !== this.sellWallet.currency) {
      this.loadWallet('sell');
    }
  }

  @Watch('requestManager.requestStates.getPrices', { immediate: true })
  private onPriceLoadingChanges() {
    const pending = this.requestManager.requestStates.getPrices === 'pending';
    this.updateFieldPlaceholders(pending);
    this.$refs.clientRateAndMarginForm?.calculatingFields(pending);
    if (pending) {
      this.amountForm[this.calculatedAmountField] = '';
    }
  }

  setErrorInField(error: string) {
    batchSetState(this.amountForm, 'errors', {
      [this.calculatedAmountField]: [],
      [this.inputAmountField]: [
        {
          name: error,
          html: true,
          error: '',
        },
      ],
    });

    batchSetState(this.amountForm, 'placeholder', {
      [this.calculatedAmountField]: '',
      [this.inputAmountField]: '',
    });
  }

  checkPricingEngineValidation(error: ApiError) {
    const options = {
      sellCurrency: this.sellCurrency,
      buyCurrency: this.buyCurrency,
      tradingDeskContactHTMLMessage: this.tradingDeskContactHTMLMessage,
      isClientUser: this.isClientUser,
    } as PricingEngineErrorOptions;

    const content = pricingEngineError(error, pricingEngineErrorsNeedingSupport, options);

    if (content) {
      this.errorMessage = content;
      this.setErrorInField('peError');
    }
  }

  get isClientUser() {
    return this.$ahTradesState.store.useAuthStore().isClientUser;
  }

  private get tradingDeskContactHTMLMessage() {
    const tradingDeskEmail = this.$ahTradesState.theme.tradingDeskEmail;
    const tradingDeskPhoneNumber = this.$ahTradesState.theme.tradingDeskPhoneNumber;

    if (tradingDeskEmail || tradingDeskPhoneNumber) {
      let out = 'Please contact our trading desk on ';
      if (tradingDeskPhoneNumber) {
        out += getPhoneNumber(tradingDeskPhoneNumber) || tradingDeskPhoneNumber;
        if (tradingDeskEmail) {
          out += ' or ';
        }
      }
      if (tradingDeskEmail) {
        out += `<a target="_blank" href="mailto:${tradingDeskEmail}">${tradingDeskEmail}</a>`;
      }
      out += '.';

      return out;
    }
    return '';
  }

  private updateFieldPlaceholders(set: boolean) {
    if (set) {
      batchSetState(this.amountForm, 'placeholder', {
        [this.calculatedAmountField]:
          !!this.amountForm[this.inputAmountField] && this.timeFrame ? 'Calculating...' : '',
        [this.inputAmountField]: '',
      });
    } else {
      batchSetState(this.amountForm, 'placeholder', [this.calculatedAmountField, this.inputAmountField], '');
    }
  }

  private onBuyCurrencyChanged(value: string) {
    if (this.sellCurrency === value) {
      this.sellCurrency = this.buyCurrency;
    }
    this.buyCurrency = value;
  }

  private onSellCurrencyChanged(value: string) {
    if (this.buyCurrency === value) {
      this.buyCurrency = this.sellCurrency;
    }
    this.sellCurrency = value;
  }

  private onInputChange(markupInfo: Partial<TradeDetails> = {}) {
    this.cleanFields();
    if (this.isTrade) {
      batchSetState(this.amountForm, 'required', {
        [this.calculatedAmountField]: !this.amountForm[this.inputAmountField],
        [this.inputAmountField]: true,
      });
      this.amountForm[this.calculatedAmountField] = '';
      if (this.timeFrame) {
        this.$emit('update:loadingPrices', !!this.amountForm[this.inputAmountField]);
      }
      this.updateFieldPlaceholders(!this.tradeDetails?.valid);
      if (markupInfo.clientRateMarkup !== undefined || markupInfo.clientRate !== undefined) {
        this.editedTradeDetails.clientRateMarkup = undefined;
        this.editedTradeDetails.clientRate = undefined;
      }
      this.tradeDetailsSubject.next({ ...this.editedTradeDetails, ...markupInfo });
    } else {
      this.amountForm[this.calculatedAmountField] = this.amountForm[this.inputAmountField];
    }
    this.$emit('update:tradeDetails', { ...this.editedTradeDetails, ...markupInfo });
  }

  private loadWallet(direction: 'buy' | 'sell') {
    const wallet = direction === 'buy' ? this.buyWallet : this.sellWallet;
    const currency = direction === 'buy' ? this.buyCurrency : this.sellCurrency;

    if (!wallet || currency !== wallet.currency) {
      this.requestManager
        .newPromise(
          `${currency}WalletLoadState`,
          this.$ahTradesState.store.useWalletsStore().loadCurrencyWallet({
            currency,
            force: true,
            owner: { isPartner: this.isPartnerUser, id: this.ownerId },
          })
        )
        .then((loadedWallet) => {
          (this[direction === 'buy' ? 'buyWallet' : 'sellWallet'] as any) = loadedWallet || null;
          this.onInputChange();
        });
    }
  }

  private onBuyAmountChanged(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      this.rateDirection = AmountType.BUY;
    }
  }

  private onSellAmountChanged(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      this.rateDirection = AmountType.SELL;
    }
  }

  private get showRequiredError() {
    return (
      this.v$.amountForm?.$anyDirty &&
      (this.v$.amountForm?.buyAmount?.required.$invalid || this.v$.amountForm?.sellAmount?.required.$invalid)
    );
  }

  @Watch('showRequiredError')
  onRequiredChange() {
    if (this.showRequiredError) {
      this.v$.amountForm.$touch();
    }
  }

  private get walletsLoaded() {
    return !!(
      this.requestManager.requestStates[`${this.buyCurrency}WalletLoadState`] !== 'pending' &&
      this.requestManager.requestStates[`${this.sellCurrency}WalletLoadState`] !== 'pending'
    );
  }

  private get isTrade() {
    return this.sellCurrency !== this.buyCurrency;
  }

  private get unexpectedDetected() {
    return (
      getState(getChildModel(this.amountForm, 'buyAmount')!, 'unexpectedError') ||
      getState(getChildModel(this.amountForm, 'sellAmount')!, 'unexpectedError')
    );
  }

  private get buyCurrencies() {
    let out = this.$ahTradesState.store
      .useSettingsStore()
      .currencies.filter((c) => c.buyCurrency)
      .map((c) => c.currency);

    if (this.beneficiary) {
      const allowed = this.beneficiary.currency;
      out = out.filter((c) => allowed.includes(c));
    } else if (this.allowedBuyCurrencies) {
      out = out.filter((c) => this.allowedBuyCurrencies?.includes(c));
    }
    return out;
  }

  private get sellCurrencies() {
    let out = this.$ahTradesState.store
      .useSettingsStore()
      .currencies.filter((c) => c.sellCurrency)
      .map((c) => c.currency);
    if (this.allowedSellCurrencies) {
      out = out.filter((c) => this.allowedSellCurrencies?.includes(c));
    }
    return out;
  }

  private get disallowedSellCurrencies() {
    if (this.buyCurrencies && this.buyCurrencies.length === 1) {
      return this.buyCurrencies;
    }
    return undefined;
  }

  private get editedTradeDetails(): TradeDetails {
    return {
      walletId: this.sellWallet?.id,
      sellCurrency: this.sellCurrency,
      buyCurrency: this.buyCurrency,
      amount: this.amount,
      amountType: this.rateDirection || undefined,
      timeFrame: this.timeFrame,
      drawdownDate: this.drawdownDate || undefined,
      clientRateMarkup: this.tradeDetails?.clientRateMarkup,
      clientRate: this.tradeDetails?.clientRate,
      valid: this.walletsLoaded && this.isFormValid && (this.drawdownDate?.valid ?? true),
      dirty: this.tradeDetails?.dirty || this.v$.$anyDirty,
    };
  }

  private get workingTradeDetails(): Partial<TradeDetails> {
    return {
      walletId: this.sellWallet?.id,
      sellCurrency: this.sellCurrency,
      buyCurrency: this.buyCurrency,
      amount: this.amount,
      amountType: this.rateDirection || undefined,
      timeFrame: this.timeFrame,
      drawdownDate: this.drawdownDate || undefined,
    };
  }

  private get isFormValid() {
    if (
      (this.rateDirection === AmountType.SELL && !this.amountForm.sellAmount) ||
      (this.rateDirection === AmountType.BUY && !this.amountForm.buyAmount)
    ) {
      return false;
    }

    if (this.timeFrame?.targetTimeFrame === TimeFrames.OTHER && !this.timeFrame?.targetDate) {
      return false;
    }

    if (this.$refs.clientRateAndMarginForm?.isFormInvalid) {
      return false;
    }

    return !this.v$.amountForm?.$invalid ?? false;
  }

  private get buyAmountModel() {
    return getChildModel(this.amountForm, 'buyAmount')!;
  }

  private get sellAmountModel() {
    return getChildModel(this.amountForm, 'sellAmount')!;
  }

  private get amount() {
    return this.amountForm[this.inputAmountField];
  }

  private get inputAmountField() {
    return this.rateDirection === AmountType.SELL ? 'sellAmount' : 'buyAmount';
  }

  private get calculatedAmountField() {
    return this.rateDirection === AmountType.SELL ? 'buyAmount' : 'sellAmount';
  }

  get isPartnerUser() {
    return !this.onBehalfOfClient && !this.$ahTradesState.store.useAuthStore().isClientUser;
  }

  get ownerId() {
    if (this.onBehalfOfClient) {
      return this.onBehalfOfClient.id;
    }
    return (
      this.$ahTradesState.store.useAuthStore().loggedInIdentity?.client?.id ||
      this.$ahTradesState.store.useAuthStore().loggedInIdentity?.partner?.id
    );
  }

  private updateTradeDetails() {
    this.$emit('update:tradeDetails', { ...this.editedTradeDetails });
  }
}
