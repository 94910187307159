
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PaginatedResponse } from 'ah-api-gateways';
import { BPagination } from 'bootstrap-vue';

/**
 * Wrapper for pagination in Listing
 *
 * Emits:
 * - page-changed (payload: number): numnber of page changed to (zero-based)
 */
@Component({
  components: {
    BPagination,
  },
})
export default class PaginationControls extends Vue {
  @Prop({ default: null }) data!: PaginatedResponse<any> | null;

  @Prop({ default: true }) showTotals!: boolean | string;

  get size() {
    return this.data?.pageSize ?? 10;
  }

  get page() {
    return this.data?.pageNumber ?? 0;
  }

  get dataSize() {
    return Math.min(this.data?.list.length ?? 0, this.data?.total ?? 0);
  }

  setPage(pageNumber: number) {
    this.$emit('page-changed', pageNumber);
  }
}
