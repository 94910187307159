import { render, staticRenderFns } from "./NotificationActionButtons.vue?vue&type=template&id=1ceea456&scoped=true&"
import script from "./NotificationActionButtons.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NotificationActionButtons.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NotificationActionButtons.vue?vue&type=style&index=0&id=1ceea456&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ceea456",
  null
  
)

export default component.exports