
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class UpdatedDate extends Vue {
  @Prop({ default: '' }) date!: string | Date;

  @Prop({ default: false }) loading!: string | boolean;

  @Prop({ default: 0 }) offsetMillis!: number;

  get dateObject() {
    if (this.date instanceof Date) {
      return this.date;
    } else {
      return new Date(this.date);
    }
  }

  get processedDate() {
    return new Date(this.dateObject.getTime() + this.offsetMillis);
  }

  refresh() {
    this.$emit('refresh');
  }
}
