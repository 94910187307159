
import { Component } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import { getState } from '../../helpers/formHelpers';
import { generateUID } from '../../../helpers/uuid';
import BaseFormField from './BaseFormField.vue';

@Component({
  components: {
    VueSelect,
  },
})
export default class RadioFormField extends BaseFormField {
  private uid = generateUID(6);

  optionName(option: any) {
    return typeof option === 'string' ? option : option.label;
  }

  optionValue(option: any) {
    return typeof option === 'string' ? option : option.value;
  }

  isOptionDisabled(option: any) {
    return this.readonly || option?.disabled === true;
  }

  /**
   * Overrides BaseFormField
   */
  get inputClass() {
    return getState(this.model, 'inputClass', 'custom-control custom-radio');
  }

  onSet(value: string) {
    this.$emit('set-value', value);
  }

  get options() {
    return getState(this.model, 'options');
  }

  get inline() {
    return getState(this.model, 'inline', false);
  }
}
