export interface PartnerCommissionReport {
  partnerId: string;
  clientId: string;
  commissionType: CommissionTypes;
  commission: number;
  commissionHomeCurrencyEquivalent: number;
  commissionGbpEquivalent: number;
  remainingCommission: number;
  remainingCommissionHomeCurrencyEquivalent: number;
  remainingCommissionGbpEquivalent: number;
  commissionCurrency: string;
  homeCurrency: string;
  commissionDate: string;
}
export interface SettledCommissionsReport {
  partnerId: string;
  partnerName: string;
  unrealisedCommissionsGbpEquivalent: number;
  realisedCommissionsGbpEquivalent: number;
  withdrawCommissionsGbpEquivalent: number;
  unclaimedCommissionsGbpEquivalent: number;
}

export enum CommissionTypes {
  PROFIT = 'PROFIT',
  SWAP_PNL = 'SWAP_PNL',
  DRAWDOWN_PNL = 'DRAWDOWN_PNL',
  INITIAL_MARGIN_CREDIT_CHARGE_AMOUNT = 'INITIAL_MARGIN_CREDIT_CHARGE_AMOUNT',
}

export const commissionTypesToHuman: { [key in CommissionTypes]: string } = {
  [CommissionTypes.PROFIT]: 'PROFIT',
  [CommissionTypes.SWAP_PNL]: 'SWAP PNL',
  [CommissionTypes.DRAWDOWN_PNL]: 'DRAWDOWN PNL',
  [CommissionTypes.INITIAL_MARGIN_CREDIT_CHARGE_AMOUNT]: 'INITIAL MARGIN CREDIT CHARGE AMOUNT',
};
