import { TradeFrequency } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { FieldOptionObj } from '../form/interfaces';

export const tradeFrequencyOptions: FieldOptionObj[] = [
  { label: 'Daily', value: TradeFrequency.DAILY },
  { label: 'Weekly', value: TradeFrequency.WEEKLY },
  { label: 'Fortnightly', value: TradeFrequency.FORTNIGHTLY },
  { label: 'Monthly', value: TradeFrequency.MONTHLY },
  { label: 'Quarterly', value: TradeFrequency.QUARTERLY },
  { label: 'Half Yearly', value: TradeFrequency.HALF_YEARLY },
  { label: 'Yearly', value: TradeFrequency.YEARLY },
  { label: 'One-off Payment', value: TradeFrequency.ONE_OFF_PAYMENT },
];

export const tradeFrequencyLabel: { [key: string]: string } = {
  [TradeFrequency.DAILY]: 'day',
  [TradeFrequency.WEEKLY]: 'week',
  [TradeFrequency.FORTNIGHTLY]: 'fortnight',
  [TradeFrequency.MONTHLY]: 'month',
  [TradeFrequency.QUARTERLY]: 'quarter',
  [TradeFrequency.HALF_YEARLY]: 'half-year',
  [TradeFrequency.YEARLY]: 'year',
  [TradeFrequency.ONE_OFF_PAYMENT]: 'one-off-payment',
};

export enum Occupation {
  ACCOUNTANT = 'ACCOUNTANT',
  ANALYST = 'ANALYST',
  ARTIST = 'ARTIST',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  CONSULTANT = 'CONSULTANT',
  DOCTOR = 'DOCTOR',
  ENGINEER = 'ENGINEER',
  ENTREPRENEUR = 'ENTREPRENEUR',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  LAWYER = 'LAWYER',
  MANAGER = 'MANAGER',
  MARKETING = 'MARKETING',
  SALES = 'SALES',
  SOFTWARE_DEVELOPER = 'SOFTWARE_DEVELOPER',
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  RETIRED = 'RETIRED',
  UNEMPLOYED = 'UNEMPLOYED',
  OTHER = 'OTHER',
}

export enum SourceOfFunds {
  COMPANY_REVENUE = 'COMPANY_REVENUE',
  INVESTMENT_INCOME = 'INVESTMENT_INCOME',
  OTHER = 'OTHER',
}

export const occupationLabels: { [key in Occupation]: string } = {
  [Occupation.ACCOUNTANT]: 'Accountant',
  [Occupation.ANALYST]: 'Analyst',
  [Occupation.ARTIST]: 'Artist/Creative',
  [Occupation.BUSINESS_OWNER]: 'Business Owner',
  [Occupation.CONSULTANT]: 'Consultant',
  [Occupation.DOCTOR]: 'Doctor/Medical Professional',
  [Occupation.ENGINEER]: 'Engineer',
  [Occupation.ENTREPRENEUR]: 'Entrepreneur',
  [Occupation.FINANCIAL_ADVISOR]: 'Financial Advisor',
  [Occupation.LAWYER]: 'Lawyer',
  [Occupation.MANAGER]: 'Manager',
  [Occupation.MARKETING]: 'Marketing',
  [Occupation.SALES]: 'Sales',
  [Occupation.SOFTWARE_DEVELOPER]: 'Software Developer',
  [Occupation.STUDENT]: 'Student',
  [Occupation.TEACHER]: 'Teacher/Professor',
  [Occupation.RETIRED]: 'Retired',
  [Occupation.UNEMPLOYED]: 'Unemployed',
  [Occupation.OTHER]: 'Other',
};

export const sourceOfFundsLabels: { [key in SourceOfFunds]: string } = {
  [SourceOfFunds.COMPANY_REVENUE]: 'Company Revenue',
  [SourceOfFunds.INVESTMENT_INCOME]: 'Investment Income',
  [SourceOfFunds.OTHER]: 'Other',
};

// All possible volume maxs we allow the user to select in onboarding.
export const maxExpectedVolumes = [50000, 100000, 250000, 500000, 1000000, 3000000, 5000000, 10000000, Infinity];

// All possible salary maxs we allow the user to select in onboarding.
export const maxExperienceYears = [0, 1, 3, 5, Infinity];

const getMinRange = (max: number, options: any[]) => {
  const maxIndex = options.findIndex((value) => value >= max);
  const minValue = maxIndex === 0 || maxIndex === -1 ? 0 : options[maxIndex - 1];
  return minValue;
};

const getMaxRange = (max: number, options: any[]) => {
  const maxIndex = options.findIndex((value) => value >= max);
  const maxValue = maxIndex === 0 || maxIndex === -1 ? options[0] : options[maxIndex];
  return maxValue;
};

export const getMinExpectedVolume = (max: number) => {
  return getMinRange(max, maxExpectedVolumes);
};

export const getMaxExperience = (min: number) => {
  if (min === 0) return 1;
  const index = maxExperienceYears.findIndex((value) => value === min);
  if (index > 0) {
    return maxExperienceYears[index + 1];
  }
};

export const expectedVolumeOptions: FieldOptionObj[] = maxExpectedVolumes.map((value, index) => {
  if (index === 0) {
    return { label: `0 - ${formatCurrencyValue(value)}`, value };
  } else if (index === maxExpectedVolumes.length - 1) {
    return { label: `${formatCurrencyValue(maxExpectedVolumes[index - 1])} +`, value };
  } else {
    return { label: `${formatCurrencyValue(maxExpectedVolumes[index - 1])} - ${formatCurrencyValue(value)}`, value };
  }
});

export const occupationOptions: FieldOptionObj[] = Object.values(occupationLabels).map((label) => ({
  label,
  value: label,
}));

export const experienceYearsOptions: FieldOptionObj[] = [
  { label: '0 - 1 Years', value: 0 },
  { label: '1 - 3 Years', value: 1 },
  { label: '3 - 5 Years', value: 3 },
  { label: '5 Years +', value: 5 },
];

export const sourceOfFundOptions: FieldOptionObj[] = Object.values(sourceOfFundsLabels).map((label) => ({
  label,
  value: label,
}));

export const expectedVolumeToHuman = (maxExpectedVolume?: number) => {
  if (maxExpectedVolume === Infinity || !maxExpectedVolume)
    return expectedVolumeOptions[expectedVolumeOptions.length - 1].label;

  const minLabel = formatCurrencyValue(getMinExpectedVolume(maxExpectedVolume), '');
  const maxLabel = formatCurrencyValue(getMaxRange(maxExpectedVolume, maxExpectedVolumes), '');
  return `GBP ${minLabel} - ${maxLabel}`;
};

export const tradeFrequencyToHuman = (frequency: TradeFrequency) =>
  (tradeFrequencyOptions.find((option) => option.value === frequency)?.label as string) || 'Unknown';

export const experienceToHuman = (minExp: number) => {
  return (experienceYearsOptions.find((option) => option.value === minExp)?.label as string) || 'Unknown';
};

export const occupationToHuman = (occupation?: string) => {
  return occupationLabels[occupation as Occupation] ?? occupation ?? '';
};

export const sourceOfFundsToHuman = (sourceOfFunds?: string) => {
  return sourceOfFundsLabels[sourceOfFunds as SourceOfFunds] ?? sourceOfFunds ?? '';
};
