
import { Component, Vue, Prop } from 'vue-property-decorator';
import CallTextBalloon from '../../../common/components/CallTextBalloon.vue';
import VButton from '../../../common/components/VButton.vue';
import { TreeOptionsBranch, TreeOptionsBranchOption } from '../../interfaces/form';

@Component({
  name: 'choice-branch',
  components: {
    CallTextBalloon,
    VButton,
  },
})
export default class ChoiceBranch extends Vue {
  @Prop({ required: true }) branch!: TreeOptionsBranch;

  @Prop({ required: true }) valueChain!: any[];

  @Prop({ default: true }) showCallText!: boolean;

  get value() {
    return this.valueChain && this.valueChain[0];
  }

  get nextValueChain() {
    return this.valueChain.slice(1);
  }

  get selectedOption() {
    return this.branch.options.find((option) => this.value === option.value);
  }

  onChoice(choice: TreeOptionsBranchOption) {
    if (this.value !== choice.value) {
      this.$emit('choice', [choice]);
    }
  }

  onBranchedChoice(choices: TreeOptionsBranchOption[]) {
    this.$emit('choice', [this.selectedOption, ...choices]);
  }
}
