
import { Component, Watch } from 'vue-property-decorator';
import coinify from 'coinify';
import VueSelect from 'vue-select';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import { ExchangeIcon, InfoTooltipIcon } from '../../../icons/components';
import { getState } from '../../helpers/formHelpers';
import { generateUID } from '../../../helpers/uuid';
import { QuoteCurrencySettings, allowedCurrencies, CurrencyExchange, Currency, AmountType } from 'ah-api-gateways';
import { formatCurrencyValue, cleanCurrencyString, formatCurrencyString } from '../../../helpers/currency';
import BaseFormField from './BaseFormField.vue';
import FormFieldErrors from './FormFieldErrors.vue';

@Component({
  components: {
    VueSelect,
    ExchangeIcon,
    InfoTooltipIcon,
    FormFieldErrors,
  },
})
export default class CurrencyExchangeFormField extends BaseFormField {
  amountType: AmountType = AmountType.SELL;

  amount: number | null = null;

  allowedCurrencies = allowedCurrencies;

  exchange: CurrencyExchange | null = null;

  inputText: string = '';

  inputDirty = false;

  $refs!: {
    currencyInput: HTMLInputElement;
  };

  private uid = generateUID(6);

  @Watch('field.$dirty')
  onFieldReset() {
    if (!this.field.$dirty) {
      this.inputDirty = false;
    }
  }

  @Watch('field.$model', { deep: true, immediate: true })
  onModelChange() {
    if (this.field.$model) {
      this.amountType = this.field.$model.amountType || AmountType.SELL;
      this.amount = this.field.$model.amount;
      this.updateInputText();
    }
  }

  @Watch('amountType')
  onAmountTypeChange() {
    if (this.amountType !== this.field.$model.amountType) {
      this.updateModel({ amountType: this.amountType }, true);
    }
  }

  get showAsDirty() {
    return this.inputDirty || this.customErrors.length > 0;
  }

  get fieldErrorsShown() {
    return this.field && (this.field.$error || this.customErrors.length > 0);
  }

  updateInputText(force = false) {
    const existing = this.cleanNumber(this.inputText);

    if (force || existing !== (this.field.$model || {}).amount) {
      this.inputText = this.formatNumber((this.field.$model || {}).amount) || '';
    }
  }

  switchCurrencies() {
    this.updateModel(
      {
        buyCurrency: this.sellCurrency,
        sellCurrency: this.buyCurrency,
        amountType: this.amountType === AmountType.SELL ? AmountType.BUY : AmountType.SELL,
        tradeDirection: this.tradeDirection,
      },
      true
    );
  }

  capitalize(str: string) {
    return capitalize(str);
  }

  onBuyCurrencyChange(val: Currency) {
    if (this.buyCurrency !== val) {
      this.buyCurrency = val;
    }
  }

  onSellCurrencyChange(val: Currency) {
    if (this.sellCurrency !== val) {
      this.sellCurrency = val;
    }
  }

  formatNumber(num: number | null) {
    if (typeof num === 'number' && !Number.isNaN(num)) {
      return formatCurrencyValue(num);
    }
    return '';
  }

  setAmount(value: string, keepPristine = false) {
    const cursorStart = this.$refs.currencyInput.selectionStart;
    const cursorEnd = this.$refs.currencyInput.selectionEnd;
    value = value.replace(/[a-zA-Z]/g, '');
    const cleanVal = value ? cleanCurrencyString(value, undefined, true) : value;
    const cleanNumber = this.cleanNumber(cleanVal);
    if (cleanVal && !Number.isNaN(cleanNumber)) {
      this.inputText = formatCurrencyString(cleanVal);
    } else {
      this.inputText = cleanVal;
    }
    this.$forceUpdate();

    if (!Number.isNaN(cleanNumber)) {
      if (cleanNumber > 0) {
        this.updateModel(
          {
            amount: typeof cleanNumber === 'number' && cleanNumber >= 0 ? cleanNumber : null,
          },
          keepPristine
        );
      }
      this.$nextTick(() => {
        if (typeof cursorStart === 'number' && typeof cursorEnd === 'number') {
          if (value === '' || cleanNumber === 0) {
            this.$refs.currencyInput.setSelectionRange(0, 0);
          } else {
            const delta = this.calcLengthDifference(
              value.substr(0, cursorStart),
              this.$refs.currencyInput.value.substr(0, cursorStart)
            );
            this.$refs.currencyInput.setSelectionRange(cursorStart + delta, cursorEnd + delta);
          }
        }
      });
    }
  }

  private calcLengthDifference(prev: string, curr: string): number {
    const deltaSeparatorsPrev = prev.length - cleanCurrencyString(prev).length;
    const deltaSeparatorsNow = curr.length - cleanCurrencyString(curr).length;

    return deltaSeparatorsNow - deltaSeparatorsPrev;
  }

  private cleanNumber(amount: string): number {
    const cleanVal = cleanCurrencyString(amount);
    const number = Number(cleanVal);

    if (Number.isNaN(number)) {
      return NaN;
    }
    return Number(number.toFixed(2));
  }

  updateModel(update?: any, keepPristine = false) {
    const model: Partial<QuoteCurrencySettings> = {
      amountType: this.amountType,
      amount: this.amount,
      sellCurrency: this.sellCurrency,
      buyCurrency: this.buyCurrency,
      tradeDirection: this.tradeDirection,
      ...update,
    };

    if (!update.tradeDirection) {
      model.tradeDirection = this.isTradeDirectionSellBuy
        ? `${model.sellCurrency}${model.buyCurrency}`
        : `${model.buyCurrency}${model.sellCurrency}`;
    }

    if (!isEqual(model, this.field.$model)) {
      this.$emit('set-value', model, keepPristine);
    }
  }

  get amountTypeField(): AmountType {
    return (this.field.$model || {}).amounType || this.amountType;
  }

  get buyCurrency(): Currency {
    return (
      (this.field.$model || {}).buyCurrency ||
      getState(this.model, 'selectedBuyCurrency', this.setDefaults ? 'EUR' : null)
    );
  }

  set buyCurrency(val) {
    this.updateModel({ buyCurrency: val }, this.keepPristine);
  }

  get isTradeDirectionSellBuy(): boolean {
    return this.tradeDirection === `${this.sellCurrency}${this.buyCurrency}`;
  }

  set isTradeDirectionSellBuy(val) {
    if (val) {
      this.updateModel({ tradeDirection: `${this.sellCurrency}${this.buyCurrency}` });
    } else {
      this.updateModel({ tradeDirection: `${this.buyCurrency}${this.sellCurrency}` });
    }
  }

  get tradeDirection(): string {
    return (this.field.$model || {}).tradeDirection || `${this.sellCurrency}${this.buyCurrency}`;
  }

  get toCurrency(): Currency {
    return this.isTradeDirectionSellBuy ? this.buyCurrency : this.sellCurrency;
  }

  get fromCurrency(): Currency {
    return this.isTradeDirectionSellBuy ? this.sellCurrency : this.buyCurrency;
  }

  get sellCurrency(): Currency {
    return (
      (this.field.$model || {}).sellCurrency ||
      getState(this.model, 'selectedSellCurrency', this.setDefaults ? 'GBP' : null)
    );
  }

  set sellCurrency(val) {
    this.updateModel({ sellCurrency: val }, this.keepPristine);
  }

  get sellCurrencies() {
    return getState(
      this.model,
      'sellCurrencies',
      this.allowedCurrencies.filter((c) => c !== this.buyCurrency)
    );
  }

  get buyCurrencies() {
    return getState(
      this.model,
      'buyCurrencies',
      this.allowedCurrencies.filter((c) => c !== this.sellCurrency)
    );
  }

  get setDefaults() {
    return getState(this.model, 'setDefaults', true);
  }

  get displaySymbols() {
    return getState(this.model, 'displaySymbols', true);
  }

  get keepPristine() {
    return getState(this.model, 'keepPristine', true);
  }

  get currencyTitle() {
    return getState(this.model, 'currencyTitle', 'Amount');
  }

  get amountTitle() {
    return getState(this.model, 'amountTitle', '');
  }

  get sellCurrencyLabel() {
    return getState(this.model, 'sellCurrencyLabel', 'Sell Currency');
  }

  get buyCurrencyLabel() {
    return getState(this.model, 'buyCurrencyLabel', 'Buy Currency');
  }

  get supportText() {
    return getState(
      this.model,
      'supportText',
      `If you can't find the currency you're looking for please contact our trading desk`
    );
  }

  get currencyObj() {
    return this.amountType === AmountType.BUY ? this.buyCurrencyObj : this.sellCurrencyObj;
  }

  get buyCurrencyObj() {
    return coinify.get(this.buyCurrency);
  }

  get sellCurrencyObj() {
    return coinify.get(this.sellCurrency);
  }

  get tradeDirectionRate() {
    return this.isTradeDirectionSellBuy
      ? getState(this.model, 'sellUnprotectedSpotRate', '-', true)
      : getState(this.model, 'buyUnprotectedSpotRate', '-', true);
  }

  getCurrencyObj(currency: Currency) {
    try {
      return coinify.get(currency);
    } catch (e) {
      return { symbol: '?' };
    }
  }

  showBlurError() {
    if (this.setDefaults) {
      this.onBlur();
    }
  }

  onBlur() {
    setTimeout(() => {
      this.field.$touch();
      this.inputDirty = true;
      this.updateInputText(true);
      this.$forceUpdate();
    });
  }
}
