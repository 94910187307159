
import { Component } from 'vue-property-decorator';
import BaseFormField from './BaseFormField.vue';
import { FieldError } from '../../interfaces/form';
import { getFieldErrorList } from '../../helpers';

@Component
export default class FormFieldErrors extends BaseFormField {
  get fieldErrors(): FieldError[] {
    return getFieldErrorList(this.field, this.model);
  }
}
