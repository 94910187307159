
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { makeFormModel, makeFormValidation, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import useVuelidate, { Validation } from '@vuelidate/core';
import { FormModel } from 'ah-common-lib/src/form/interfaces';

@Component({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    const { currencyForm } = this as CurrencySelectInput;

    return {
      currencyForm: makeFormValidation(currencyForm!),
    };
  },
})
export default class CurrencySelectInput extends Vue {
  @Prop({ default: '' }) currency!: string;

  @Prop({ default: true }) allowEmpty!: boolean;

  @Prop({ default: '' }) label!: string;

  @Prop({ default: undefined }) allowedCurrencies!: string[] | undefined;

  @Prop({ default: undefined }) disallowedCurrencies!: string[] | undefined;

  private v$!: Validation;

  private currencyForm: FormModel | null = null;

  created() {
    this.currencyForm = makeFormModel({
      name: 'currencyForm',
      title: '',
      fieldType: 'form',
      fields: [selectField('currency', ``, () => this.currencyOptions)],
    });
    setState(this.currencyField!, 'title', this.label);

    this.$ahTradesState.store
      .useSettingsStore()
      .loadTradeableCurrencies()
      .then(() => {
        this.onOptionsChange();
        this.onQueryChange();
      });
  }

  get currencyOptions() {
    let out = this.$ahTradesState.store
      .useSettingsStore()
      .currencies.filter((c) => c.enabled)
      .map((i) => ({
        value: i.currency,
        label: i.currency,
      }));

    if (this.allowedCurrencies) {
      out = out.filter((c) => this.allowedCurrencies!.includes((c as any).value));
    }

    if (this.disallowedCurrencies) {
      out = out.filter((c) => !this.disallowedCurrencies!.includes((c as any).value));
    }

    return out;
  }

  @Watch('currencyOptions')
  onOptionsChange() {
    if (this.currencyForm) {
      const currencySelect = getChildModel(this.currencyForm, 'currency')!;

      setState(currencySelect, 'readonly', this.currencyOptions.length <= 1);

      this.checkCurrencyValidity();

      if (this.currencyOptions.length === 1 && this.currencyForm.currency !== this.currencyOptions[0].value) {
        this.currencyForm.currency = this.currencyOptions[0].value;
        this.onCurrencyChange();
      }
    }
  }

  @Watch('currency')
  onQueryChange() {
    if (this.currencyForm) {
      this.currencyForm.currency = this.currency;
      this.checkCurrencyValidity();
    }
  }

  checkCurrencyValidity() {
    if (
      this.currencyForm &&
      this.currencyForm.currency &&
      this.currencyOptions.length &&
      this.currencyOptions.findIndex((o) => o.value === this.currencyForm!.currency) === -1
    ) {
      this.currencyForm.currency = this.allowEmpty ? '' : this.currencyOptions[0].value;
      this.onCurrencyChange();
    }
  }

  onFormEvent(formEvent: FormEvent) {
    if (formEvent.event === 'form-field-set-value') {
      this.onCurrencyChange();
    }
  }

  onCurrencyChange() {
    this.$emit('update:currency', this.currencyForm!.currency);
  }

  get currencyField() {
    if (this.currencyForm) {
      return this.currencyForm.$fields.find((f) => f.$name === 'currency')!;
    }
  }
}
