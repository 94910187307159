import _Vue, { PluginObject } from 'vue';
import { Toast } from 'ah-common-lib';
import VueI18n from 'vue-i18n';
import { BeneficiaryService } from 'ah-api-gateways';
import { MediaQueries } from 'ah-common-lib/src/mediaQuery';
import { AuthStore, SettingsStore } from 'ah-common-lib/src/models';

declare module 'vue/types/vue' {
  interface Vue {
    $ahBeneficiariesState: AhBeneficiariesState;
  }
}

export interface AhBeneficiariesPluginOptions {
  state: AhBeneficiariesState;
}

export interface AhBeneficiariesState {
  theme: {
    name: string;
  };
  mediaQuery: MediaQueries;
  showDevTools: boolean;
  toast: Toast.Toast;
  i18n: VueI18n;
  store: {
    useSettingsStore: () => SettingsStore;
    useAuthStore: () => AuthStore;
  };
  services: {
    beneficiary: BeneficiaryService;
  };
}

export const AhBeneficiariesPlugin = {
  install: function install(Vue: typeof _Vue, options: AhBeneficiariesPluginOptions) {
    const culledState: AhBeneficiariesState = {
      mediaQuery: options.state.mediaQuery,
      showDevTools: options.state.showDevTools,
      theme: options.state.theme,
      toast: options.state.toast,
      store: options.state.store,
      services: options.state.services,
      i18n: options.state.i18n,
    };

    Vue.prototype.$ahBeneficiariesState = new _Vue({
      data: culledState,
    });
  },
} as PluginObject<AhBeneficiariesPluginOptions>;

export function useBeneficiariesState() {
  return _Vue.prototype.$ahBeneficiariesState as AhBeneficiariesState;
}
