import { render, staticRenderFns } from "./NotificationsModal.vue?vue&type=template&id=13a69233&scoped=true&"
import script from "./NotificationsModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NotificationsModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NotificationsModal.vue?vue&type=style&index=0&id=13a69233&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a69233",
  null
  
)

export default component.exports