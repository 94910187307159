
import { Component } from 'vue-property-decorator';
import { EyeIcon, EyeCrossedIcon } from '../../../icons/components';
import { getState } from '../../helpers/formHelpers';
import TextFormField from './TextFormField.vue';
import { FormIconClickEvent } from '../../interfaces';

@Component
export default class PasswordFormField extends TextFormField {
  private showPassword = false;

  onIconClick(name: string) {
    this.showPassword = !this.showPassword;

    this.emitFormEvent({
      field: this.field,
      model: this.model,
      event: 'form-icon-clicked',
      name,
    } as FormIconClickEvent<string>);
  }

  get fieldType() {
    return this.showPassword ? 'text' : 'password';
  }

  get icon() {
    if (!getState(this.model, 'allowShowPassword')) {
      return null;
    }

    return this.showPassword ? EyeCrossedIcon : EyeIcon;
  }
}
