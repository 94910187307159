
import { Component, Prop, Mixins } from 'vue-property-decorator';
import WithRequestManager from '../../../requestManager/WithRequestManager.vue';

@Component
export default class ManagedLoadingOverlay extends Mixins(WithRequestManager) {
  @Prop({ default: true }) exposeToParent!: boolean | string;

  requestManagerConfig = {
    exposeToParent: this.exposedToParent,
  };

  get exposedToParent() {
    return this.exposeToParent !== false;
  }

  get requestStates() {
    return this.getAllRequestsInState('pending', true);
  }

  get anyLoading() {
    if (this.$attrs.loading !== undefined) {
      return this.$attrs.loading;
    }
    return this.anyRequestInState('pending', true);
  }

  get anyFailed() {
    if (this.$attrs.error !== undefined) {
      return this.$attrs.error;
    }
    return this.anyRequestInState('error', true);
  }

  get allFailedRequests() {
    return this.getAllRequestsInState('error', true);
  }

  onRetry() {
    this.allFailedRequests.forEach((r) => r.managerData.retryRequest(r.request));
    this.$emit('retry');
  }
}
