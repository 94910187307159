import { render, staticRenderFns } from "./CompanyDetailsStep.vue?vue&type=template&id=58230197&scoped=true&"
import script from "./CompanyDetailsStep.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CompanyDetailsStep.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CompanyDetailsStep.vue?vue&type=style&index=0&id=58230197&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58230197",
  null
  
)

export default component.exports