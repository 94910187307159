
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {
  addMonths,
  addWeeks,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  isSameDay,
  format,
  isSameMonth,
  isSameYear,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
} from 'date-fns';
import { isDescendant } from '../../helpers/dom';
import { uniqueId } from 'lodash';

export interface DateFilterActions extends DateRange {
  label: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export const defaultChoices = [
  { label: 'Today', start: startOfToday(), end: endOfToday() },
  { label: 'Tomorrow', start: startOfTomorrow(), end: endOfTomorrow() },
  { label: 'This week', start: startOfWeek(new Date()), end: endOfWeek(new Date()) },
  { label: 'Next week', start: startOfWeek(addWeeks(new Date(), 1)), end: endOfWeek(addWeeks(new Date(), 1)) },
  { label: 'This month', start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
  { label: 'Next month', start: startOfMonth(addMonths(new Date(), 1)), end: endOfMonth(addMonths(new Date(), 1)) },
  { label: 'This year', start: startOfYear(new Date()), end: endOfYear(new Date()) },
  { label: 'Next year', start: startOfYear(addYears(new Date(), 1)), end: endOfYear(addYears(new Date(), 1)) },
];

/**
 * Range date selector
 *
 * Emits:
 * - date (payload: DateRange): .sync'able date range
 * - submit (payload: Date | DateRange): date selected on submittion
 */
@Component({
  components: {
    DatePicker,
  },
})
export default class DateSelector extends Vue {
  $refs!: {
    popover: HTMLDivElement;
    datePicker: Vue & {
      move: (arg: number | Date | string | { month: number; year: number }, opts: any) => Promise<void>;
    };
  };

  /**
   * Title of the Date selector
   */
  @Prop({ default: '' }) title!: string;

  /**
   * Confirm button label
   */
  @Prop({ default: 'Confirm' }) confirmButtonLabel!: string;

  /**
   * Prefix to show in date Selector button
   */
  @Prop({ default: '' }) popupButtonPrefix!: string;

  /**
   * Whether date picker is a range of dates
   */
  @Prop({ default: false }) isRanged!: boolean | string;

  /**
   * Known date ranges that the user can select to auto fill the date picker
   *
   * if not ranged date this will be ignored
   */
  @Prop({ default: () => defaultChoices }) commonChoices!: DateFilterActions[];

  /**
   * Date selected in the date picker
   */
  @Prop({ default: null }) date!: DateRange | Date | null;

  /**
   * Dates not able to be selected
   */
  @Prop({ default: null }) excludedDates!: Date[] | null;

  /**
   * Whether clear option is available
   */
  @Prop({ default: false }) clearable!: boolean | string;

  /**
   * Clear value
   *
   * Will be ignored if prop `clearable` is set to false
   */
  @Prop({ default: null }) clearValue!: DateRange | Date | null;

  /**
   * Additional changes to the calendar options
   * Same values for selected attribute and dragable attribute is being used
   */
  @Prop({ required: false }) options?: any[];

  /**
   * Actions menu target key
   * Should be set whether not to have common choices list.
   */
  @Prop({ default: false }) hideChoices!: boolean | string;

  /**
   * Placeholder value
   * If a date is not selected shows this text
   */
  @Prop({ default: 'All dates' }) placeholder!: string;

  /**
   * Format value
   * Shows the data selected with this format
   */
  @Prop({ default: 'dd MMMM' }) format!: string;

  /**
   * Whether to autosubmit, i.e. change date values as soon as they are selected on the UI (removing confirm/cancel buttons)
   */
  @Prop({ default: false }) autoSubmit?: boolean | string;

  /**
   * Whether to autosubmit, i.e. change date values as soon as they are selected on the UI (removing confirm/cancel buttons)
   */
  @Prop({ default: 'btn-primary' }) btnClass!: string;

  private show = false;

  private fromPage: { month: number; year: number } = { month: 0, year: 0 };

  private toPage: { month: number; year: number } = { month: 0, year: 0 };

  private popOverTarget = `date-menu-toggle-${uniqueId()}`;

  private get attributes() {
    return {
      highlight: {
        class: 'date-highlight',
        contentClass: 'date-highlight-content',
      },
      ...this.options,
    };
  }

  private selectedDate: DateRange | Date | null = null;

  private dateToSelect: DateRange | Date | null = null;

  @Watch('selectedDate', { immediate: true })
  onSelectedDateChange() {
    this.dateToSelect = this.selectedDate;
  }

  onDateSelected(value: DateRange | Date | null) {
    this.dateToSelect = value;
    if (this.useAutoSubmit) {
      this.submit();
    }
  }

  windowClickListener!: (event: MouseEvent) => void;

  mounted() {
    this.windowClickListener = (event: any) => {
      if (this.show && this.$refs.popover && !isDescendant(this.$refs.popover, event.target)) {
        this.show = false;
      }
    };
    window.addEventListener('click', this.windowClickListener);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.windowClickListener);
  }

  showModal() {
    if (this.show === false) {
      setTimeout(() => {
        this.show = true;
      });
    }
  }

  get selectedDateDescription() {
    try {
      if (this.start && this.end && this.isRangedDatePicker) {
        // if is a known date range, display the known label
        const knownDate = this.commonChoices.find((choice) => {
          return isSameDay(this.start!, choice.start) && isSameDay(this.end!, choice.end);
        });
        if (knownDate) return knownDate.label;

        // else try to figure out what's the best label to display
        if (isSameDay(this.start, this.end!)) {
          return format(this.start, 'dd MMMM');
        }
        if (isSameMonth(this.start, this.end)) {
          return `${format(this.start!, 'dd')}-${format(this.end, 'dd')} ${format(this.start, 'MMM')}`;
        }
        if (isSameYear(this.start, this.end)) {
          return `${format(this.start, 'dd MMM')}-${format(this.end, 'dd MMM')}`;
        }
        return `${format(this.start, 'dd MMM yy')}-${format(this.end, 'dd MMM yy')}`;
      } else if (this.start) {
        return format(this.start, this.format!);
      } else {
        return this.placeholder;
      }
    } catch (e) {
      return this.placeholder;
    }
  }

  get start() {
    if (!this.selectedDate) {
      return null;
    }

    if (this.selectedDate instanceof Date) {
      return this.selectedDate;
    } else {
      return (this.selectedDate as DateRange).start;
    }
  }

  get end() {
    if (!this.selectedDate) {
      return null;
    }

    if (this.selectedDate instanceof Date) {
      return this.selectedDate;
    } else {
      return (this.selectedDate as DateRange).end;
    }
  }

  get shouldHideChoices() {
    return this.hideChoices !== false;
  }

  get isRangedDatePicker() {
    return this.isRanged !== false;
  }

  get useAutoSubmit() {
    return this.autoSubmit !== false;
  }

  get isDirty() {
    if (!this.selectedDate) return false;
    if (!isNaN(new Date(this.selectedDate as Date).getDate())) {
      return !isSameDay(this.selectedDate as Date, this.clearValue as Date);
    } else if (this.isRangedDatePicker) {
      const range = this.selectedDate as DateRange;
      const defaultRange = this.clearValue as DateRange;
      return (
        (!defaultRange && !!this.selectedDate) ||
        !(isSameDay(defaultRange.start, range.start) && isSameDay(defaultRange.end, range.end))
      );
    }

    return this.selectedDate !== this.clearValue;
  }

  isActive(choice: DateFilterActions) {
    if (this.isRangedDatePicker && this.dateToSelect) {
      const range = this.dateToSelect as DateRange;
      return isSameDay(choice.start, range.start) && isSameDay(choice.end, range.end);
    }
    return false;
  }

  clear() {
    this.dateToSelect = this.clearValue;
    this.selectedDate = this.clearValue;
    this.$emit('update:date', this.selectedDate);
  }

  cancel() {
    this.show = false;
  }

  /**
   * Select date/date range from one of the choices provided.
   *
   * If current view does not include any of the dates being selected, it will auto scroll to the beginning of the range
   */
  selectFromChoice(date: DateRange | Date) {
    this.dateToSelect = date;
    const minPageDate = new Date(this.fromPage.year, this.fromPage.month - 1, 1);
    const maxPageDate = endOfMonth(new Date(this.toPage.year, this.toPage.month - 1, 1));
    const minDate = !(date instanceof Date) ? (date as DateRange).start : (date as Date);
    const maxDate = !(date instanceof Date) ? (date as DateRange).end : (date as Date);
    if (!maxDate || !minDate || minPageDate > maxDate || maxPageDate < minDate) {
      this.$refs.datePicker.move({ month: minDate!.getMonth() + 1, year: minDate!.getFullYear() }, { position: 1 });
    }
    if (this.autoSubmit) {
      this.submit();
    }
  }

  submit() {
    this.show = false;
    this.selectedDate = this.dateToSelect;
    this.$emit('submit', this.selectedDate);
    this.$emit('update:date', this.selectedDate);
  }

  @Watch('date', { immediate: true })
  onDateChange() {
    this.selectedDate = this.date;
  }
}
