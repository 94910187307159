import { VersionedObject, CreatableObject, UpdatableObject } from './rest';

export enum CreditLimitRequestStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum CreditLimitStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  AMENDED = 'AMENDED',
  EXPIRED = 'EXPIRED',
}

export interface CreditData {
  partnerId: string;
  clientId: string;
  marginCreditLimitSpots: number;
  marginCreditLimitForwards: number;
  marginCreditLimitOptions: number;
  marginCreditLimitTotal: number;
  imccEnabled: boolean;
  spotsIm: boolean;
  currency: string;
  expirationDate: string;
}

export interface CreditProfile extends VersionedObject, CreatableObject, UpdatableObject {
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  marginCreditLimitTotal: number;
  marginCreditLimitTotalInUse: number;
  marginCreditLimitTotalUsage: number;
  marginCreditLimitTotalRemaining: number;
  imccEnabled: boolean;
  spotsIm: boolean;
  currency: string;
  expirationDate: string;
}

export interface MarginCreditUsageReport {
  marginCreditLimitTotal: number;
  marginCreditLimitTotalInUse: number;
  marginCreditLimitTotalUsage: number;
  clientType: string;
  countryCode: string;
  currency: string;
}

export interface ClientMarginCreditUsageReport extends MarginCreditUsageReport {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;
}

export interface CreditProfileAudit extends CreditProfile {
  amendmentReason: string;
  amendedAt: string;
  amendedBy: string;
}

export interface MarginCreditAudit {
  id: string;
  clientId: string;
  partnerId: string;
  currency: string;
  marginCreditLimitTotal: number;
  profile: CreditProfileAudit;
  status: CreditLimitStatus;
}

export interface MarginCreditFilters {
  partnerName?: string;
  clientName?: string;
  clientType?: string;
  countryCode?: string;
  query?: string;
  status?: CreditLimitStatus;
  currency?: string;
  spotsIm?: boolean;
}
