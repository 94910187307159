
import { Component, Vue, Prop } from 'vue-property-decorator';
import ListDownloadButton from './ListDownloadButton.vue';
import { PaginatedResponse, PaginatedQuery } from 'ah-api-gateways';
import { RequestState } from 'ah-requests';
import { DynamicTable } from '../../components';
import PaginationControls from './PaginationControls.vue';

@Component({
  components: {
    ListDownloadButton,
    PaginationControls,
  },
})
export default class PaginatedTable<T = any> extends Vue {
  $refs!: {
    table: DynamicTable;
  };

  @Prop({ default: null }) data!: PaginatedResponse<T> | null;

  @Prop({ default: null }) query!: PaginatedQuery | null;

  @Prop({ default: 'idle' }) dataLoadState!: RequestState;

  @Prop({ default: 'idle' }) dataDownloadState!: RequestState;

  @Prop({ default: 'items' }) itemsLabel!: string;

  @Prop({ default: 'id' }) primaryKey!: string;

  @Prop({ default: false }) customSearch!: boolean;

  @Prop({ default: () => [] }) selectedItems!: any[];

  @Prop({ default: false }) showExport!: boolean | string;

  @Prop({ default: true }) showTableFooterTotals!: boolean | string;

  @Prop({ default: false }) hidePagination!: boolean | string;

  setPage(pageNumber: any) {
    this.$emit('sort', {
      ...this.query,
      pageNumber,
    } as PaginatedQuery);
  }

  reload() {
    this.$emit('sort', { ...this.query } as PaginatedQuery);
  }
}
