
import { Component, Watch } from 'vue-property-decorator';
import { VueTelInput } from 'vue-tel-input';
import { getState } from '../../helpers/formHelpers';
import BaseFormField from './BaseFormField.vue';
import { displayNumber } from '../../../helpers/calls';
import { generateUID } from '../../../helpers/uuid';
import 'vue-tel-input/dist/vue-tel-input.css';

interface VueTelInputValidation {
  country: {
    dialCode: string;
    iso2: string;
    name: string;
  };
  countryCallingCode: string;
  countryCode: string;
  formatted: string;
  nationalNumber: string;
  number: string;
  valid: boolean;
}

@Component({
  components: {
    VueTelInput,
  },
})
export default class PhoneFormField extends BaseFormField {
  $refs!: {
    telInput: Vue;
  };

  private id = '';

  /**
   * We keep `valid` stored so changes to the model can trigger recalculation of input-based validation
   */
  private valid: boolean = true;

  created() {
    this.id = generateUID(10);
  }

  /**
   * Input event reaction:
   * - Trigger `setValue`
   * - Maintain focus, if currently focussed (preventing focus change bug in vue-tel-input), using telInput's focus method
   */
  onInput(val: string, value: VueTelInputValidation) {
    const input = this.$refs.telInput?.$refs?.input as HTMLElement;
    if (input && document.activeElement === input) {
      setTimeout(() => (this.$refs.telInput as any).focus());
    }
    this.setValue(val, value);
  }

  /**
   * Set value method called on user input - this sets field.$model but also `typedValue` and `fieldValue`,
   * to ensure field display and calidation runs as expected
   *
   * If field is too short, it is considered as empty, so field can be non-required
   */
  setValue(val: string, validation: VueTelInputValidation) {
    if (val.length > 4 || (validation.country && val !== `+${validation.country.dialCode}`)) {
      this.fieldValue = validation.valid ? validation.number : val;
      this.typedValue = val;
    } else {
      this.fieldValue = '';
      this.typedValue = val;
    }
    if (this.fieldValue !== this.field.$model) {
      this.$emit('set-value', this.fieldValue, !this.field.$dirty && !this.dirtyOnInput);
    }
    this.$set(this.model.$state, 'valid', validation.valid);
    this.$forceUpdate();
  }

  /**
   * We store `typedValue` and `fieldValue` so phone number input validation is triggered as intended by VueTelInput.
   *
   * The input shows preferably `typedValue` (if set), so normal user input is triggers phone input validation as intended.
   *
   * During normal operation, `fieldValue` matches the model, and `typedValue` is shown.
   * When $model changes externally, `typedValue` and `fieldValue` are reset - this is done via the @Watch annotation
   */
  private typedValue = '';
  private fieldValue = '';

  @Watch('field.$model')
  onModelChange() {
    if (!this.fieldValue || this.field.$model !== this.fieldValue) {
      this.fieldValue = '';
      this.typedValue = '';
    }
  }

  /**
   * If `typedValue` is set
   */
  get value() {
    return this.typedValue || displayNumber(this.field.$model) || this.field.$model;
  }

  get dirtyOnInput() {
    return getState(this.model, 'dirtyOnInput');
  }

  get placeholder() {
    return getState(this.model, 'placeholder');
  }

  get defaultCountry() {
    return getState(this.model, 'defaultCountry', '');
  }

  get dynamicPlaceholder() {
    return getState(this.model, 'dynamicPlaceholder', true);
  }

  get enabledCountryCode() {
    return getState(this.model, 'enabledCountryCode', true);
  }

  get inputOptions() {
    return {
      id: this.id,
      placeholder: this.placeholder,
      styleClasses: [
        'field-group-field-input form-control',
        this.inputClass,
        { [this.inputErrorClass]: this.fieldErrorsShown },
      ],
      ...getState(this.model, 'inputOptions', { showDialCode: true, tabindex: 0 }),
    };
  }

  get dropdownOptions() {
    return {
      ...getState(this.model, 'dropdownOptions', { showDialCodeInList: true, showDialCodeInSelection: true }),
    };
  }

  get countries() {
    return getState(this.model, 'countries', undefined);
  }

  get autoDefaultCountry() {
    return this.value.length === 0;
  }

  onValidate(val: VueTelInputValidation) {
    this.valid = val.valid;
    this.$set(this.model.$state, 'valid', this.valid);
  }

  @Watch('value')
  @Watch('field')
  /**
   * Trigger a `valid` state change on value or field changes
   */
  onValidityChange() {
    this.$set(this.model.$state, 'valid', this.valid);
  }
}
