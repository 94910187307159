import { DeletableObject, VersionedObject } from '..';
import { AuditData } from '../auditData';

export enum BankAccountSchema {
  SEPA = 'SEPA',
  FASTER = 'FASTER',
  SWIFT = 'SWIFT',
}

export enum BankAccountAuditActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const bankAccountActionsToHuman: { [key in BankAccountAuditActions]: string } = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
};

export interface BankAccountData extends VersionedObject, Partial<DeletableObject> {
  id: string;
  name: string;
  accountHolderName: string;
  bankingSchema: BankAccountSchema;
  isMifidAccount: boolean;
  bicSwift?: string | null;
  iban?: string | null;
  accountNumber?: string;
  bankName: string;
  bankCountryCode: string;
  sortCode?: string | null;
  bankStreet?: string;
  bankCity?: string | null;
  bankStateProvince?: string | null;
  bankPostalCode?: string | null;
  currency: string;
  description?: string | null;
}

export interface BankAccountAuditData extends BankAccountData, AuditData<BankAccountAuditActions> {}
