import {
  InstrumentType,
  AmountType,
  UpdatableObject,
  AssetClass,
  VersionedObject,
  VoiceTradeState,
  DeletableObject,
} from '..';
import { AuditData } from '../auditData';

export enum VoiceTradePurposeTypes {
  OPENING_TRADE = 'OPENING_TRADE',
  DRAWDOWN = 'DRAWDOWN',
  ROLL_FORWARD = 'ROLL_FORWARD',
  CANCEL_TRADE = 'CANCEL_TRADE',
  AMEND_RATE = 'AMEND_RATE',
}

export const voiceTradePurposeTypesToHuman: { [key in VoiceTradePurposeTypes]: string } = {
  OPENING_TRADE: 'Opening Trade',
  DRAWDOWN: 'Drawdown',
  ROLL_FORWARD: 'Roll Forward',
  CANCEL_TRADE: 'Cancel Trade',
  AMEND_RATE: 'Amend Rate',
};

export enum OptionStatus {
  EXPIRED = 'EXPIRED',
  EXERCISED = 'EXERCISED',
  DEAD = 'DEAD',
  LIVE = 'LIVE',
}

export const optionStatusLabels: { [key in string]: OptionStatus } = {
  ['EXPIRED']: OptionStatus.EXPIRED,
  ['EXERCISED']: OptionStatus.EXERCISED,
  ['DEAD']: OptionStatus.DEAD,
  ['LIVE']: OptionStatus.LIVE,
};

export const optionStatusToHuman: { [key in OptionStatus]: string } = {
  EXPIRED: 'Expired',
  EXERCISED: 'Exercised',
  DEAD: 'Dead',
  LIVE: 'Live',
};

export enum OptionProductType {
  VANILLA = 'VANILLA',
  PARTICIPATING_FORWARD = 'PARTICIPATING_FORWARD',
  SYNTHETIC_FORWARD = 'SYNTHETIC_FORWARD',
  TRACKER = 'TRACKER',
  COLLAR = 'COLLAR',
  OTHER = 'OTHER',
}

export const optionProductTypeToHuman: { [key in OptionProductType]: string } = {
  VANILLA: 'FX Vanilla',
  PARTICIPATING_FORWARD: 'FX Participating forward',
  SYNTHETIC_FORWARD: 'FX Synthetic forward',
  TRACKER: 'FX Tracking Forward',
  COLLAR: 'FX Collar',
  OTHER: 'Other',
};

export function formatOptionProductType(instrument?: string, defaultStr = '') {
  if (!instrument) {
    return defaultStr;
  }
  return optionProductTypeToHuman[instrument as OptionProductType] || defaultStr;
}

export interface VoiceTradeData extends VersionedObject, UpdatableObject, DeletableObject {
  parentId?: string;
  voiceTradeMainId: string;
  referenceNumber: string;
  optixRelatedConfoId?: string;
  sellCurrency?: string;
  buyCurrency?: string;
  sellAmount: number;
  buyAmount: number;
  sellAmountOriginal?: number;
  buyAmountOriginal?: number;

  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;

  assetClass: AssetClass;
  instrumentType: InstrumentType;
  state: VoiceTradeState;
  tradeDate: string;
  currencyPair: string;
  notionalAmount: number;
  notionalAmountGbp: number;
  notionalCurrency: string;
  amountType: AmountType;
  optionProductType: OptionProductType;

  manualInitialMarginCurrency?: string | null;
  manualInitialMarginAmountGbp?: number | null;
  manualInitialMarginAmount?: number | null;
  manualInitialMarginDate?: string | null;
  manualInitialMarginStatus?: InitialMarginStatus;

  nearLegNotionalAmount: number;
  nearLegNotionalAmountGbp: number;
  nearLegNotionalCurrency: string;
  nearLegAmountType: AmountType;
  farLegNotionalCurrency: string;
  farLegNotionalAmount: number;
  farLegNotionalAmountGbp: number;
  farLegAmountType: AmountType;

  clientRate?: number;
  windowStartDate?: string;
  settlementDate?: string;
  farLegClientRate?: number;
  farLegSettlementDate?: string;
  nearLegSettlementDate?: string;
  nearLegClientRate?: number;

  putCallCcy1?: PutCallCcy1;
  clientPremiumAmount?: number;
  clientPremiumCurrency?: string;
  liquidityProviderPremiumAmount?: number;
  liquidityProviderPremiumCurrency?: string;
  liquidityProviderLegId?: string;
  deskPremium?: number;
  premiumCurrency?: string;
  deskPremiumCurrency?: string;
  premiumDate?: string;
  optionStatus?: OptionStatus;
  barrierLevel?: string;
  barrierType?: string;
  triggerWindow?: string;
  barrierLevel2?: string;
  barrierType2?: string;
  triggerWindow2?: string;
  barrierStatus?: string;
  settleFixingRate?: number;
  cutOffFixing?: string;

  liquidityProviderId?: string;
  liquidityProviderName?: string;
  liquidityProviderDealId?: string | null;
  liquidityProviderRate?: number;
  nearLegLiquidityProviderId?: string;
  nearLegLiquidityProviderName?: string;
  nearLegLiquidityProviderDealId?: string;
  nearLegLiquidityProviderRate?: number;
  farLegLiquidityProviderId?: string;
  farLegLiquidityProviderName?: string;
  farLegLiquidityProviderDealId?: string;
  farLegLiquidityProviderRate?: number;

  spotRateSnapshot?: number;
  optixExternalId?: string;
  optixDealIndex?: string;
  optixParentId?: string;
  purposeType?: VoiceTradePurposeTypes;

  receivingClientAccountId?: string;
  receivingClientAccountName?: string;
  payingClientAccountId?: string;
  payingClientAccountName?: string;

  payingLiquidityProviderAccountId?: string;
  payingLiquidityProviderAccountName?: string;
  receivingLiquidityProviderAccountId?: string;
  receivingLiquidityProviderAccountName?: string;

  nearLegReceivingClientAccountId?: string;
  nearLegReceivingClientAccountName?: string;
  nearLegPayingClientAccountId?: string;
  nearLegPayingClientAccountName?: string;
  nearLegReceivingLiquidityProviderAccountId?: string;
  nearLegReceivingLiquidityProviderAccountName?: string;
  nearLegPayingLiquidityProviderAccountId?: string;
  nearLegPayingLiquidityProviderAccountName?: string;

  farLegReceivingClientAccountId?: string;
  farLegReceivingClientAccountName?: string;
  farLegPayingClientAccountId?: string;
  farLegPayingClientAccountName?: string;
  farLegReceivingLiquidityProviderAccountId?: string;
  farLegReceivingLiquidityProviderAccountName?: string;
  farLegPayingLiquidityProviderAccountId?: string;
  farLegPayingLiquidityProviderAccountName?: string;

  strikeRate?: number;
  expiryDate?: string;
  notes?: string;

  commonSettlementDate?: string;
  commonAmountType?: AmountType;
  commonSellCurrency?: string;
  commonSellAmount?: number;
  commonBuyCurrency?: string;
  commonBuyAmount?: number;

  tradeId?: string;
  claimType?: string;

  spotRef?: string;
  expiryTime?: string;
}
export interface VoiceTradeDrawdownRequest {
  drawdownAmount: number;
  drawdownCurrency: string;
  drawdownAmountType: AmountType;
  clientRate: number;
  liquidityProviderRate: number;
  liquidityProviderDealId: string;
  tradeDate: string;
  drawdownDate: string;
  spotRateSnapshot: number;
  notes: string;
}

export interface VoiceTradeActionResponse {
  originalVoiceTrade: VersionedObject;
  newVoiceTrade: VersionedObject;
}

export enum VoiceTradeNotificationState {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  SENDING = 'SENDING',
  FAILED = 'FAILED',
}

export enum VoiceTradeNotificationType {
  OPTIONS_EXPIRY_CONFIRMATION = 'OPTIONS_EXPIRY_CONFIRMATION',
  OPTIONS_EXPIRY_REACHED = 'OPTIONS_EXPIRY_REACHED',
  OPTIONS_CREATE_CONFIRMATION = 'OPTIONS_CREATE_CONFIRMATION',
}

export interface VoiceTradeNotification {
  voiceTradeId: string;
  type: VoiceTradeNotificationType;
  state: VoiceTradeNotificationState;
  exportId: string;
  spotRate: number;
  scenario: number;
  expiryDate: string;
  triggeredAt: string;
}

export interface InitialMargin {
  initialMarginRequired?: boolean;
  manualInitialMarginCurrency?: string | null;
  manualInitialMarginAmount?: number | null;
  manualInitialMarginDate?: string | null;
  manualInitialMarginStatus?: InitialMarginStatus;
}

export enum InitialMarginStatus {
  SETTLED = 'SETTLED',
  NOT_SETTLED = 'NOT_SETTLED',
}

export const initialMarginStatusToHuman: { [key in InitialMarginStatus]: string } = {
  SETTLED: 'Settled',
  NOT_SETTLED: 'Not Settled',
};

export enum PutCallCcy1 {
  PUT = 'PUT',
  CALL = 'CALL',
}

export enum VoiceTradeAuditActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CANCEL = 'CANCEL',
  AMEND = 'AMEND',
  DRAWDOWN = 'DRAWDOWN',
  ROLL_FORWARD = 'ROLL_FORWARD',
  SETTLE = 'SETTLE',
  UNSETTLE = 'UNSETTLE',
}

export const voiceTradeActionsToHuman: { [key in VoiceTradeAuditActions]: string } = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
  CANCEL: 'Cancelled',
  AMEND: 'Amended',
  DRAWDOWN: 'Drawdown',
  ROLL_FORWARD: 'Roll Forward',
  SETTLE: 'Settled',
  UNSETTLE: 'Unsettled',
};

export interface VoiceTradeAuditData extends VoiceTradeData, DeletableObject, AuditData<VoiceTradeAuditActions> {
  initialMarginPercentage: number;
  initialMarginAmountGbp: number;
  cancellationPnlCurrency: number;
  cancellationPnlAmount: number;
}
