
import { Component, Vue, Prop } from 'vue-property-decorator';
import VButton from '../../common/components/VButton.vue';
import FormElement from './FormElement.vue';
import { FormModel, FormEvent, FormAction } from '../interfaces/form';
import { getState } from '../helpers';
import { FormValidation } from '../interfaces';

/**
 * Dynamic Form component
 *
 * Usually the base component for any schema based forms
 * Emits:
 * - `form-event` for all form events (re-emited from fields or emited from form actions)
 * - `submit` - convenience event, emmited alongside the relevant `form-event`
 */
@Component({
  components: {
    FormElement,
    VButton,
  },
})
export default class DynamicForm extends Vue {
  @Prop({ required: true }) form!: FormValidation<FormModel>;

  public triggerFieldAction(path: string, action: any) {
    if (this.model.$path === path) {
      // trigger action here!
    } else {
      this.model.$fields.forEach((field, index) => {
        if (path.startsWith(field.$path)) {
          const child = (this.$refs as any).fields[index] as FormElement;
          if (child) {
            child.triggerFieldAction(path, action);
          }
        }
      });
    }
  }

  get model(): FormModel {
    return this.form.$model;
  }

  get requiredText() {
    return getState(this.model, 'requiredText', '');
  }

  get requiredTextNote() {
    return getState(this.model, 'requiredTextNote', '');
  }

  get formClass() {
    return getState(this.model, 'formClass', this.$formOptions.formClass || '', true);
  }

  private onFormEvent(event: FormEvent) {
    this.$emit('form-event', event);
    if (event.event === 'form-field-submit') {
      this.$emit('submit', event);
    }
  }

  private onFormAction(action: FormAction) {
    setTimeout(() => {
      this.$emit('form-event', {
        field: this.form,
        model: this.model,
        event: 'form-action',
        action,
      } as FormEvent);
    });
  }
}
