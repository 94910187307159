import { OptionType } from '../currency';
import { HedgingInstruments } from './hedgingInstrument';
import { QuoteCurrencyData } from './quote';
import { VersionedObject } from '../rest';

export enum CalculationState {
  UNCALCULATED = 'UNCALCULATED',
  SUCCESSFULLY = 'SUCCESSFULLY',
  UNSUPPORTED_PAIR = 'UNSUPPORTED_PAIR',
  TARGET_DATE_IS_WEEKEND = 'TARGET_DATE_IS_WEEKEND',
  TARGET_DATE_IS_HOLIDAY = 'TARGET_DATE_IS_HOLIDAY',
  START_DATE_IS_WEEKEND = 'START_DATE_IS_WEEKEND',
  START_DATE_IS_HOLIDAY = 'START_DATE_IS_HOLIDAY',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface HedgingSolutionInput {
  partnerBasisPoints?: number;
  startDate?: string;
  targetDate?: string;
  targetTimeFrame?: string;
  hedgingProduct: HedgingInstruments;
  participationLevel?: number;
  protectedStrike?: number;
  strikeRate?: number;
  cappedStrike?: number;

  $tempID?: string;
}

export interface DetailedCurrencyDefaults extends CurrencyDefaults {
  currency: string;
  optionType: OptionType;
}

export interface CurrencyDefaults {
  startStrike: number;
  strikeRates: number[];
  maxStrikeInStrikeRange: number;
  minStrikeInStrikeRange: number;
}

export interface HedgingSolutionDefaults extends BaseHedgingSolutionDefaults {
  ccy1: CurrencyDefaults;
  ccy2: CurrencyDefaults;
}

export interface QuoteDefaults extends BaseHedgingSolutionDefaults {
  ccy1: DetailedCurrencyDefaults;
  ccy2: DetailedCurrencyDefaults;
}

export interface BaseHedgingSolutionDefaults {
  minCappedStrike: number;
  maxCappedStrike: number;
  minProtectedStrike: number;
  maxProtectedStrike: number;
}

export interface HedgingSolution extends HedgingSolutionInput, VersionedObject {
  calculationState: CalculationState;
  displayOrder?: number;
  partnerProfit?: number;
  expiryDate?: string;
  createdAt: string;
  settlementDate?: string;
  expressedInterest?: boolean;
  premiumPercentage?: number;
  spotRate?: number;
  forwardRate?: number;
  forwardPoints?: number;
  price?: number;
  priceExpirationDate?: string;
  priceRequestDate?: string;
  ccy1: QuoteCurrencyData;
  ccy2: QuoteCurrencyData;
  defaults?: HedgingSolutionDefaults;

  deletedAt?: string;
  updatedAt?: string;
}
