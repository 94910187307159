import _Vue, { PluginObject, VueConstructor } from 'vue';
import TooltipComponent from './components/TooltipComponent.vue';
import { ValidationArgs } from '@vuelidate/core';

import * as Components from './components';

declare module 'vue/types/vue' {
  interface Vue {
    $formOptions: FormPluginOptions;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends _Vue> {
    validations?: ValidationArgs;
  }
}

export interface FormPluginOptions {
  fieldWrapperClass: string;
  formWrapperClass: string;
  formClass: string;
  /**
   * Component to display a tooltip, when needed.
   *
   * Must accept a `text` prop
   */
  tooltipComponent: VueConstructor;
}

const pluginOptionDefaults: FormPluginOptions = {
  fieldWrapperClass: '',
  formWrapperClass: '',
  formClass: 'form dynamic-form',
  tooltipComponent: TooltipComponent as any,
};

export default {
  install: function install(Vue: typeof _Vue, options?: Partial<FormPluginOptions>) {
    Object.keys(Components).forEach((key) => Vue.component(key, (Components as any)[key]));

    Vue.prototype.$formOptions = {
      ...pluginOptionDefaults,
      ...options,
    };
  },
} as PluginObject<void>;
